import {styled} from '@mui/system'

export const DisplayedTags = styled('div')(
  ({theme}) => `
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 6px;
  flex: 1 0 0;
  flex-wrap: wrap;
  cursor: default;
`,
)
