import {Grid, Link, Typography} from '@mui/material'
import {Box, Stack, styled} from '@mui/system'
import React from 'react'
import {useHref} from 'react-router-dom'
import colors from './colors'
import {SecondaryButton} from './ui/Buttons'

const homepageContainer = styled('div')<{backgroundColor?: string}>(
  ({theme, backgroundColor}) => `

    box-sizing: border-box;
    background-color: ${backgroundColor || colors.background[2]};
    ${theme.breakpoints.up('sm')} {
        padding: 3.75rem 7.5rem;

    }
    
    
    `,
)

function HomepageWelcome(props: any) {
  const {welcome, user} = props
  if (!welcome || !welcome.greeting || welcome.greeting.length === 0) {
    return <></>
  }

  return (
    <Grid
      container
      sx={{
        backgroundColor: colors.background[1],
        borderBottom: {xs: `1px solid ${colors.background[5]}`, md: 'none'},
        padding: {md: '3.75rem 7.5rem 2.94rem', xs: '1.25rem 1.55rem 3.75rem'},
      }}>
      <Grid container item md={6} xs={12}>
        <Grid>
          <Typography variant="h1" sx={{color: colors.darkEvergreen[60]}}>
            {welcome.greeting}
            {user.name}
            {welcome.greetingPunctuation}{' '}
          </Typography>
          <Typography variant="h1" sx={{color: colors.evergreen[130]}}>
            {welcome.headline}
          </Typography>
          {welcome.subHeadline && (
            <Typography
              variant="body1medium"
              sx={{color: colors.darkEvergreen[75], display: 'block', marginTop: '1.88rem', textDecoration: 'none'}}>
              {welcome.subHeadline}{' '}
              {welcome.subHeadlineLinkText && welcome.subHeadlineLinkSrc && (
                <Link variant="body1medium" href={welcome.subHeadlineLinkSrc} sx={{color: colors.darkEvergreen[75]}}>
                  {welcome.subHeadlineLinkText}
                </Link>
              )}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid item></Grid>
    </Grid>
  )
}
const MarketingImageHolder = styled('div')(
  ({theme}) => `

    
    overflow: clip;
  `,
)

const ChipHolder = styled('div')(
  ({theme}) => `
    display: inline-block;
    background-color: ${colors.evergreen[70]};
    color: #FFFFFF;
    padding: 0.5rem 0.75rem;

    font-family: 'Aktiv-Grotesk';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: normal;
  `,
)
function Chip(props: any) {
  const text = props.text
  if (text && text.length === 0) {
    return <></>
  }

  return <ChipHolder>{text}</ChipHolder>
}

function CallToAction(props: any) {
  const {callToAction} = props

  if (!callToAction || !callToAction.type || callToAction.type.length === 0) {
    return <></>
  }

  if (callToAction.type === 'link') {
    return (
      <div>
        <SecondaryButton
          href={callToAction.linkSrc}
          size="large"
          sx={{textDecoration: 'none'}}
          target={callToAction.newTab ? '_blank' : ''}
          rel={callToAction.newTab ? 'noopener' : ''}>
          {callToAction.text}
        </SecondaryButton>
      </div>
    )
  }

  return (
    <div>
      <SecondaryButton size="large">{callToAction.text}</SecondaryButton>
    </div>
  )
}
function FlexibleMarketingModule(props: any) {
  const {module} = props
  if (!module.headline || module.headline.length === 0) {
    return <></>
  }

  return (
    <Grid
      container
      sx={{backgroundColor: colors.background[1], padding: {md: '3.75rem 7.5rem', xs: '3.75rem 1.55rem'}}}>
      <Grid item md={6} xs={12}>
        <MarketingImageHolder>
          <img
            src={module.imageSrc}
            alt={module.headline}
            style={{borderRadius: '32px', width: '100%', height: '100%'}}
          />
        </MarketingImageHolder>
      </Grid>
      <Grid container item alignItems="center" md={6} xs={12}>
        <Stack spacing={5} sx={{paddingLeft: {md: '3.75rem'}, paddingTop: {xs: '1.88rem'}}}>
          {/**container item direction='column' justifyContent='center' alignItems='flex-start' */}
          <div>{module.chipText && <Chip text={module.chipText} />}</div>
          <Typography variant="h3" sx={{color: colors.evergreen[130]}}>
            {module.headline}
          </Typography>
          <Typography variant="body2medium" sx={{color: colors.darkEvergreen[75]}}>
            {module.subHeadline}
          </Typography>
          <CallToAction callToAction={module.callToAction} />
        </Stack>
      </Grid>
    </Grid>
  )
}

function EditorialItem(props: any) {
  const {item} = props

  const href = useHref(item.linkSrc)

  return (
    <Link
      href={href}
      sx={{textDecoration: 'none'}}
      target={item.newTab ? '_blank' : ''}
      rel={item.newTab ? 'noopener' : ''}>
      <Box sx={{maxWidth: '17.81rem'}}>
        <Box sx={{overflow: 'clip'}}>
          <img
            alt={item.imgAlt || item.title}
            src={item.imgSrc}
            style={{borderRadius: '32px', width: '100%', height: '100%'}}
          />
        </Box>
        <Box sx={{marginTop: {md: '1.25rem', xs: '1.25rem'}, color: colors.darkEvergreen[100]}}>
          <Typography variant="h5">{item.title}</Typography>
        </Box>
      </Box>
    </Link>
  )
}

function CarouselModule(props: any) {
  const {module} = props

  if (!module || !module.items || module.items.length === 0) {
    return <></>
  }

  if (
    !module.items[0].imgSrc ||
    module.items[0].imgSrc.length === 0 ||
    !module.items[0].linkSrc ||
    module.items[0].linkSrc.length === 0
  ) {
    return <></>
  }

  return (
    <Grid
      container
      sx={{padding: {md: '3.75rem 7.5rem', xs: '3.75rem 1.55rem'}, backgroundColor: colors.background['warm white']}}>
      {module.eyebrowText && (
        <Grid item xs={12} md={12}>
          <Typography variant="body2" sx={{color: colors.evergreen[100]}}>
            {module.eyebrowText}
          </Typography>
        </Grid>
      )}
      {module.headerText && (
        <Grid item xs={12} md={12}>
          <Typography variant="h3" sx={{color: colors.darkEvergreen[100], marginTop: '1.25rem'}}>
            {module.headerText}
          </Typography>
        </Grid>
      )}
      <Grid container direction="row" justifyContent="center" spacing={5} sx={{paddingTop: '2.5rem'}}>
        {module.items.map((item: any, i: number) => {
          return (
            <Grid key={i} item md={3}>
              <EditorialItem item={item} />
            </Grid>
          )
        })}
      </Grid>
    </Grid>
  )
}

const links = {
  'Lauren Abney': '/profile/898',
  'Stephanie Eichmann': '/profile/885',
  'Brooke Richman': '/profile/882',
  'Mary Adkins': '/profile/866',
  'Bloomi': '/profile/894',
  'thirteen lune': '/profile/900',
  'Rebdolls': '/profile/777',
  'Gourmend Foods': '/profile/850',
}

export default function HomeView(): React.JSX.Element {
  const homepage = {
    modules: [
      {
        type: 'FlexibleMarketingModule',
        imageSideLeft: true,
        imageSrc: 'https://mcusercontent.com/7d17a8227a09488f1d4a68bfa/images/0eac591b-f251-6a3d-a264-f8b28e128b0c.png',
        chipText: 'Just Launched',
        callToAction: {
          type: 'link',
          text: 'Browse Directory',
          linkSrc: 'https://www.investwithcherub.com/app#/directory/companies',
        },
        headline: 'Now Live: Cherub Member Directory',
        subHeadline: 'Browse and connect with Cherub members beyond your Deal Flow',
      },
      {
        type: 'CarouselModule',
        eyebrowText: 'New to Cherub',
        headerText: 'Latest angel investors',
        items: [
          {
            imgSrc: 'https://uploads-ssl.webflow.com/63f65e36f83418180b98ca68/66a400964832c64f4fa30861_lauren%20abney.png',
            imgAlt: 'Lauren Abney',
            title: 'Lauren Abney',
            linkSrc: links['Lauren Abney'],
            newTab: false,
          },
          {
            imgSrc: 'https://uploads-ssl.webflow.com/63f65e36f83418180b98ca68/66a40096547d22e36d4f6cf2_stephanie%20eichmann.png',
            imgAlt: 'Stephanie Eichmann',
            title: 'Stephanie Eichmann',
            linkSrc: links['Stephanie Eichmann'],
            newTab: false,
          },
          {
            imgSrc: 'https://uploads-ssl.webflow.com/63f65e36f83418180b98ca68/66a40096a989f66ce224b39d_brooke%20richman.png',
            imgAlt: 'Brooke Richman',
            title: 'Brooke Richman',
            linkSrc: links['Brooke Richman'],
            newTab: false,
          },
          {
            imgSrc: 'https://uploads-ssl.webflow.com/63f65e36f83418180b98ca68/66a40096838dd5347ecac3ed_mary%20adkins.png',
            imgAlt: 'Mary Adkins',
            title: 'Mary Adkins',
            linkSrc: links['Mary Adkins'],
            newTab: false,
          },
        ],
      },
      {
        type: 'FlexibleMarketingModule',
        imageSideLeft: true,
        imageSrc: 'https://uploads-ssl.webflow.com/63f65e36f83418180b98ca68/66900dbd63ed5ad6f0a2baee_new_deal_flow.jpg',
        chipText: 'Just Launched',
        callToAction: {
          type: 'link',
          text: 'Learn More',
          linkSrc: 'https://us21.campaign-archive.com/?u=7d17a8227a09488f1d4a68bfa&id=91a6d1dd17',
          newTab: true,
        },
        headline: 'Meet Our New Deal Flow Experience',
        subHeadline: 'Now with intro messages and enhanced for quality',
      },
      {
        type: 'CarouselModule',
        eyebrowText: 'Say Hello',
        headerText: 'Meet our Newest Cherub Select Companies',
        items: [
          {
            imgSrc: 'https://uploads-ssl.webflow.com/63f65e36f83418180b98ca68/66a40ef991c4656b66066337_bloomi.png',
            imgAlt: 'Bloomi',
            title: 'Bloomi',
            linkSrc: links['Bloomi'],
            newTab: false,
          },
          {
            imgSrc: 'https://uploads-ssl.webflow.com/63f65e36f83418180b98ca68/66a40ef9bc485e77205969dd_rebdolls.png',
            imgAlt: 'Rebdolls',
            title: 'Rebdolls',
            linkSrc: links['Rebdolls'],
            newTab: false,
          },
          {
            imgSrc: 'https://uploads-ssl.webflow.com/63f65e36f83418180b98ca68/66a40ef9b131c6c562df45df_thirteen%20lune.png',
            imgAlt: 'thirteen lune',
            title: 'thirteen lune',
            linkSrc: links['thirteen lune'],
            newTab: false,
          },
          {
            imgSrc: 'https://uploads-ssl.webflow.com/63f65e36f83418180b98ca68/66a40ef920b166553a2ac929_gourmend%20foods.png',
            imgAlt: 'Gourmend Foods',
            title: 'Gourmend Foods',
            linkSrc: links['Gourmend Foods'],
            newTab: false,
          },
        ],
      },
    ],
  }

  return (
    <Grid sx={{backgroundColor: 'transparent'}}>
      {homepage.modules.map((module: any, i: number) => {
        if (module.type === 'FlexibleMarketingModule') {
          return <FlexibleMarketingModule key={i} module={module} />
        }
        if (module.type === 'CarouselModule') {
          return <CarouselModule key={i} module={module} />
        }
        return <></>
      })}
    </Grid>
  )
}
