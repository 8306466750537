import {styled} from '@mui/system'
import colors from './colors'


export const PinnedHeader = styled('div')<{backgroundColor?: string}>(
  ({theme, backgroundColor}) => `
  background-color: ${backgroundColor || (theme.palette.mode === 'light' ? colors.background[1] : colors.darkEvergreen[100])};
  color: ${theme.palette.mode === 'light' ? colors.darkEvergreen[100] : colors.background[1]};
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  height: 100px;
  padding: 0 4em;

  position: sticky;
  z-index: 999;
  top: 0;
}`,
)
export type HeaderProps = {
  right?: JSX.Element
  hideRight?: true
}