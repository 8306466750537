import React, {createContext} from 'react'

export type DealFlowContextType = {
    deals: [DealType] | []
}


export type DealType = any

export const DealFlowContext = createContext<DealFlowContextType | null>(null)

export const DealFlowDispatchContext = createContext<React.Dispatch<DealAction> | null>(null)

export type DealAction =   
    | {
        type: 'set_deals_loaded'
        deals: any
    }
    |{
        type: 'deals_clear'
        deals: any    
    }



   

export function dealflowReducer(state: DealFlowContextType, action: DealAction): DealFlowContextType  {
    switch (action.type) {
        case 'set_deals_loaded': {
            return {
                ...state,
                deals: action.deals,
            }
        }
        default: {
            return state
        }
    }
}

export function useDealFlowContext() {
    const deals = React.useContext(DealFlowContext)
    if (!deals) {
        throw new Error('useDealFlowContext must be used within a DealFlowProvider')
    }
    const dispatch = React.useContext(DealFlowDispatchContext)
    if (!dispatch) {
        throw new Error('useDealFlowDispatch must be used within a DealFlowProvider')
    }
    return {deals, dispatch}

}

export type MatchStatusContextType = {    
    deal: any
    matchingTags: any
    requestsRemaining: any
    matched: any
}

export type MatchStatusType = any

export const MatchStatusContext = createContext<MatchStatusContextType | null>(null)   
export const MatchStatusDispatchContext = createContext<React.Dispatch<MatchStatusAction> | null>(null)

export type MatchStatusAction =
    | {
        type: 'set_match_status'
        match: any
    }

export function matchStatusReducer(state: MatchStatusContextType, action: MatchStatusAction): MatchStatusContextType {
    switch (action.type) {
        case 'set_match_status': {
            return {
                ...state,                
                deal: action.match.deal,
                matchingTags: action.match.matchingTags,
                requestsRemaining: action.match.requestsRemaining,
                matched: action.match.matched,
            }
        }
        default: {
            return state
        }
    }
}

export function useMatchStatusContext() {
    const match = React.useContext(MatchStatusContext)
    if (!match) {
        throw new Error('useMatchStatusContext must be used within a MatchStatusProvider')
    }
    const dispatch = React.useContext(MatchStatusDispatchContext)
    if (!dispatch) {
        throw new Error('useMatchStatusDispatch must be used within a MatchStatusProvider')
    }
    return {match, dispatch}
}

