import {Typography} from '@mui/material'
import {styled} from '@mui/system'
import colors from '../colors'

const ImageViewport = styled('div')<{previewing: boolean}>(
  ({theme, previewing}) => `
  border-radius: 200px;
  border: 5px solid ${colors.background[4]};
  background: ${colors.transparent.fully};

  display: flex;
  width: 150px;
  height: 150px;
  justify-content: center;
  align-items: center;
  z-index: 2;
`,
)

const ImageMask = styled('div')(
  ({theme}) => `
  background: ${colors.background.white};
  border-radius: 200px;

  align-items: center;
  display: flex;
  justify-content: center;
  overflow: hidden;
  height: 100%;
  width: 100%;
  z-index: 1;
`,
)

export default function AvatarImagePreview({image}: {image?: string}): React.JSX.Element {
  return (
    <ImageViewport previewing={Boolean(image)}>
      <ImageMask>
        {image ? (
          <img style={{zIndex: 1, minHeight: '100%', maxHeight: '100%'}} src={image} alt="Your Avatar" />
        ) : (
          <div style={{width: '45px', height: '45px', textAlign: 'center', lineHeight: '180%'}}>
            <Typography variant="body2medium" color={colors.gray[3]}>
              Your Photo
            </Typography>
          </div>
        )}
      </ImageMask>
    </ImageViewport>
  )
}
