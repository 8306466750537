import _ from 'lodash'
import {DateTime} from 'luxon'
import React, {createContext} from 'react'
import {FounderProfileData, InvestorProfileData} from '../api/types'
import {DealType} from '../deal/Deal.Contexts'
import {
  DateProfileField,
  ImageProfileField,
  InputProfileField,
  RepeatingFields,
  SelectProfileField,
  TagProfileField,
} from './common'

export type PendingFileState = Record<
  string,
  {
    file: File
    state: 'pending' | 'uploading' | 'uploaded' | 'failed'
  }
>

// TODO: should look into jsonschema or something similar for this
// type ProfileDataType = { [key: string]: any } & {
//   stage: 'Pre-Seed' | 'Seed' | 'Series A+'
// }
export type ProfileDataKeys = keyof FounderProfileData | keyof InvestorProfileData
export type ProfileDataType = any // Partial<FounderProfileData & InvestorProfileData> | null

export type ProfileKindType = 'founder' | 'investor'

export type ProfileMetadata = {
  profileType: ProfileKindType
  truncated: boolean
  orgId?: string
}

// FUTURE: should we end up doing a context+reducer again,
// we should probably swap to a fuller framework for this like RTK (ReduxToolkit)
export type ProfileForm = {
  metadata: ProfileMetadata
  profile: ProfileDataType
  currentFiles: Record<string, string>
  pendingFiles: PendingFileState
  deals?: DealType[]
  isOwnProfile?: boolean
  // TODO: removedFiles: string[]
}

export const nullProfile: ProfileForm = {
  profile: null,
  metadata: {
    profileType: 'founder',
    truncated: true,
  },
  currentFiles: {},
  pendingFiles: {},
  deals: [],
}

export const ProfileFormContext = createContext<ProfileForm | null>(null)

export const ProfileFormDispatchContext = createContext<React.Dispatch<Action> | null>(null)

export type Action =
  | {
      type: 'set_value'
      field: InputProfileField | SelectProfileField
      value: string | null
    }
  | {
      type: 'set_date'
      field: DateProfileField
      value: DateTime | null
    }
  | {
      type: 'set_object'
      field: InputProfileField
      value: object | null
    }
  | {
      type: 'set_image'
      field: ImageProfileField
      file: File | null
    }
  | {
      type: 'set_array'
      field: RepeatingFields
      value: string | null
      index: number
    }
  | {
      type: 'set_tags'
      field: TagProfileField
      value: string[] | null
    }
  | {
      type: 'set_loaded'
      profile: ProfileDataType
      files: Record<string, string>
      metadata: ProfileMetadata
    }

export function profileFormReducer(form: ProfileForm, action: Action): ProfileForm {
  switch (action.type) {
    case 'set_array': {
      const current = [...(_.get(form.profile, action.field.property) ?? [])]
      if (action.value) {
        current[action.index] = action.value
      } else {
        current.splice(action.index, 1)
      }
      return {
        ...form,
        profile: _.chain(form.profile).cloneDeep().set(action.field.property, current).value(),
      }
    }
    case 'set_image': {
      // need to delete key rather than just set
      if (action.file == null) {
        return {
          ...form,
          pendingFiles: _.omit(form.pendingFiles, action.field.property),
        }
      }
      return {
        ...form,
        pendingFiles: {
          ...form.pendingFiles,
          [action.field.property]: {
            file: action.file,
            state: 'pending',
          },
        },
      }
    }
    case 'set_tags': {
      // TODO: Tags need to be set top level, need to update endpoints too
      return {
        ...form,
        profile: _.chain(form.profile).cloneDeep().set(action.field.property, action.value).value(),
      }
    }
    case 'set_date': {
      const value = action.value?.toISODate()
      return {
        ...form,
        profile: _.chain(form.profile).cloneDeep().set(action.field.property, value).value(),
      }
    }
    case 'set_object':
    case 'set_value': {
      return {
        ...form,
        profile: _.chain(form.profile).cloneDeep().set(action.field.property, action.value).value(),
      }
    }
    case 'set_loaded': {
      return {
        ...form,
        metadata: action.metadata,
        profile: action.profile,
        currentFiles: action.files,
        pendingFiles: {},
      }
    }
  }
}

export function useProfileContext() {
  const form = React.useContext(ProfileFormContext)
  if (!form) {
    throw new Error('useProfileForm must be used within a ProfileFormProvider')
  }
  const dispatch = React.useContext(ProfileFormDispatchContext)
  if (!dispatch) {
    throw new Error('useProfileFormDispatch must be used within a ProfileFormProvider')
  }

  return {form, dispatch}
}
