import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio, {RadioProps} from '@mui/material/Radio'
import RadioGroup, {RadioGroupProps} from '@mui/material/RadioGroup'
import {styled} from '@mui/material/styles'
import {TypographyProps} from '@mui/system'
import colors from '../colors'

function RadioButtonCheckedIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <rect width="18" height="18" fill="white" />
      <circle cx="9" cy="9" r="8.5" stroke="#C9C9C9" />
      <circle cx="9" cy="9" r="5" fill="#3A3A3A" />
    </svg>
  )
}

const BpIcon = styled('span')(({theme}) => ({
  borderRadius: '50%',
  border: `1px solid ${colors.gray[4]}`,
  width: '16px',
  height: '16px',
  backgroundColor: colors.background.white,
  // theme.palette.mode === 'dark'
  //   ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
  //   : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  'input:hover ~ &': {
    borderColor: colors.gray[3],
  },
}))

// Inspired by blueprintjs
function CherubRadioOption(props: RadioProps) {
  return <Radio disableRipple color="default" checkedIcon={<RadioButtonCheckedIcon />} icon={<BpIcon />} {...props} />
}

const textStyle: TypographyProps = {
  fontFamily: 'Aktiv-Grotesk',
  fontSize: '14px',
  lineHeight: '130%',
}

type RadioGroupChoices = {
  choices: Record<string, string>
}

export default function CherubRadioGroup({choices, ...props}: RadioGroupProps & RadioGroupChoices) {
  return (
    <FormControl>
      <RadioGroup {...props}>
        {Object.keys(choices).map(key => (
          <FormControlLabel
            key={key}
            slotProps={{typography: textStyle}}
            value={key}
            control={<CherubRadioOption />}
            label={choices[key]}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}
