// See: https://www.figma.com/file/qMoxJn4rVySreDEnf2R9Js/Cherub-Annotations?type=design&node-id=370%3A18079&mode=dev

import {ClickAwayListener, Grid, Tooltip, TooltipProps, tooltipClasses} from '@mui/material'
import {SxProps, styled} from '@mui/system'
import React from 'react'
import colors from '../colors'
import {Cancel, Help} from '../ui/icons'

const HtmlTooltip = styled(({className, ...props}: TooltipProps) => (
  <Tooltip {...props} classes={{popper: className}} />
))(({theme}) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: colors.background.white,
    // TODO: fix this or pull in svg arrow
    // boxShadow: '2px 0 0 0 rgba(0, 0, 0, 0.2)',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: colors.background.white,
    maxWidth: 200,
    fontSize: '12px',
    border: `1px solid ${colors.gray[6]}`,
    borderRadius: 12,
    boxShadow: '0px 2px 2px 2px rgba(0, 0, 0, 0.2)',
  },
}))

const Title = styled('div')(
  ({theme}) => `
  color: ${colors.gray[1]};
  font-family: "Aktiv-Grotesk";
  font-size: 12px;
  font-style: normal;
  font-weight: 600; /* TODO */
  line-height: 130%; /* 15.6px */
`,
)

const Description = styled('div')(
  ({theme}) => `
  color: ${colors.gray[1]};
  font-family: "Aktiv-Grotesk";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 15.6px */
`,
)

type CherubTooltipProps = {
  title?: string
  description: React.JSX.Element | string
  sx?: SxProps
  iconStyle?: React.CSSProperties
}

export default function CherubTooltip({
  title = "What's this?", // eslint-disable-line quotes
  description,
  iconStyle,
}: CherubTooltipProps): React.JSX.Element {
  const [open, setOpen] = React.useState(false)
  const onClose = () => setOpen(false)

  return (
    <ClickAwayListener onClickAway={onClose}>
      <div style={{display: 'inline-block'}}>
        <HtmlTooltip
          PopperProps={{
            disablePortal: true,
          }}
          open={open}
          onClose={onClose}
          arrow
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={
            <Grid container padding={2} spacing={3}>
              <Grid container item justifyContent="space-between">
                <Grid item flexShrink={0}>
                  <Title>{title}</Title>
                </Grid>
                <Grid item flexShrink={0}>
                  <Cancel
                    style={{cursor: 'pointer', color: colors.gray[1]}}
                    onClick={() => {
                      setOpen(false)
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item>
                <Description>{description}</Description>
              </Grid>
            </Grid>
          }>
          <span
            // TODO: https://www.figma.com/file/qMoxJn4rVySreDEnf2R9Js?node-id=99:6591&mode=dev#691879429
            onClick={() => {
              setOpen(true)
            }}
            onTouchEnd={() => {
              setOpen(true)
            }}
            onMouseOver={() => {
              setOpen(true)
            }}>
            <Help style={{margin: '0 0 -4px 4px', ...iconStyle}} />
          </span>
        </HtmlTooltip>
      </div>
    </ClickAwayListener>
  )
}
