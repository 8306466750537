// Figma: https://www.figma.com/file/qMoxJn4rVySreDEnf2R9Js/Cherub-Annotations?type=design&node-id=399%3A5443&mode=dev
import {Grid, Typography} from '@mui/material'
import {Stack, SxProps, styled} from '@mui/system'
import _ from 'lodash'
import React from 'react'
import colors from '../colors'
import {PrimaryButton} from '../ui/Buttons'
import {NavigateNext} from '../ui/icons'
import {CherubModal} from './Profile.CherubModal'
import {useProfileContext} from './Profile.Contexts'
import CherubRadioGroup from './Profile.RadioGroup'
import {Option, Select} from './Profile.Select'

const styles: Record<string, SxProps> = {
  container: {
    borderRadius: 5,
    border: `2px solid ${colors.background[4]}`,
    background: colors.background[2],
    padding: 7.5,
  },
}

const ActionTitle = styled('div')(
  ({theme}) => `
  color: ${colors.evergreen[130]};

  /* Headline/Desktop/H4 */
  font-family: "Warnock-Pro";
  font-size: 36px;
  font-style: normal;
  font-weight: 300;
  line-height: 110%; /* 39.6px */
  letter-spacing: -0.36px;
`,
)

const ActionDescription = styled('div')(
  ({theme}) => `
  color: ${colors.darkEvergreen[60]};
  font-family: "Aktiv-Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
`,
)

const ActionButton = styled('button')(
  ({theme}) => `
  border: 0;
  background: ${colors.transparent.fully};
  color: ${colors.evergreen[130]};
  cursor: pointer;
  text-align: right;
  font-family: "Aktiv-Grotesk";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%; /* 22px */
`,
)

const Divider = styled('div')(
  ({theme}) => `
  background: ${colors.background[5]};
  height: 1px;
`,
)

const TooltipLabel = styled('span')(
  ({theme}) => `
  color: ${colors.darkEvergreen[100]};
  font-family: "Aktiv-Grotesk";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 22px */
`,
)

type StageChoices = 'Pre-Seed' | 'Seed' | 'Series A+'

const choices = [
  {
    value: 'Pre-Seed',
    label: 'This is where you typically look to validate an idea or have a proof of concept in place ',
  },
  {value: 'Seed', label: 'Normally when you have a live product with traction that you’re looking to further develop'},
  {
    value: 'Series A+',
    label: 'Comes when you have clear growth, revenue, or product-market fit and are ready for scale',
  },
]

function StageForm({onClose}: {onClose: () => void}): React.JSX.Element {
  const {
    form: {profile},
    dispatch,
  } = useProfileContext()
  const [stage, setStage] = React.useState(_.get(profile, 'stage'))
  const description = choices.find(c => c.value === stage)

  return (
    <Stack spacing={5}>
      <Typography variant="h5">Stage</Typography>
      <Typography variant="body2">
        Your fundraising stage helps set angel investor expectations about your business and use of funds
      </Typography>
      <Stack spacing={3}>
        <Typography variant="body3">What stage is your company in?</Typography>
        <Select placeholder="Select…" value={stage} onChange={(_, v) => setStage(v)}>
          <Option value="Pre-Seed">Pre-Seed</Option>
          <Option value="Seed">Seed</Option>
          <Option value="Series A+">Series A+</Option>
        </Select>
        <Typography variant="body4" sx={{minHeight: '70px'}}>
          {description?.label}
        </Typography>
        <PrimaryButton
          size="large"
          onClick={() => {
            dispatch({
              type: 'set_value',
              field: {
                label: 'What stage is your company in?',
                property: 'stage',
                type: 'text',
                sizes: {xs: 12},
              },
              value: stage,
            })
            onClose()
          }}>
          Save changes
        </PrimaryButton>
      </Stack>
    </Stack>
  )
}

// TODO: need to sort out storing enums in dispatch
const founderChoices = {
  'Match with angel investors': 'Match with angel investors',
  'Match with advisors': 'Match with advisors',
  Both: 'Both',
  'Do not match or surface me to anyone': 'Do not match or surface me to anyone',
}

const investorChoices = {
  'Angel investing': 'Angel investing',
  'Advising / advisory shares': 'Advising / advisory shares',
  Both: 'Both',
  'Do not match or surface me to anyone': 'Do not match or surface me to anyone',
}

function OpenToForm({onClose}: {onClose: () => void}): React.JSX.Element {
  const {
    form: {
      profile,
      metadata: {profileType},
    },
    dispatch,
  } = useProfileContext()
  const [openTo, setOpenTo] = React.useState(_.get(profile, 'open_to'))

  const choices = profileType === 'founder' ? founderChoices : investorChoices

  return (
    <Stack spacing={5}>
      <Typography variant="h5">Open to</Typography>
      <Typography variant="body2">
        Control who you want to match with and whether or not you’re still up to matching.
      </Typography>
      <Stack spacing={3}>
        <Typography variant="body3">Do you want to match with angel investors, advisors or both?</Typography>
        <CherubRadioGroup choices={choices} defaultValue={openTo} onChange={v => setOpenTo(v.target.value)} />
        <Typography variant="body4" sx={{minHeight: '70px'}}>
          We only share this information with your matches on Cherub.
        </Typography>
        <PrimaryButton
          size="large"
          onClick={() => {
            dispatch({
              type: 'set_value',
              field: {
                label: 'Do you want to match with angel investors, advisors or both?',
                property: 'open_to',
                type: 'text',
                sizes: {xs: 12},
              },
              value: openTo,
            })
            onClose()
          }}>
          Save changes
        </PrimaryButton>
      </Stack>
    </Stack>
  )
}

const descriptions = {
  'Pre-Seed': {
    text: 'Investors are looking for pre-seed companies to have a clear idea in a growth category and the founding team who will bring it to life better than anyone else.',
    link: 'https://intercom.help/cherub/en/articles/9035322-cherub-profile-guide-pre-seed-stage-companies',
  },
  Seed: {
    text: "There's a live product with traction that you're looking to further develop.",
    link: 'https://intercom.help/cherub/en/articles/9035310-cherub-profile-guide-seed-stage-companies',
  },
  'Series A+': {
    text: "There's clear product market fit and you're ready for scale.",
    link: 'https://intercom.help/cherub/en/articles/9044719-cherub-profile-guide-series-a-stage-companies',
  },
}

export default function SecondaryAction(): React.JSX.Element {
  const {
    form: {
      profile,
      metadata: {profileType},
    },
  } = useProfileContext()
  const [showStageModal, setShowStageModal] = React.useState(false)
  const [showOpenToModal, setShowOpenToModal] = React.useState(false)

  const description = profile.stage in descriptions ? descriptions[profile.stage as StageChoices] : null

  return (
    <Grid container sx={styles.container} spacing={5}>
      {profileType === 'founder' && (
        <CherubModal open={showStageModal} onClose={() => setShowStageModal(false)}>
          <StageForm onClose={() => setShowStageModal(false)} />
        </CherubModal>
      )}
      <CherubModal open={showOpenToModal} onClose={() => setShowOpenToModal(false)}>
        <OpenToForm onClose={() => setShowOpenToModal(false)} />
      </CherubModal>
      <Grid item xs={12}>
        <ActionTitle>Settings</ActionTitle>
      </Grid>
      {profileType === 'founder' && (
        <Grid container item spacing={2}>
          <Grid container item sx={{padding: '10px 0'}} xs={12} direction="row" justifyContent="space-between">
            <Grid item>
              <TooltipLabel>Stage</TooltipLabel>
              {/* <CherubTooltip sx={styles.tooltip} description={content.TODO} /> */}
            </Grid>
            <Grid item>
              <ActionButton type="button" onClick={() => setShowStageModal(true)}>
                {profile?.stage} <NavigateNext />
              </ActionButton>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <ActionDescription>
              {description?.text}{' '}
              <a
                style={{color: colors.evergreen[70]}}
                href={description?.link}
                target="_blank"
                rel="noreferer noreferrer">
                Tips and examples
              </a>
            </ActionDescription>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        {profileType === 'founder' && <Divider />}
      </Grid>
      <Grid container item sx={{padding: '10px 0'}} xs={12} direction="row" justifyContent="space-between">
        <Grid item>
          <TooltipLabel>Open to</TooltipLabel>
          {/* <CherubTooltip sx={styles.tooltip} description={content.TODO} /> */}
        </Grid>
        <Grid item>
          <ActionButton type="button" onClick={() => setShowOpenToModal(true)}>
            {profile?.open_to} <NavigateNext />
          </ActionButton>
        </Grid>
      </Grid>
    </Grid>
  )
}
