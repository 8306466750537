import {PropsWithChildren} from 'react'
import {useAuthentication} from './useAuthentication'

// View wrapper that waits for authentication to load into an unauthenticated or authenticated state

export function AfterAuthLoads(props: PropsWithChildren) {
  const {state} = useAuthentication()

  if (state === 'loading') {
    return <></>
  }

  return <>{props.children}</>
}
