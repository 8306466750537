import {ApolloClient, InMemoryCache} from '@apollo/client'
import {useAuth0} from '@auth0/auth0-react'
import {useEffect, useState} from 'react'
import config from '../config'

// This helps avoid blips where we render something if a person is not logged in
// TODO: turn this into a state machine: 'loading', 'authenticated', 'unauthenticated', 'error'

export function useAuthentication() {
  const [state, setState] = useState<'loading' | 'error' | 'unauthenticated' | 'authenticated'>('loading')
  const [_token, setToken] = useState('')
  const [_client, setClient] = useState<ApolloClient<any> | null>(null)
  const {isLoading, isAuthenticated, getAccessTokenSilently} = useAuth0()

  useEffect(() => {
    if (isLoading) {
      return
    }

    if (!isAuthenticated) {
      setState('unauthenticated')
    } else {
      getAccessTokenSilently()
        .then(t => {
          setToken(t)
          const client = new ApolloClient({
            uri: `${config.app.apiHost}/graphql`,
            cache: new InMemoryCache(),
            name: 'Cherub',
            version: 'beta',
            headers: {
              authorization: `Bearer ${t}`,
            },
          })
          setClient(client)
          setState('authenticated')
        })
        .catch(error => {
          console.error('Error loading authentication', error)
          setState('error')
        })
    }
  }, [isLoading, isAuthenticated, getAccessTokenSilently, setToken, setClient, setState])

  return {state, _token, _client}
}
