import {styled} from '@mui/system'
import colors from '../colors'

type DealTagsProps = {
  tags: string[]
  maxRows?: {
    count: number
    width: number
    padding: number
  }
  maxTags?: number
  bgColor?: string
  textColor?: string
}

let _canvas: HTMLCanvasElement | null = null
let _context: CanvasRenderingContext2D | null = null
function getContext() {
  if (_context) {
    return _context
  }
  _canvas = document.createElement('canvas')
  _context = _canvas.getContext('2d')
  return _context
}

const measureTextWidth = (text: string, font = '12px Aktiv-Grotesk') => {
  const context = getContext()
  if (!context) {
    return 0
  }
  context.font = font
  return context.measureText(text).width
}

export function DealTags(props: DealTagsProps) {
  const {bgColor, maxRows, maxTags = 3, tags, textColor} = props

  if (tags.length === 0) {
    return <></>
  }

  let renderedTags = tags
  if (maxRows) {
    let rowLength = 0
    let row = 1
    renderedTags = []
    for (let i = 0; i < tags.length; i++) {
      const tag = tags[i]
      const tagWidth = measureTextWidth(tag) + maxRows.padding
      // account for the truncated count icon (the +X at the end of the last row)
      const countIcon = row === maxRows.count ? measureTextWidth(`+${tags.length - i}`) + maxRows.padding : 0
      if (rowLength + tagWidth + countIcon > maxRows.width) {
        row++
        if (row > maxRows.count) {
          break
        }
        rowLength = 0
      }
      rowLength += tagWidth
      renderedTags.push(tag)
    }
  } else if (tags.length > maxTags) {
    renderedTags = tags.slice(0, maxTags)
  }
  const extraCount = tags.length - renderedTags.length

  return (
    <Item sx={{maxHeight: '6.25rem'}}>
      {renderedTags.map((tag: string, i: number) => (
        <TagChip
          key={i}
          sx={{
            background: bgColor ?? colors.evergreen[100],
            color: textColor ?? colors.background[1],
            marginRight: '0.38rem',
            marginBottom: '0.625rem',
          }}>
          {tag}
        </TagChip>
      ))}
      {extraCount > 0 && (
        <TagChip
          sx={{
            background: bgColor ?? colors.evergreen[100],
            color: textColor ?? colors.background[1],
            marginRight: '0.38rem',
            marginBottom: '0.625rem',
          }}>
          +{extraCount}
        </TagChip>
      )}
    </Item>
  )
}

export const Item = styled('div')(
  ({theme}) => `
    margin-top: 0.31rem;
    `,
)

export const TagChip = styled('div')(
  ({theme}) => `
    border-radius: 20px;
    background: ${colors.evergreen[100]};
    display: inline-block;
    align-items: center;
    padding: 0.25rem 0.625rem 0.125rem;
  
    color: ${colors.background[1]};
    text-align: center;
    font-family: 'Aktiv-Grotesk';
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  
    pointer: cursor;
  `,
)
