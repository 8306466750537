import Header from './Header'

export const route = {
  path: '/auth/callback',
  element: <Callback />,
}

export function Callback() {
  return (
    <>
      <Header hideRight/>
    </>
  )
}
