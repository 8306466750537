import React from 'react'
import {RouteObject} from 'react-router-dom'
import Footer from './Footer'
import Header from './Header'
import HomeView from './Home'
import {RequiresAuthentication} from './auth/RequiresAuthentication'
import ErrorPage from './error-page'

export const route: RouteObject = {
  path: '/',
  element: (
    <RequiresAuthentication>
      <App />
    </RequiresAuthentication>
  ),
  errorElement: <ErrorPage />,
}

export default function App(): React.JSX.Element {
  return (
    <div>
      <Header />
      <HomeView />
      <Footer />
    </div>
  )
}