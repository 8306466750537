import {gql, useMutation} from '@apollo/client'
import _ from 'lodash'
import {DateTime, DurationLike} from 'luxon'
import mixpanel from 'mixpanel-browser'
import {useCallback, useEffect} from 'react'
import {ProfileDataType, ProfileMetadata} from '../profile/Profile.Contexts'
import {externalLink} from '../profile/utils'

type FileReference = {
  key: string
}

type ProfileViewData = {
  id?: string
  photo?: FileReference
  name?: string
  summary?: string
  links?: string[]
  additionalPhoto?: FileReference // TODO caraousel of photos
  tags?: string[]
}

/** Full profile object version */
export function profileViewData(profile: any): ProfileViewData
/** ProfileContext data structures */
export function profileViewData(metadata: ProfileMetadata, profile?: ProfileDataType): ProfileViewData
export function profileViewData(metadata: ProfileMetadata | any, profile?: ProfileDataType): ProfileViewData {
  if (!profile) {
    // when called with a single argument, it's the full profile object
    profile = metadata.data
  }
  return metadata.profileType === 'founder'
    ? {
        id: metadata?.owner?.id,
        photo: profile?.company?.logo,
        name: profile?.company?.name,
        summary: profile?.company?.tagline,
        links: _.compact([profile?.company?.website]),
        additionalPhoto: profile?.company?.hero, // TODO caraousel of photos
        tags: profile?.match_characteristics,
      }
    : {
        id: metadata?.owner?.id,
        photo: profile?.investor?.headshot,
        name: `${profile?.investor?.first_name ?? ''} ${profile?.investor?.last_name ?? ''}`,
        summary: profile?.investor?.headline,
        links: (profile?.about_materials ?? []).map((m: any) => m.link),
        additionalPhoto: profile?.about_materials?.[0]?.photo, // TODO caraousel of photos
        tags: profile?.match_characteristics,
      }
}

type MetricsInput = {
  targetId: string
  type: 'profile_view' | 'profile_interaction'
}

type LinkedMetricsInput = MetricsInput & {
  extra: {
    section?: string 
    link: string
  }
}

const cache = (() => {
  const data = JSON.parse(localStorage.getItem('@@Cherub/metrics') || '{}')
  return {
    getKeys(module: string, metrics: MetricsInput) {
      let targetId = metrics.targetId
      if (metrics.type === 'profile_interaction') {
        targetId = `${targetId}_${(metrics as LinkedMetricsInput).extra.link}`
      }
      return [module, `id_${targetId}`, metrics.type, 'lastRun']
    },
    get(module: string, metrics: MetricsInput) {
      return DateTime.fromISO(
        _.get(data, this.getKeys(module, metrics)) ?? '1970-01-01T00:00:00.000Z',
      )
    },
    set(module: string, metrics: MetricsInput, lastRun: DateTime) {
      _.set(data, this.getKeys(module, metrics), lastRun.toISO())
      localStorage.setItem('@@Cherub/metrics', JSON.stringify(data))
    },
    clear(module: string, metrics: MetricsInput) {
      _.unset(data, this.getKeys(module, metrics))
      localStorage.setItem('@@Cherub/metrics', JSON.stringify(data))
    },
  }
})()

export function useMetricWhenRendered(module: string, variables: MetricsInput, cooldown: DurationLike, delay = 5) {
  const [createMetric] = useMutation(gql`
    mutation ${module}Metrics($targetId: String!, $type: String!) {
      metrics(input: {
        targetId: $targetId
        type: $type
      }) {
        success
        errors
      }
    }
  `)

  useEffect(() => {
    if (!variables.targetId) {
      return
    }

    const now = Date.now()
    const lastRun = cache.get(module, variables)
    const next = lastRun.plus(cooldown).toMillis()
    if (now < next) {
      return
    }

    const onPageLoad = () => {
      new Promise<void>(resolve => {
        setTimeout(resolve, delay * 1000)
      })
      .then(async () => {
        // Mixpanel tracking?
        cache.set(module, variables, DateTime.now())
        const response = await createMetric({variables})
        // Only retry if it was a non-API error
        if (response.errors) {
          cache.clear(module, variables)
          console.error(`Error creating metrics for ${module}`, response.errors)
        }
      })
      .catch(e => {
        console.error(`Error creating metrics for ${module}`, e)
      })
    }
    if (document.readyState !== 'complete') {
      window.addEventListener('load', onPageLoad, false)
      return () => window.removeEventListener('load', onPageLoad)
    }
    return onPageLoad()
  }, [module, variables, cooldown, delay, createMetric])
}

export function useTrackedExternalLink(module: string, variables: LinkedMetricsInput, cooldown: DurationLike = { hours: 1 }) {
  const [createMetric] = useMutation(gql`
    mutation ${module}Metrics($targetId: String!, $type: String!, $extra: JSON) {
      metrics(input: {
        targetId: $targetId
        type: $type
        extra: $extra
      }) {
        success
        errors
      }
    }
  `)

  const externalLinkProps = externalLink(variables.extra.link)
  const onClick = useCallback(() => {
    if (!variables.targetId || !variables.extra.link) {
      return
    }

    const now = Date.now()
    const lastRun = cache.get(module, variables)
    const next = lastRun.plus(cooldown).toMillis()
    if (now < next) {
      return
    }

    cache.set(module, variables, DateTime.now())
    mixpanel.track(variables.type, variables.extra)
    return createMetric({variables})
      .then(response => {
        if (response.errors) {
          cache.clear(module, variables)
          console.error(`Error creating metrics for ${module}`, response.errors)
        }
      })
      .catch(e => {
        console.error(`Error creating metrics for ${module}`, e)
      })
  }, [module, variables, cooldown, createMetric])

  return {onClick, ...externalLinkProps}
}

