// Given an href, return the props for a safe external link (eg <a> tag)
export function externalLink(href: string) {
  if (href == null) {
    return {}
  }
  if (!href.startsWith('http://') && !href.startsWith('https://')) {
    href = `https://${href}`
  }
  return {
    href,
    rel: 'noopener noreferrer',
    target: '_blank',
  }
}
