import {useAuth0} from '@auth0/auth0-react'
import {useLocation, useNavigate} from 'react-router-dom'

const RETURN_URL_STORAGE_KEY = '@Cherub:returnAfterSignUp'

export function useAuthRedirects() {
  const {loginWithRedirect, logout} = useAuth0()
  const navigate = useNavigate()
  const location = useLocation()

  const returnToDefault = window.location.pathname + window.location.hash
  const signupReturnTo = window.location.pathname + '#/onboarding'
  const afterSignup = location.pathname
  const authorizationParams = {screen_hint: 'register'}

  const signIn = (returnTo: string = returnToDefault) => {
    // Disallow redirecting to the callback page, as a user will get stuck there
    if (returnTo.endsWith('#/auth/callback')) {
      returnTo = returnToDefault
    }

    loginWithRedirect({appState: {returnTo}})
  }
  const signUp = (returnAfterSignUp: string = afterSignup) => {
    window.localStorage.setItem(RETURN_URL_STORAGE_KEY, returnAfterSignUp)
    loginWithRedirect({
      authorizationParams,
      appState: {
        returnTo: signupReturnTo,
      },
    })
  }
  const signOut = () => logout()
  const resumeAfterOnboarding = () => {
    const returnAfterSignUp = window.localStorage.getItem(RETURN_URL_STORAGE_KEY)
    if (returnAfterSignUp) {
      window.localStorage.removeItem(RETURN_URL_STORAGE_KEY)
      navigate(returnAfterSignUp)
      return 'redirected'
    }
    return null
  }

  return {
    signIn,
    signUp,
    resumeAfterOnboarding,
    signOut,
  }
}
