import {Grid, Link, Typography} from '@mui/material'
import {Stack, styled} from '@mui/system'
import React from 'react'
import colors from './colors'

const FooterLink = styled(Link)(
  ({theme}) => `
  color: ${colors.background[1]};
  cursor: pointer;
  textDecoration: none;

  &:visited {
    color: ${colors.background[1]};
  }
`,
)

const FooterText = styled(Typography)(({theme}) => ({
  color: colors.background[5],
  fontFamily: 'Aktiv-Grotesk',
  fontSize: '15px',
}))

const Item = styled('div')(({theme}) => ({}))

function CherubLogo(): React.JSX.Element {
  return (
    <svg width="275" height="48" viewBox="0 0 275 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.435547 24.1189C0.435547 10.2617 10.4073 0.761719 23.6073 0.761719C37.4529 0.761719 44.8421 9.54744 45.7029 20.7617L34.0095 21.1903C33.2921 16.1903 30.566 11.5474 23.5355 11.5474C16.8638 11.5474 12.9899 16.476 12.9899 24.1189C12.9899 31.7617 16.8638 36.6903 23.5355 36.6903C30.566 36.6903 33.2921 32.0474 34.0095 27.0474L45.7029 27.5474C44.8421 38.7617 37.4529 47.476 23.6073 47.476C10.4073 47.476 0.435547 37.976 0.435547 24.1189ZM62.6296 46.6189H50.4339V1.61886H62.6296V18.976H80.6361V1.61886H92.8317V46.6189H80.6361V28.8331H62.6296V46.6189ZM135.267 36.6189V46.6189H99.6845V1.61886H134.621V11.6189H111.665V18.976H129.384V28.8331H111.665V36.6189H135.267ZM181.701 46.6189H169.362C168.429 45.4046 167.856 42.6189 167.497 37.976C167.138 32.8332 164.914 31.6903 160.825 31.6903H152.719V46.6189H140.738V1.61886H163.551C175.245 1.61886 181.056 7.11886 181.056 15.9046C181.056 22.476 176.966 26.7617 171.443 27.476C176.106 28.4046 178.832 31.0474 179.334 36.2617C179.979 42.976 180.195 44.976 181.701 46.6189ZM152.719 22.3332H161.399C166.564 22.3332 169.147 21.0474 169.147 16.9046C169.147 13.2617 166.564 11.3331 161.399 11.3331H152.719V22.3332ZM206.646 47.476C195.025 47.476 186.129 41.9046 186.129 28.7617V1.61886H198.253V26.7617C198.253 33.6189 200.405 37.2617 206.646 37.2617C212.959 37.2617 215.04 33.6189 215.04 26.7617V1.61886H227.235V28.7617C227.235 41.9046 218.268 47.476 206.646 47.476ZM274.02 34.2617C274.02 42.0474 267.85 46.6189 257.305 46.6189H233.775V1.61886H255.798C267.42 1.61886 272.657 6.47601 272.657 14.0474C272.657 19.0474 269.429 22.476 264.622 23.2617C269.931 24.3331 274.02 27.8332 274.02 34.2617ZM255.296 28.5474H245.611V36.9046H255.368C259.6 36.9046 261.466 35.1903 261.466 32.7617C261.466 30.3332 259.6 28.5474 255.296 28.5474ZM245.611 11.2617V19.476H254.22C258.381 19.476 260.031 17.6189 260.031 15.476C260.031 13.1189 258.381 11.2617 254.22 11.2617H245.611Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default function Footer(): React.JSX.Element {
  return (
    <Grid
      container
      sx={{
        flexDirection: {xs: 'column-reverse', md: 'row'},
        width: '100%',
        backgroundColor: colors.evergreen[130],
        color: colors.background[2],
        padding: {md: '3.75rem 7.5rem', xs: '40px 25px 40px 27px'},
      }}>
      <Grid container item xs={12} md={4} sx={{marginTop: {xs: '40px', md: '0'}}}>
        <Stack sx={{width: '365px'}} spacing={4}>
          <Item sx={{color: colors.background[2]}}>
            <FooterLink href="/" sx={{color: colors.background[1]}}>
              <CherubLogo />
            </FooterLink>
            <Typography
              sx={{
                color: colors.background[2],
                fontSize: '12px',
                fontFamily: 'Warnock-Pro',
                display: 'inline-block',
                marginLeft: '0.25rem',
                position: 'relative',
                top: '-36px',
              }}>
              Beta
            </Typography>
          </Item>
          <Item sx={{display: {xs: 'none', md: 'block'}}}>
            <FooterText>
              Cherub is currently in Beta. Got feedback? We'd love to hear it. Email{' '}
              <Link href="mailto:support@investwithcherub.com" sx={{color: colors.background[5]}}>
                support@investwithcherub.com
              </Link>
            </FooterText>
          </Item>
          <Item>
            <FooterText>&copy; 2024 Cherub</FooterText>
          </Item>
        </Stack>
      </Grid>
      <Grid container item xs={12} md={8} justifyContent="flex-end">
        <Grid item xs={6} sm={1} md={2}>
          <Stack spacing={2.5}>
            <Item>
              <Typography variant="mobileh5">Product</Typography>
            </Item>
            <Item>
              <FooterLink underline="none" href="/" variant="body3medium">
                Home
              </FooterLink>
            </Item>
            <Item>
              <FooterLink underline="none" href="/#/deals" variant="body3medium">
                Deal Flow
              </FooterLink>
            </Item>
          </Stack>
        </Grid>
        <Grid item xs={6} sm={1} md={2.5} sx={{paddingLeft: {md: '32px'}}}>
          <Stack spacing={2.5}>
            <Item>
              <Typography variant="mobileh5">Resource</Typography>
            </Item>
            <Item>
              <FooterLink href="https://intercom.help/cherub/en/" underline="none" variant="body3medium">
                Help Center
              </FooterLink>
            </Item>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={1} md={2.5} sx={{paddingLeft: {md: '32px'}, marginTop: {xs: '40px', md: '0'}}}>
          <Stack spacing={2.5}>
            <Item>
              <Typography variant="mobileh5">Company</Typography>
            </Item>
            <Item>
              <FooterLink href="https://www.investwithcherub.com/about-us" underline="none" variant="body3medium">
                About
              </FooterLink>
            </Item>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  )
}
