// Figma: https://www.figma.com/file/qMoxJn4rVySreDEnf2R9Js/Cherub-Annotations?type=design&node-id=399%3A5443&mode=dev
import {Grid, Typography} from '@mui/material'
import {Stack, SxProps, styled} from '@mui/system'
import _ from 'lodash'
import React from 'react'
import colors from '../colors'
import {PrimaryButton, SecondaryButtonLink} from '../ui/Buttons'
import {Done} from '../ui/icons'
import {useProfileContext} from './Profile.Contexts'

const styles: Record<string, SxProps> = {
  container: {
    borderRadius: 5,
    border: `2px solid ${colors.background[4]}`,
    background: colors.background[4],
    padding: 7.5,
  },
}

const ActionDescription = styled('div')(
  ({theme}) => `
  color: ${colors.gray[1]};
  font-family: "Aktiv-Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
`,
)

const AcceptedProfilesTitle = styled('div')(
  ({theme}) => `
  color: ${colors.gray[1]};
  font-family: "Aktiv-Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%;
`,
)

const AcceptedProfilesItem = styled('div')<{completed: boolean}>(
  ({theme, completed}) => `
  color: ${completed ? colors.green[130] : colors.gray[2]};
  cursor: pointer;
  font-family: "Aktiv-Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  text-decoration: underline;
`,
)

const learnMoreURL = 'https://intercom.help/cherub/en/articles/9035310-cherub-profile-guide-seed-stage-companies'


export default function PrimaryAction({onClick}: {onClick: () => void}): React.JSX.Element {
  const {
    form: {profile},
  } = useProfileContext()

  const openTo = 'open_to'
  const minimumFields = [
    {
      label: 'Pitch deck',
      properties: ['pitch.elevator', 'pitch.document_url'],
    },
    {
      label: 'Tagline',
      properties: ['company.tagline'],
    },
    {
      label: 'Photo',
      properties: ['company.logo'],
    },
    {
      label: 'Company website',
      properties: ['company.website'],
    },
    {
      label: 'Open to Investors or Advisors',
      properties: [openTo],
    },
  ]

  const enabled = minimumFields.every(field =>
    field.properties.every(property => !!_.get(profile, property)),
  )

  return (
    <Grid container sx={styles.container} spacing={5}>
      <Grid item xs={12}>
        <Typography variant="h4">Cherub Database</Typography>
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={2}>
          <ActionDescription>
            Submit your profile to the Cherub Database to Get Matches based on who you both are and what you are looking
            for.
          </ActionDescription>
          {/* TODO: investor does not have this piece */}
          <AcceptedProfilesTitle>Accepted profiles have:</AcceptedProfilesTitle>
          <Stack spacing={0}>
            {minimumFields.map(field => {
              const completed = field.properties.every(property => !!_.get(profile, property))
              const id = field.properties[0]
              return (
                <AcceptedProfilesItem
                  key={id}
                  completed={completed}
                  onClick={() => {
                    if (id === openTo) {
                      // TODO: trigger open to modal
                    }

                    const element = document.getElementById(id)
                    if (!element) return
                    window.scrollTo({
                      top: element.parentElement!.parentElement!.getBoundingClientRect().top - 120,
                      behavior: 'smooth',
                    })
                  }}>
                  <Done
                    style={{
                      border: `1px solid ${completed ? colors.green[130] : colors.gray[2]}`,
                      borderRadius: '20px',
                      color: completed ? colors.green[130] : colors.transparent.fully,
                      margin: '0 4px 0 0',
                    }}
                    width={16}
                    height={16}
                  />
                  {field.label}
                </AcceptedProfilesItem>
              )
            })}
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" spacing={2.5}>
          <SecondaryButtonLink size="large" href={learnMoreURL}>
            Learn More
          </SecondaryButtonLink>
          <PrimaryButton
            size="large"
            disabled={!enabled}
            href="https://investwithcherub.typeform.com/cherubselect"
            onClick={() => {
              enabled && onClick()
            }}>
            Submit Profile
          </PrimaryButton>
        </Stack>
      </Grid>
    </Grid>
  )
}
