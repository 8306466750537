import {gql, useMutation} from '@apollo/client'
import {useAuth0} from '@auth0/auth0-react'
import {Typography} from '@mui/material'
import Divider from '@mui/material/Divider'
import Menu, {MenuProps} from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import {Stack, styled} from '@mui/system'
import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {CherubLogo} from './CherubLogo'
import {HeaderProps, PinnedHeader} from './PinnedHeader'
import {useMeQuery} from './api/types'
import {RequiresAuthentication} from './auth/RequiresAuthentication'
import {useAuthRedirects} from './auth/useAuthRedirects'
import {useAuthentication} from './auth/useAuthentication'
import colors from './colors'
import config from './config'
import {CherubModal} from './profile/Profile.CherubModal'
import {PrimaryButton, SecondaryButton} from './ui/Buttons'
import {HamburgerMenu} from './ui/icons'

function LoginActions() {
  const {signIn, signUp} = useAuthRedirects()
  // TODO: return to onboarding here?
  return (
    <Stack direction="row" spacing={6}>
      <SecondaryButton size="large" sx={{bgcolor: colors.background.white}} onClick={() => signIn()}>
        Sign In
      </SecondaryButton>
      <PrimaryButton size="large" onClick={() => signUp()}>
        Get Started
      </PrimaryButton>
    </Stack>
  )
}

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({theme}) => ({
  '& .MuiPaper-root': {
    borderRadius: '1.25rem',
    backgroundColor: colors.darkEvergreen[100],
    color: colors.background['warm white'],
    marginTop: theme.spacing(1),
    minWidth: 180,
    '& .MuiMenu-list': {
      color: colors.background['warm white'],
    },
    '& .MuiMenuItem-root': {
      borderRadius: '0.625rem',
      color: colors.background['warm white'],
      margin: '0.625rem 1.25rem',
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        marginRight: theme.spacing(1.5),
      },
      '&:hover': {
        backgroundColor: colors.evergreen[100],
      },
    },
  },
}))

function StandardMenu() {
  const navigate = useNavigate()
  const {logout} = useAuth0()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (path?: string) => {
    setAnchorEl(null)
    if (path) {
      navigate(path)
    }
  }
  const {data} = useMeQuery()
  const onManageSubscription = data?.me?.profileType === 'investor'
    ? () => window.open('https://intercom.help/cherub/en/articles/9151815-how-to-manage-your-cherub-invest-membership', '_blank')
    : () => generatePortalUrl().then(({data}) => {
        data.stripe.portal.sessionUrl && setTimeout(() => window.open(data.stripe.portal.sessionUrl, '_blank'))
      })

  const [ generatePortalUrl ] = useMutation(gql`
    mutation StripePortal {
      stripe {
        portal(input: {}) {
          sessionUrl
        }
      }
    }
  `)

  return (
    <div>
      <button
        aria-controls={open ? 'navigation-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{
          background: colors.transparent.fully,
          border: 'none',
        }}>
        <HamburgerMenu />
      </button>
      <StyledMenu
        MenuListProps={{
          'aria-labelledby': 'navigation-menu',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose()}>
        <MenuItem onClick={() => handleClose('/')} disableRipple>
          Home
        </MenuItem>
        <MenuItem onClick={() => handleClose('/deals')} disableRipple>
          Deal Flow
        </MenuItem>
        <MenuItem onClick={() => handleClose('/directory')} disableRipple>
          Directory
        </MenuItem>
        <MenuItem onClick={() => handleClose('/engagement')} disableRipple>
          Engagement
        </MenuItem>
        <MenuItem onClick={() => handleClose(`/profile/${data?.me.orgId}`)} disableRipple>
          My Profile
        </MenuItem>
        <MenuItem onClick={() => handleClose(`/profile/${data?.me.orgId}/edit`)} disableRipple>
          Edit Profile
        </MenuItem>
        <MenuItem onClick={() => window.open('https://intercom.help/cherub/en/', '_blank')} disableRipple>
          Help Center
        </MenuItem>
        <MenuItem onClick={onManageSubscription} disableRipple>
          Manage Subscription
        </MenuItem>
        <Divider sx={{height: '0.00625rem', background: colors.background['warm white']}} />
        <MenuItem onClick={() => logout()} disableRipple>
          Sign Out
        </MenuItem>
      </StyledMenu>
    </div>
  )
}

function DealActions(props: any) {
  const [updateIntro, {data, loading, error}] = useMutation(gql`
    mutation DealsupdateIntro($id: ID!, $requestIntro: Boolean) {
      deals {
        updateIntro(input: {id: $id, requestIntro: $requestIntro}) {
          deal {
            id
            requestIntro
          }
        }
      }
    }
  `)

  const [notNow, setNotNow] = useState(false)
  const [getIntroText, setGetIntroText] = useState(false)
  function noIntro() {
    updateIntro({
      variables: {id: props.deal, requestIntro: false},
      onCompleted: data => {
        setTimeout(() => props.updatedFn(false), 3000)
        setNotNow(true)
      },
    })
  }

  function getIntro() {
    updateIntro({
      variables: {id: props.deal, requestIntro: true},
      onCompleted: data => {
        setTimeout(() => props.updatedFn(false), 3000)
        setGetIntroText(true)
      },
    })
  }

  return (
    <Stack direction="row" spacing={6}>
      <PrimaryButton
        size="medium-large"
        sx={{color: colors.background[1], paddingTop: '14px'}}
        onClick={() => noIntro()}>
        Not Now {notNow && <span>&#x2714;</span>}
      </PrimaryButton>
      <SecondaryButton
        size="medium-large"
        sx={{
          bgcolor: colors.background[1],
          borderColor: colors.background[5],
          color: colors.darkEvergreen[100],
          paddingTop: '14px',
        }}
        onClick={() => getIntro()}>
        {getIntroText ? 'Requested' : ' Get Intro'}
      </SecondaryButton>
    </Stack>
  )
}

export function MatchHeader(props: any) {
  const matchStatus = props.matchStatus 
  const Component = props.component

  return (
    <PinnedHeader backgroundColor={colors.darkEvergreen[100]} className={'.header-cherub'}>
      <div>
        <CherubLogo color={colors.background[2]} />
        <Typography
          sx={{
            color: colors.background[2],
            fontSize: '.75rem',
            fontFamily: 'Warnock-Pro',
            display: 'inline-block',
            marginLeft: '0.25rem',
            height: '100%',
            position: 'relative',
            top: '-14px',
          }}>
          Beta
        </Typography>
      </div>
      {/* <DealActions deal={props.deal} match={props.match} matchStatus={matchStatus} component={component} updatedFn={props.updatedFn} /> */}
      <Component deal={props.deal} match={props.match} matchStatus={matchStatus} updatedFn={props.updated} />
    </PinnedHeader>
  )
}

export default function Header({right, hideRight}: HeaderProps): React.JSX.Element {
  const {state} = useAuthentication()
  const [showDebugInfo, setShowDebugInfo] = useState(false)

  // RequiresAuthentication is a bit of a load bearing wrapper,
  // as this is where we check the onboarding state of a user and forward them along to onboarding if incomplete
  const standardRight = state === 'authenticated' ? <RequiresAuthentication><StandardMenu /></RequiresAuthentication> : <LoginActions />

  const debugDescription = `
  Version: ${config.app.version}
  Build: ${config.app.build}
  `

  const debugListener = (e: KeyboardEvent) => {
    if (e.key === '/' && e.metaKey && e.altKey && e.ctrlKey) {
      setShowDebugInfo(true)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', debugListener)
    return () => document.removeEventListener('keydown', debugListener)
  }, [])

  return (
    <PinnedHeader className={'.header-cherub'}>
      <CherubModal open={showDebugInfo} onClose={() => setShowDebugInfo(false)}>
        <pre>{debugDescription}</pre>
      </CherubModal>
      <div>
        <CherubLogo color={colors.darkEvergreen[100]} />
        <Typography
          sx={{
            fontSize: '.75rem',
            fontFamily: 'Warnock-Pro',
            display: 'inline-block',
            marginLeft: '0.25rem',
            height: '100%',
            position: 'relative',
            top: '-14px',
          }}>
          Beta
        </Typography>
      </div>
      {state !== 'loading' && !hideRight && (right || standardRight)}
    </PinnedHeader>
  )
}
