import {Option as BaseOption, optionClasses} from '@mui/base/Option'
import {Select as BaseSelect, SelectProps, SelectRootSlotProps} from '@mui/base/Select'
import {styled} from '@mui/system'
import * as React from 'react'
import colors from '../colors'
import {ChevronDown, ChevronUp} from '../ui/icons'

export const Select = React.forwardRef(function Select<TValue extends {}, Multiple extends boolean>(
  props: SelectProps<TValue, Multiple>,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const slots: SelectProps<TValue, Multiple>['slots'] = {
    root: CustomButton,
    listbox: Listbox,
    popup: Popup,
    ...props.slots,
  }

  return <BaseSelect {...props} ref={ref} slots={slots} />
}) as <TValue extends {}, Multiple extends boolean>(
  props: SelectProps<TValue, Multiple> & React.RefAttributes<HTMLButtonElement>,
) => JSX.Element

const CustomButton = React.forwardRef(function CustomButton<TValue extends {}, Multiple extends boolean>(
  props: SelectRootSlotProps<TValue, Multiple>,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const {ownerState, ...other} = props
  return (
    <StyledButton type="button" {...other} ref={ref}>
      {other.children}
      {ownerState.open ? <ChevronUp /> : <ChevronDown />}
    </StyledButton>
  )
})

const StyledButton = styled('button', {shouldForwardProp: () => true})(
  ({theme}) => `
  background: ${colors.background.white};
  border-radius: 8px;
  border: 1px solid ${colors.background[4]};
  position: relative;
  padding: 10px;
  text-align: left;

  /* Headline/Mobile/h4 */
  color: ${colors.darkEvergreen[100]};
  font-family: "Warnock-Pro";
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 115%; /* 27.6px */
  letter-spacing: -0.24px;
  outline: none;
  width: 100%;

  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  & > svg {
    font-size: 1rem;
    position: absolute;
    height: 100%;
    top: 0;
    right: 10px;
  }
  `,
)

const Listbox = styled('ul')(
  ({theme}) => `
  background: ${colors.background.white};
  border: 1px solid ${colors.background[4]};
  border-radius: 12px;
  box-sizing: border-box;
  padding: 6px;
  margin: 12px 0;
  min-width: 320px;
  overflow: auto;
  outline: 0px;

  /* Headline/Mobile/h4 */
  color: ${colors.darkEvergreen[100]};
  font-family: "Warnock-Pro";
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 115%; /* 27.6px */
  letter-spacing: -0.24px;
  outline: none;
  width: 100%;
  `,
)

export const Option = styled(BaseOption)(
  ({theme}) => `
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionClasses.selected} {
    background-color: ${colors.transparent[10]};
  }
  `,
)

const Popup = styled('div')`
  z-index: 9999;
`
