import {RegularBreakpoints} from '@mui/material'
import _ from 'lodash'
import {PendingFileState, ProfileDataType} from './Profile.Contexts'

export type BaseProfileField = {
  property: string
  label: string
  description?: string
  tooltip?: string
  placeholder?: string
  sizes: RegularBreakpoints
}

export function isFieldFilled(field: ProfileField, profile: ProfileDataType, pendingFiles: PendingFileState) {
  return !_.isEmpty(_.get(profile, field.property)) || pendingFiles[field.property] != null
}

export type ImageProfileField = BaseProfileField & {
  type: 'image'
  extra: {
    previewType?: 'avatar'
    hideText?: boolean
    // TODO: preview annotation?
    // see: https://www.figma.com/file/qMoxJn4rVySreDEnf2R9Js/Cherub-Annotations?type=design&node-id=335-39844&mode=dev
  }
}

export type TagProfileField = BaseProfileField & {
  type: 'tags'
  categories: string[]
  extra: {
    modalPrompt: string
    modalDescription?: string
    maxItems: number | null
  }
}

export type RaiseProfileField = BaseProfileField & {
  type: 'raise'
  repeats?: number
}

export type DateProfileField = BaseProfileField & {
  type: 'date'
}

export type SelectProfileField = BaseProfileField & {
  type: 'select'
  options: Array<{value: string; label: string}>
}

export type InputProfileField = BaseProfileField & {
  type: 'text'
  extra?: {
    maxLength?: number
    textArea?: {
      rows?: number
    }
    showPreview?: boolean
    repeats?: number
  }
}

export type ProfileField =
  | ImageProfileField
  | TagProfileField
  | RaiseProfileField
  | DateProfileField
  | SelectProfileField
  | InputProfileField

export type RepeatingFields = {
  type: 'repeating'
  minimum: number
  repeats: number
  property: string
  fields: Array<ProfileField>
}

export type ProfileFieldList = Array<React.FC | ProfileField | RepeatingFields>

export interface Section {
  title: string
  percent: number
  description?: React.JSX.Element
  fields: ProfileFieldList
  repeats?: number // TODO: bad name, prob bad to be just a number
}

export function anchorFor(section: Section): string {
  return section.title.toLowerCase().replace(' ', '-')
}

export function isSectionFinished(section: Section, profile: ProfileDataType, pendingFiles: PendingFileState): boolean {
  for (const field of section.fields) {
    if (typeof field === 'function') {
      continue
    }

    if (field.type === 'repeating') {
      for (const childField of field.fields) {
        // repeat sections are finished if first is finished
        const property = `${field.property}[0].${childField.property}`
        if (!isFieldFilled({...childField, property}, profile, pendingFiles)) {
          return false
        }
      }
      continue
    }

    if (!isFieldFilled(field, profile, pendingFiles)) {
      return false
    }
  }
  return true
}

export const commonFields = {
  companyName: {
    property: 'company.name',
    label: 'Company Name',
    sizes: {xs: 12, md: 6},
    placeholder: 'Name',
    type: 'text',
  } as InputProfileField,
  contactFirstName: {
    property: 'organization_contact.first_name',
    label: 'First Name',
    sizes: {xs: 6},
    type: 'text',
  } as InputProfileField,
  contactLastName: {
    property: 'organization_contact.last_name',
    label: 'Last Name',
    sizes: {xs: 6},
    type: 'text',
  } as InputProfileField,
  contactJobRole: {
    property: 'organization_contact.job_role',
    label: 'Your role in the company',
    // tooltip: content.TODO,
    sizes: {xs: 12},
    type: 'text',
    extra: {
      maxLength: 40,
    },
  } as InputProfileField,
  investorFirstName: {
    property: 'investor.first_name',
    label: 'First Name',
    sizes: {xs: 12},
    type: 'text',
  } as InputProfileField,
  investorLastName: {
    property: 'investor.last_name',
    label: 'Last Name',
    sizes: {xs: 12},
    type: 'text',
  } as InputProfileField,
  investorHeadline: {
    property: 'investor.headline',
    label: 'What\'s the headline we should include under your name?',
    sizes: {xs: 12},
    type: 'text',
    placeholder:'Founder with 2x multi-million dollar exits, currently CEO/Co-founder at @investwithcherub',
    extra: {
      maxLength: 100,
      textArea: {
        rows: 3,
      },
    },
  } as InputProfileField,
}
