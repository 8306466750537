import {Alert} from '@mui/material'
import {Box, Stack, styled} from '@mui/system'
import auth0, {Auth0Error} from 'auth0-js'
import {useState} from 'react'
import Header from './Header'
import colors from './colors'
import config from './config'
import {CherubModal} from './profile/Profile.CherubModal'
import {ProfileKindType} from './profile/Profile.Contexts'
import CherubRadioGroup from './profile/Profile.RadioGroup'
import {PrimaryButton, SecondaryButton} from './ui/Buttons'
import {Done} from './ui/icons'

declare global {
  // this is set in the Custom Login page in Auth0 in a script tag
  var __our__auth0_config: string
}

export const route = {
  path: '/',
  element: <Authenticate />,
}

let c: any = null
const injectedConfig = () => {
  if (c) {
    return c
  }
  c = JSON.parse(decodeURIComponent(escape(window.atob(window.__our__auth0_config))))
  return c
}

type LoginAction = {
  type: 'login'
  username: string
  password: string
}
type RegisterAction = {
  type: 'register'
  username: string
  password: string
  kind: ProfileKindType
}
type ForgotAction = {
  type: 'forgot'
  username: string
}

export const LoginInputWrapper = styled('div')(
  ({theme}) => `
  border-radius: 0.5rem;
  border: 1px solid ${colors.background[4]};
  background: ${colors.background['warm white']};

  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  padding: 0.625rem;
`,
)

export const LoginLabel = styled('label')(
  ({theme}) => `
  color: ${colors.darkEvergreen[60]};
  font-family: "Aktiv-Grotesk";
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 0.975rem */
`,
)

export const LoginInput = styled('input')(
  ({theme}) => `
  background: ${colors.transparent.fully};
  box-sizing: border-box;
  border: 0;
  color: ${colors.darkEvergreen[100]};
  display: block;
  flex: 1 0 0;
  font-family: "Warnock-Pro";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 300;
  line-height: 115%; /* 1.725rem */
  letter-spacing: -0.015rem;
  outline: none;
  width: 100%;

  ::placeholder {
    color: ${colors.darkEvergreen[40]};
  }
`,
)

type Action = LoginAction | RegisterAction | ForgotAction

function authenticate(action: Action) {
  const leeway = injectedConfig().internalOptions.leeway
  if (leeway) {
    const convertedLeeway = parseInt(leeway)

    if (!isNaN(convertedLeeway)) {
      injectedConfig().internalOptions.leeway = convertedLeeway
    }
  }

  const params = Object.assign(
    {
      overrides: {
        __tenant: injectedConfig().auth0Tenant,
        __token_issuer: injectedConfig().authorizationServer.issuer,
      },
      domain: injectedConfig().auth0Domain,
      clientID: injectedConfig().clientID,
      redirectUri: injectedConfig().callbackURL,
      responseType: 'code',
    },
    injectedConfig().internalOptions,
  )

  const client = new auth0.WebAuth(params)
  switch (action.type) {
    case 'login': {
      // TODO: handle promise
      return new Promise((resolve, reject) =>
        client.login(
          {
            realm: 'Username-Password-Authentication',
            username: action.username,
            password: action.password,
          },
          (err, authResult) => {
            if (err) {
              reject(err)
            } else {
              resolve(authResult)
            }
          },
        ),
      )
    }
    case 'register': {
      // TODO: handle promise
      return new Promise((resolve, reject) =>
        client.redirect.signupAndLogin(
          {
            connection: 'Username-Password-Authentication',
            email: action.username,
            password: action.password,
            userMetadata: {
              account_type: action.kind,
              callback_url: config.auth0.devRegisterCallbackUri,
            },
          },
          (err, authResult) => {
            if (err) {
              reject(err)
            } else {
              resolve(authResult)
            }
          },
        ),
      )
    }
    case 'forgot': {
      return new Promise((resolve, reject) =>
        client.changePassword(
          {
            connection: 'Username-Password-Authentication',
            email: action.username,
          },
          (err, authResult) => {
            if (err) {
              reject(err)
            } else {
              resolve(authResult)
            }
          },
        ),
      )
    }
  }
}

function isLongEnough(password: string) {
  return password.length >= 8
}

function hasLowerCase(password: string) {
  return /[a-z]/.test(password)
}

function hasUpperCase(password: string) {
  return /[A-Z]/.test(password)
}

function hasNumber(password: string) {
  return /[0-9]/.test(password)
}


const termsURL = 'http://www.investwithcherub.com/terms-of-service'
const privacyURL = 'http://www.investwithcherub.com/privacy-policy'

export function Authenticate() {
  const [username, setUsername] = useState('')
  const [forgotUsername, setForgotUsername] = useState('')
  const [password, setPassword] = useState('')
  const [mode, setMode] = useState<Action['type']>(injectedConfig()?.extraParams?.screen_hint || 'login')
  const [kind, setKind] = useState<ProfileKindType | null>(null)
  const [showForgot, setShowForgot] = useState(false)
  const [submitLocked, setSubmitLocked] = useState(false)
  const [error, setError] = useState<Auth0Error | null>(null)
  const [message, setMessage] = useState<string | null>(null)

  const submitAuth = (e: Event, action: Action) => {
    e.preventDefault()
    if (!submitLocked) {
      setSubmitLocked(true)
      // don't unlock on success as they will get redirected
      authenticate(action).catch((err) => {
        setSubmitLocked(false)
        setError(err)
      })
    }
  }

  const showForgotModal = () => {
    setShowForgot(true)
    setForgotUsername(username)
  }

  const canSubmitRegister = !!(username && password && kind) && (
    hasLowerCase(password) && hasUpperCase(password) && hasNumber(password) && isLongEnough(password)
  )
  const canSubmitLogin = !!(username && password)
  const canSubmitForgot = forgotUsername.match(/.+@.+\..+/) !== null

  return (
    <Box>
      <Header hideRight />
      <CherubModal open={showForgot} onClose={() => setShowForgot(false)} omitSpacer={true}>
        <Stack spacing={8}>
          <Box typography="h5">Forgot your password?</Box>
          <Box typography="body2">
            Enter the email address you use to sign in, and we’ll send you a link to reset your password.
          </Box>
          <LoginInputWrapper sx={{bgcolor: colors.background.white}}>
            <LoginLabel htmlFor="username">Email</LoginLabel>
            <LoginInput
              id="username"
              type="text"
              value={forgotUsername}
              placeholder="example@email.com"
              onChange={e => setForgotUsername(e.target.value)}
            />
          </LoginInputWrapper>
          <Stack direction="row" spacing={3}>
            <PrimaryButton
              size="medium-large"
              disabled={!canSubmitForgot}
              onClick={() => {
                authenticate({type: 'forgot', username: forgotUsername})
                  .then(() => {
                    setShowForgot(false)
                    setMessage('If an account with that email exists, we have send you a link to reset your password.')
                  })
                  .catch((err) => setError(err))
              }}>
              Send link
            </PrimaryButton>
            <SecondaryButton size="medium-large" onClick={() => setShowForgot(false)}>
              Cancel
            </SecondaryButton>
          </Stack>
        </Stack>
      </CherubModal>
      <Box margin="0 auto" width="20rem">
        <Box typography="h4" textAlign="center" marginBottom={2}>
          Welcome to Cherub
        </Box>
        {mode === 'login' && (
          <>
            {error && (
              <Alert color="warning">
                Unable to sign in. Please check your credentials and try again.
                If you continue to experience issues,
                you may reset your password or contact support for further assistance.
              </Alert>
            )}
            {message && (
              <Alert color="success">{message}</Alert>
            )}
            <Box typography="body2" color={colors.darkEvergreen[75]} textAlign="center" marginBottom={5}>
              New to Cherub?{' '}
              <span
                style={{cursor: 'pointer', textDecoration: 'underline'}}
                role="button"
                aria-pressed="false"
                onClick={() => setMode('register')}>
                Sign Up
              </span>
            </Box>
            <Box bgcolor={colors.background[5]} height="1px" marginBottom={5} />
            <form>
              <Stack spacing={5}>
                <LoginInputWrapper>
                  <LoginLabel htmlFor="username">Email</LoginLabel>
                  <LoginInput
                    id="username"
                    type="text"
                    value={username}
                    placeholder="example@email.com"
                    onChange={e => setUsername(e.target.value)}
                  />
                </LoginInputWrapper>
                <LoginInputWrapper>
                  <LoginLabel htmlFor="password">Password</LoginLabel>
                  <LoginInput
                    id="password"
                    type="password"
                    value={password}
                    placeholder="Create password"
                    onChange={e => setPassword(e.target.value)}
                  />
                </LoginInputWrapper>
                <Box typography="body2" color={colors.darkEvergreen[60]} textAlign="center">
                  <div
                    role="button"
                    aria-pressed="false"
                    onClick={() => showForgotModal()}
                    style={{cursor: 'pointer', textDecoration: 'underline'}}>
                    Forgot your password?
                  </div>
                </Box>
                <PrimaryButton
                  size="large"
                  type="submit"
                  disabled={!canSubmitLogin}
                  onClick={(e: Event) => submitAuth(e, {type: 'login', username, password})}
                >
                  Sign in
                </PrimaryButton>
                <Box typography="body2" color={colors.darkEvergreen[60]}>
                  By logging in, you agree to our{' '}
                  <a style={{color: colors.darkEvergreen[60]}} target="_blank" rel="noreferrer" href={termsURL}>
                    Terms
                  </a>
                  . See how we use your data in our{' '}
                  <a style={{color: colors.darkEvergreen[60]}} target="_blank" rel="noreferrer" href={privacyURL}>
                    Privacy Policy
                  </a>
                  .
                </Box>
              </Stack>
            </form>
          </>
        )}
        {mode === 'register' && (
          <>
            {error && (
              <Alert color="warning">
                We couldn't complete your registration.
                Please ensure all fields are correctly filled out and try again.
                If the problem persists, contact support for assistance.
              </Alert>
            )}
            <Box typography="body2" color={colors.darkEvergreen[75]} textAlign="center" marginBottom={5}>
              Already have an account?{' '}
              <span
                style={{cursor: 'pointer', textDecoration: 'underline'}}
                role="button"
                aria-pressed="false"
                onClick={() => setMode('login')}>
                Sign In
              </span>
            </Box>
            <Box bgcolor={colors.background[5]} height="1px" marginBottom={5} />
            <Box typography="body2" color={colors.darkEvergreen[75]} marginBottom={5}>
              Are you a founder or angel investor?
            </Box>
            <Box typography="body3" color={colors.darkEvergreen[60]}>
              If you identify as both you should create two separate accounts.
            </Box>
            <CherubRadioGroup
              choices={{founder: "I'm a founder", investor: "I'm an angel investor"}}
              onChange={(_, v) => setKind(v as typeof kind)}
            />
            <Box bgcolor={colors.background[5]} height="1px" marginBottom={5} />
            <Stack spacing={5}>
              <LoginInputWrapper>
                <LoginLabel htmlFor="username">Email</LoginLabel>
                <LoginInput
                  id="username"
                  type="text"
                  value={username}
                  placeholder="example@email.com"
                  onChange={e => setUsername(e.target.value)}
                />
              </LoginInputWrapper>
              <LoginInputWrapper>
                <LoginLabel htmlFor="password">Password</LoginLabel>
                <LoginInput
                  id="password"
                  type="password"
                  value={password}
                  placeholder="Enter password"
                  onChange={e => setPassword(e.target.value)}
                />
              </LoginInputWrapper>
              <PrimaryButton
                size="large"
                disabled={!canSubmitRegister}
                onClick={(e: Event) => {
                  submitAuth(e, {type: 'register', username, password, kind: kind!})
                }}>
                Sign up
              </PrimaryButton>
              <Box typography="body2" marginBottom={4}>
                <div style={{color: isLongEnough(password) ? colors.darkEvergreen[100] : colors.darkEvergreen[60]}}>
                  <Done visibility={isLongEnough(password) ? 'visible' : 'hidden'} /> 8+ characters
                </div>
                <div style={{color: hasNumber(password) ? colors.darkEvergreen[100] : colors.darkEvergreen[60]}}>
                  <Done visibility={hasNumber(password) ? 'visible' : 'hidden'} /> Min. one number
                </div>
                <div style={{color: hasLowerCase(password) ? colors.darkEvergreen[100] : colors.darkEvergreen[60]}}>
                  <Done visibility={hasLowerCase(password) ? 'visible' : 'hidden'} /> Min. one lowercase letter
                </div>
                <div style={{color: hasUpperCase(password) ? colors.darkEvergreen[100] : colors.darkEvergreen[60]}}>
                  <Done visibility={hasUpperCase(password) ? 'visible' : 'hidden'} /> Min. one uppercase letter
                </div>
              </Box>
              <Box bgcolor={colors.background[5]} height="1px" marginBottom={5} />
              <Box typography="body2" color={colors.darkEvergreen[60]}>
                By signing up, you agree to our{' '}
                <a style={{color: colors.darkEvergreen[60]}} target="_blank" rel="noreferrer" href={termsURL}>
                  Terms
                </a>
                . See how we use your data in our{' '}
                <a style={{color: colors.darkEvergreen[60]}} target="_blank" rel="noreferrer" href={privacyURL}>
                  Privacy Policy
                </a>
                .
              </Box>
            </Stack>
          </>
        )}
      </Box>
    </Box>
  )
}
