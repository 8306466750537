import {Box} from '@mui/material'
import {SxProps, styled} from '@mui/system'
import {DateTime} from 'luxon'
import * as React from 'react'
import colors from '../colors'
import {SecondaryButton} from '../ui/Buttons'
import {Add, NavigateNext} from '../ui/icons'
import {CherubModal} from './Profile.CherubModal'
import {useProfileContext} from './Profile.Contexts'
import ManageRaiseForm from './Profile.ManageRaiseForm'
import CherubTooltip from './Profile.Tooltip'

const style: Record<string, SxProps> = {
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 325,
    height: 600,
    bgcolor: colors.background['warm white'],
    boxShadow: 4,
    borderRadius: 4,
    padding: '21.5px 25px',
  },
}

const DivElement = styled('div')(
  ({theme}) => `
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.375rem;
`,
)

const LabelElement = styled('label')(
  ({theme}) => `
  color: ${colors.darkEvergreen[60]};

  /* Body/Body 3 Medium */
  font-family: Aktiv-Grotesk;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 0.975rem */
`,
)

const RaiseInputDisplay = styled('div')(
  ({theme}) => `
  border-radius: 8px;
  border: 1px solid ${colors.gray[4]};
  background: ${colors.background.white};
  cursor: pointer;

  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
`,
)

const RaiseSummary = styled('div')(
  ({theme}) => `
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 6px;
  flex: 1 0 0;
  flex-wrap: wrap;
`,
)

const StateChip = styled('div')(
  ({theme}) => `
  border-radius: 20px;
  background: ${colors.gray[5]};

  align-items: center;
  padding: 6px 10px;

  color: ${colors.background.black};
  text-align: center;
  font-family: "Aktiv-Grotesk";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
`,
)

type ProfileInputProps = {
  'aria-label': string // makes this required
  raiseIndex: number
}

// This is an all encompasing blob for managing raises.
// It's an anti-pattern to the forms, but the whole UI and data here
// in this section is an anti-pattern already without baking in a lot more nuance into the form builder
function RaiseInput(props: ProfileInputProps): React.JSX.Element {
  const {
    form: {profile},
  } = useProfileContext()
  const [showPicker, setShowPicker] = React.useState(false)
  const [raiseIndex, setRaiseIndex] = React.useState(0)

  const hasStage = profile.stage !== undefined

  return (
    <DivElement>
      <LabelElement>{props['aria-label']}</LabelElement>
      {profile.raises?.map((raise: any, index: number) => {
        let status = 'Active'
        try {
          status = DateTime.fromISO(raise.close_date) < DateTime.now() ? 'Closed' : 'Active'
        } catch (e) {
          console.error('Error parsing date', raise.close_date, e)
        }
        return (
          <RaiseInputDisplay
            key={index}
            onClick={() => {
              setRaiseIndex(index)
              setShowPicker(true)
            }}>
            <RaiseSummary>
              {raise.name} {raise.amount}
              <StateChip>{status}</StateChip>
            </RaiseSummary>
            <div style={{flexShrink: 0}}>
              <NavigateNext />
            </div>
          </RaiseInputDisplay>
        )
      })}
      <SecondaryButton
        alt="Must have set a raise stage first"
        disabled={!hasStage}
        sx={{minWidth: {xs: '100%', sm: 'auto'}}}
        size="medium-large"
        aria-label="Add new raise"
        onClick={() => {
          setRaiseIndex(profile.raises?.length ?? 0)
          setShowPicker(true)
        }}>
        <Add /> Add
        {!hasStage && <CherubTooltip description="You must set a Stage before you can create new raises" />}
      </SecondaryButton>
      {showPicker && (
        <CherubModal
          open={showPicker}
          onClose={() => setShowPicker(false)}
          aria-labelledby="modal-title"
          aria-describedby="modal-description">
          <Box sx={style.modal}>
            <ManageRaiseForm raiseIndex={raiseIndex} onSave={() => setShowPicker(false)} />
          </Box>
        </CherubModal>
      )}
    </DivElement>
  )
}

export default RaiseInput
