import {SelectProps} from '@mui/base'
import {Typography} from '@mui/material'
import {Box, styled} from '@mui/system'
import * as React from 'react'
import colors from '../colors'
import {Option, Select} from './Profile.Select'
import CherubTooltip from './Profile.Tooltip'
import {SelectProfileField} from './common'

const DivElement = styled('div')(
  ({theme}) => `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
`,
)

type ProfileSelectProps = {
  field: SelectProfileField & {
    value: string
  }
  select: SelectProps<string, false, 'button'> & {
    'aria-label': string // makes this required
  }
}

const SelectField = React.forwardRef(function CustomSelectField(
  props: ProfileSelectProps,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  // Highlights repeating is not an ideal pattern, so we'll handle it one off and think on it later
  return (
    <DivElement>
      <label htmlFor={props.field.property}>
        <Typography variant="body4medium" color={colors.darkEvergreen[60]}>
          {props.select['aria-label']}
        </Typography>
        {props.field.tooltip && <CherubTooltip description={props.field.tooltip} />}
      </label>
      {props.field.description && <Box sx={{typography: 'body3medium'}}>{props.field.description}</Box>}
      <Select {...props.select} value={props.field.value} ref={ref}>
        {props.field.options.map(option => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    </DivElement>
  )
})

export default SelectField
