import {Modal, ModalProps} from '@mui/material'
import {Box, SxProps} from '@mui/system'
import colors from '../colors'
import {Close} from '../ui/icons'

const styles: Record<string, SxProps> = {
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 325,
    maxHeight: 600,
    bgcolor: colors.background['warm white'],
    boxShadow: 4,
    borderRadius: 4,
    padding: '21.5px 25px',
    display: 'flex',
    flexDirection: 'column',
  },
  spacer: {
    flex: '0 1 90px',
  },
  children: {
    flex: '1 0 0',
  },
}

// TODO: revisit omitSpacer
export function CherubModal(props: React.PropsWithChildren<ModalProps> & {omitSpacer?: boolean}): React.JSX.Element {
  return (
    <Modal {...props}>
      <Box sx={styles.modal}>
        <Close
          style={{position: 'absolute', top: 8, right: 8, cursor: 'pointer', color: 'black'}}
          aria-label="Close"
          onClick={() => props.onClose && props.onClose({}, 'backdropClick')}
        />
        {props.omitSpacer || <Box sx={styles.spacer} />}
        <Box sx={styles.children}>{props.children}</Box>
      </Box>
    </Modal>
  )
}
