import {styled} from '@mui/system'
import colors from '../colors'

export const CardCaraousel = styled('div')(
  ({theme}) => `
  display: flex;
  overflow-x: auto;
  gap: 1.25rem;
`,
)

export const Card = styled('div')<{background?: string}>(
  ({theme, background = colors.transparent.fully}) => `
  border-radius: 1.25rem;
  border: 2px solid ${colors.background[3]};

  align-items: flex-start;
  align-content: stretch;
  align-self: stretch;
  background: ${background};
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  padding: 3.125rem;
`,
)
export const BoundedImage = styled('img')(
  ({theme}) => `
  max-width: 100%;
  max-height: 100%;
`,
)
export const CardRow = styled('div')<{borderColor?: string}>(
  ({theme, borderColor = colors.background[5]}) => `
  border-bottom: 1px solid ${borderColor};

  align-items: end;
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  padding: 0.9375rem 0rem;
`,
)
