import {ThemeProvider} from '@mui/system'
import {LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon'
import * as Sentry from '@sentry/react'
import mixpanel from 'mixpanel-browser'
import React from 'react'
import ReactDOM from 'react-dom/client'
import {RouterProvider} from 'react-router-dom'
import config from './config'
import './index.css'
import {Auth0ProviderWithNavigate, router} from './providers'
import reportWebVitals from './reportWebVitals'
import theme from './theme'

mixpanel.init(config.app.mixpanel, {debug: true, track_pageview: 'full-url', persistence: 'localStorage'})
Sentry.init({
  environment: config.app.env,
  dsn: config.app.sentry,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [config.app.apiHost],
  // Session Replay
  replaysSessionSampleRate: 0.0,
  replaysOnErrorSampleRate: 1.0,
  enabled: process.env.NODE_ENV !== 'development',
})

const root = ReactDOM.createRoot(
  document.getElementById('app-root')! /* eslint @typescript-eslint/no-non-null-assertion: 0 */,
)

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <Auth0ProviderWithNavigate>
          <RouterProvider router={router} />
        </Auth0ProviderWithNavigate>
      </LocalizationProvider>
    </ThemeProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
