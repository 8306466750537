import {Button as MuiBaseButton, buttonClasses} from '@mui/base/Button'
import {Theme, styled} from '@mui/system'
import {useHref} from 'react-router-dom'
import colors from '../colors'

type Sizes = 'small' | 'medium' | 'medium-large' | 'large'

type ModifyableCss = 'padding' | 'fontSize' | 'lineHeight'

function BaseButton({children, ...props}: React.ComponentProps<typeof MuiBaseButton>) {
  const hrefTo = useHref(props.href || '')
  // TODO: better validation
  if (props.href && !props.href.startsWith('http') && !props.href.startsWith('mailto:')) {
    props.href = hrefTo
  }
  return <MuiBaseButton {...props}>{children}</MuiBaseButton>
}

// Each variant of "Sizes" must be defined, and require defining specific CSS properties
const variants: Record<Sizes, Required<Pick<React.CSSProperties, ModifyableCss>>> = {
  small: {
    padding: '8px 16px',
    fontSize: '14px',
    lineHeight: '100%',
  },
  medium: {
    padding: '12px 24px',
    fontSize: '14px',
    lineHeight: '100%',
  },
  'medium-large': {
    padding: '10px 16px',
    fontSize: '16px',
    lineHeight: '110%',
  },
  large: {
    padding: '20px 40px',
    fontSize: '16px',
    lineHeight: '110%',
  },
}

const primaryButtonStyle = ({theme, size}: {theme: Theme; size: Sizes}) => `
  background-color: ${colors.evergreen[100]};
  border-radius: 100px;
  border: 1px solid ${colors.background[5]};
  box-sizing: border-box;  
  color: ${colors.background[1]};
  cursor: pointer;
  display: inline-block;
  font-family: "Aktiv-Grotesk";
  font-size: ${variants[size].fontSize};
  font-style: normal;
  font-weight: 400;
  line-height: ${variants[size].lineHeight};
  padding: ${variants[size].padding};
  text-align: center;
  
  ${theme.breakpoints.down('sm')} {
    width: 100%;
  }

  &:hover {
    background-color: ${colors.darkEvergreen[100]};
  }

  &.${buttonClasses.active} { }

  &.${buttonClasses.focusVisible} {
    background-color: ${colors.darkEvergreen[100]};
  }

  &.${buttonClasses.disabled} {
    background-color: ${colors.transparent[10]};
    border-color: ${colors.background[4]};
    color: ${colors.darkEvergreen[40]};
    cursor: default;
  }

`

export const PrimaryButton = styled(BaseButton)<{size: Sizes}>(({theme, size}) => primaryButtonStyle({theme, size}))

// export labels so that these can be used to wrap hidden elements, eg files
export const PrimaryButtonLabel = styled('label')<{size: Sizes}>(({theme, size}) => primaryButtonStyle({theme, size}))

export const PrimaryButtonLink = styled('a')<{size: Sizes}>(({theme, size}) => primaryButtonStyle({theme, size}))

const secondaryButtonStyle = ({theme, size}: {theme: Theme; size: Sizes}) => `
  background-color: ${colors.transparent.fully};
  border-radius: 100px;
  border: 1px solid ${colors.background[5]};
  box-sizing: border-box;  
  color: ${colors.evergreen[100]};
  cursor: pointer;
  display: inline-block;
  font-family: "Aktiv-Grotesk";
  font-size: ${variants[size].fontSize};
  font-style: normal;
  font-weight: 400;
  line-height: ${variants[size].lineHeight};
  padding: ${variants[size].padding};
  text-align: center;
  
  ${theme.breakpoints.down('sm')} {
    width: 100%;
  }

  &:hover {
    border-color: ${colors.evergreen[100]};
  }

  &.${buttonClasses.active} {
    background-color: ${colors.background[4]};
    border-color: ${colors.background[4]};
    color: ${colors.darkEvergreen[100]};
  }

  &.${buttonClasses.focusVisible} {
    border-color: ${colors.evergreen[100]};
  }

  &.${buttonClasses.disabled} {
    background-color: ${colors.transparent[10]};
    border-color: ${colors.gray[5]};
    color: ${colors.darkEvergreen[40]};
    cursor: default;
  }

  &:visited {
    color: ${colors.evergreen[100]};
  }
`

export const SecondaryButton = styled(BaseButton)<{size: Sizes}>(({theme, size}) => secondaryButtonStyle({theme, size}))

export const SecondaryLabelButton = styled('label')<{size: Sizes}>(({theme, size}) =>
  secondaryButtonStyle({theme, size}),
)

export const SecondaryButtonLink = styled('a')<{size: Sizes}>(({theme, size}) => secondaryButtonStyle({theme, size}))
