import {Box, Modal} from '@mui/material'
import {SxProps, styled} from '@mui/system'
import * as React from 'react'
import colors from '../colors'
import {NavigateNext} from '../ui/icons'
import {useProfileContext} from './Profile.Contexts'
import TagSelector from './Profile.TagSelector'
import CherubTooltip from './Profile.Tooltip'
import {TagProfileField} from './common'

const style: Record<string, SxProps> = {
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 325,
    height: 600,
    bgcolor: colors.background['warm white'],
    boxShadow: 4,
    borderRadius: 4,
    padding: '21.5px 25px',
  },
}

const DivElement = styled('div')(
  ({theme}) => `
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.375rem;
`,
)

const LabelElement = styled('label')(
  ({theme}) => `
  color: ${colors.darkEvergreen[60]};

  /* Body/Body 3 Medium */
  font-family: Aktiv-Grotesk;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 0.975rem */
`,
)

const DescriptionElement = styled('div')(
  ({theme}) => `
  color: ${colors.gray[1]}

  font-family: "Aktiv-Grotesk";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 1.1375rem */
`,
)

const TagDisplay = styled('div')(
  ({theme}) => `
  border-radius: 8px;
  border: 1px solid ${colors.gray[4]};
  background: ${colors.background.white};
  cursor: pointer;

  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
`,
)

const DisplayedTags = styled('div')(
  ({theme}) => `
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 6px;
  flex: 1 0 0;
  flex-wrap: wrap;
`,
)

const TagChip = styled('div')(
  ({theme}) => `
  border-radius: 20px;
  background: ${colors.gray[5]};

  align-items: center;
  padding: 6px 10px;

  color: ${colors.background.black};
  text-align: center;
  font-family: "Aktiv-Grotesk";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
`,
)

type ProfileInputProps = TagProfileField & {
  'aria-label': string // makes this required
  value?: string[]
}

// const TagInput = React.forwardRef(function CustomInput(
//   props: React.InputHTMLAttributes<HTMLInputElement> & ProfileInputProps,
//   ref: React.ForwardedRef<HTMLInputElement>,
// ) {
function TagInput(props: ProfileInputProps): React.JSX.Element {
  const {dispatch} = useProfileContext()
  const [showPicker, setShowPicker] = React.useState(false)

  return (
    <DivElement>
      <LabelElement>
        {props['aria-label']} {`(${props.value?.length})`}
        {props.tooltip && <CherubTooltip description={props.tooltip} />}
      </LabelElement>
      {props.description && <DescriptionElement>{props.description}</DescriptionElement>}
      {/* TODO: need to figure out how to a11y this */}
      <TagDisplay onClick={() => setShowPicker(true)}>
        <DisplayedTags>{props.value?.map(tag => <TagChip key={tag}>{tag}</TagChip>)}</DisplayedTags>
        <div style={{flexShrink: 0}}>
          <NavigateNext />
        </div>
      </TagDisplay>
      {showPicker && (
        <Modal
          open={showPicker}
          onClose={() => setShowPicker(false)}
          aria-labelledby="modal-title"
          aria-describedby="modal-description">
          <Box sx={style.modal}>
            <TagSelector
              title={props.extra.modalPrompt}
              categories={props.categories}
              description={props.extra.modalDescription || props.description}
              maxItems={props.extra.maxItems}
              tags={props.value || []}
              filterTODO={''}
              showSuggestTODO={false}
              onClose={() => setShowPicker(false)}
              setTags={tags => {
                setShowPicker(false)
                dispatch({type: 'set_tags', field: props, value: tags})
              }}
            />
          </Box>
        </Modal>
      )}
    </DivElement>
  )
}

export default TagInput
