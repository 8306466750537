import {FetchResult} from '@apollo/client'
import styled from '@emotion/styled'
import {Alert, CircularProgress, Grid, LinearProgress, Typography, linearProgressClasses} from '@mui/material'
import {Box, Stack} from '@mui/system'
import * as Sentry from '@sentry/react'
import {EmbeddedCheckout, EmbeddedCheckoutProvider} from '@stripe/react-stripe-js'
import {Stripe, loadStripe} from '@stripe/stripe-js'
import _ from 'lodash'
import {DateTime} from 'luxon'
import React, {useEffect, useState} from 'react'
import {Navigate, useLoaderData, useLocation, useNavigate, useOutlet} from 'react-router-dom'
import {SaveOnboardingMutation, useCheckOnboardingWorkflowStatusMutation, useOnboardingMeQuery, useSaveOnboardingMutation} from './api/types'
import {RequiresAuthentication} from './auth/RequiresAuthentication'
import {useAuthRedirects} from './auth/useAuthRedirects'
import {LoginInput, LoginInputWrapper, LoginLabel} from './Authenticate'
import colors from './colors'
import config from './config'
import Header from './Header'
import {ProfileField, commonFields} from './profile/common'
import {ProfileDataType} from './profile/Profile.Contexts'
import CherubRadioGroup from './profile/Profile.RadioGroup'
import {Option, Select} from './profile/Profile.Select'
import TagSelector from './profile/Profile.TagSelector'
import {PrimaryButton} from './ui/Buttons'
import {CancelDark, Done, LeftArrow} from './ui/icons'

function hasErrors(result: FetchResult<SaveOnboardingMutation>): Error | null {
  const errors = []
  if (result.errors?.length) {
    errors.push(...result.errors)
  }

  if (result.data?.onboardingWorkflow?.errors?.length) {
    errors.push(...result.data.onboardingWorkflow.errors)
  }

  if (errors.length > 0) {
    return new Error(errors.join('; '))
  }

  return null
}

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const loader = async() => {
  const stripe = await loadStripe(config.stripe.pk, {})
  return {stripe} as {stripe: Stripe}
}

export const route = {
  path: '/onboarding',
  // As the stripe state changes, we want to avoid cached self-metadata (me query)
  element: <RequiresAuthentication><Onboarding /></RequiresAuthentication>,
  children: [
    {
      index: true, // TODO: why doesn't this work?
      path: 'tell-us-about-yourself',
      element: <RequiresAuthentication><TellUsAboutYourself /></RequiresAuthentication>,
      profileType: 'both',
    },
    {
      path: 'select-industries',
      element: <RequiresAuthentication><Industries /></RequiresAuthentication>,
      profileType: 'both',
    },
    {
      path: 'fundraising-goals',
      element: <RequiresAuthentication><FundraisingGoals /></RequiresAuthentication>,
      profileType: 'founder',
    },
    {
      path: 'investment-appetite',
      element: <RequiresAuthentication><InvestmentAppetite /></RequiresAuthentication>,
      profileType: 'investor',
    },
    {
      path: 'accreditation',
      element: <RequiresAuthentication><AccreditedSelect /></RequiresAuthentication>,
      profileType: 'investor',
    },
    {
      path: 'accredited-only',
      element: <RequiresAuthentication><AccreditedOnly /></RequiresAuthentication>,
      profileType: 'investor',
    },
    {
      path: 'pick-subscription',
      element: <RequiresAuthentication><PickSubscription /></RequiresAuthentication>,
      profileType: 'founder',
    },
    {
      path: 'pick-frequency',
      element: <RequiresAuthentication><SubscriptionFrequency /></RequiresAuthentication>,
    },
    {
      path: 'agreement',
      element: <RequiresAuthentication><Agreement /></RequiresAuthentication>,
      profileType: 'both',
    },
    {
      path: 'collect-payment',
      element: <RequiresAuthentication><CollectPayment /></RequiresAuthentication>,
      loader,
      profileType: 'founder',
    },
    {
      path: 'payment-finished',
      element: <RequiresAuthentication><PaymentFinished /></RequiresAuthentication>,
    },
    {
      path: 'verify-identity',
      element: <RequiresAuthentication><VerifyIdentity /></RequiresAuthentication>,
      loader,
      profileType: 'both',
    },
    {
      path: 'verification-finished',
      element: <RequiresAuthentication><VerificationFinished /></RequiresAuthentication>,
      profileType: 'both',
    },
  ],
}

const BorderLinearProgress = styled(LinearProgress)(
  ({ theme }) => `
  height: 0.25rem;
  border-radius: 0.25rem;

  &.${linearProgressClasses.colorPrimary} {
    background-color: ${colors.darkEvergreen[20]};
  }
  & .${linearProgressClasses.bar} {
    border-radius: 0.25rem;
    background-color: ${colors.evergreen[100]};
  }
`,
)

function Divider() {
  return (
    <Box sx={{height:'1px', bgcolor: colors.background[5], width: '100%' }}/>
  )
}

function TellUsAboutYourself() {
  const [profile, setProfile] = React.useState<Partial<ProfileDataType>>({})
  const [checkStatus, {data: status, loading, called, error}] = useCheckOnboardingWorkflowStatusMutation({
    onCompleted: (data) => {
      setProfile(data.onboardingWorkflow?.data ?? {})
    },
  })

  useEffect(() => {
    checkStatus({ variables: { stepName: 'newly-created' } })
  }, [checkStatus])

  const [saveBasicInfo] = useSaveOnboardingMutation()
  const [saveError, setSaveError] = React.useState<Error | null>(null)

  const navigate = useNavigate()

  if (loading || !called) {
    return (
      <Box>
        <CircularProgress />
      </Box>
    )
  }

  if (error || status?.onboardingWorkflow?.errors?.length || status?.onboardingWorkflow == null) {
    const message = error?.message ?? status?.onboardingWorkflow?.errors?.join('; ') ?? 'Failed to load your onboarding status'
    return (
      <Box>
        <Alert action="error">{message}</Alert>
      </Box>
    )
  }

  const isInvestor = status.onboardingWorkflow.profileType === 'investor'

  const fields: Array<React.FC<{}> | ProfileField> = isInvestor ? [
    commonFields.investorFirstName,
    commonFields.investorLastName,
    Divider,
    commonFields.investorHeadline,
  ] : [
    commonFields.contactFirstName,
    commonFields.contactLastName,
    Divider,
    commonFields.companyName,
    commonFields.contactJobRole,
  ]

  const canSubmit = _.every(_.map(fields, field =>
    typeof field === 'function' || _.get(profile, field.property),
  ))

  return (
    <Stack spacing={5}>
      {saveError && <Alert action="error">{saveError.message}</Alert>}
      <Box typography="h4">Tell us about yourself</Box>
      <Divider />
      <Box typography="body1" color={colors.darkEvergreen[75]}>{_.capitalize(status.onboardingWorkflow.profileType)}</Box>
      {fields.map((field, i) => {
        if (typeof field === 'function') {
           const Field = field as React.FC
          return <Field key={i} />
        }

        return (
          <LoginInputWrapper key={i} sx={{bgcolor: colors.background.white}}>
            <LoginLabel htmlFor={field.property}>{field.label}</LoginLabel>
            <LoginInput
              id={field.property}
              type="text"
              value={_.get(profile, field.property) ?? ''}
              placeholder={field.placeholder || 'Name'}
              onChange={e => {
                setProfile(_.set(_.cloneDeep(profile), field.property, e.target.value))
              }}
            />
          </LoginInputWrapper>
        )
      })}
      <PrimaryButton disabled={!canSubmit} size="large" onClick={() => {
        if (isInvestor) {
          saveBasicInfo({
            variables: {
              stepName: 'newly-created',
              data: profile,
            },
          }).then((response) => {
            const error = hasErrors(response)
            if (error) {
              return setSaveError(error)
            }

            navigate('/onboarding/select-industries')
          })
        } else {
          saveBasicInfo({
            variables: {
              stepName: 'newly-created',
              data: profile,
            },
          }).then((response) => {
            const error = hasErrors(response)
            if (error) {
              return setSaveError(error)
            }

            navigate('/onboarding/select-industries')
          })
        }
        
      }}>Next</PrimaryButton>
    </Stack>
  )
}

const FilledDone = styled(Done)(
  ({theme}) => `
  background: ${colors.evergreen[100]};
  border-radius: 4rem;
  color: ${colors.background.white};

  height: 1.125rem;
  width: 1.125rem;
`,
)

const SuccessDone = styled(Done)(
  ({theme}) => `
  background: ${colors.gray[1]};
  border-radius: 4rem;
  color: ${colors.background.white};

  height: 6rem;
  width: 6rem;
`,
)

const Choice = styled('label')<{ selected: boolean }>(
  ({theme, selected}) => `
  border-radius: 1.25rem;
  border: 1px solid ${selected ? colors.darkEvergreen[100] : colors.background[5]};
  background: ${colors.background.white};

  box-sizing: border-box;
  display: flex;
  padding: 1.25rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  width: 100%;
`,
)

const ChoiceText = styled('div')(
  ({theme}) => `
  flex: 1 0 0;
`,
)

const ChoiceRadio = styled('input')(
  ({theme}) => `
  accent-color: ${colors.darkEvergreen[100]};
  transform: scale(1.25);
`,
)

type choices = 'cherub_select_annual_v1' | 'cherub_select_quarterly_v1'

function PickSubscription() {
  const [checkStatus, { data, loading, called, error }] = useCheckOnboardingWorkflowStatusMutation()
  const [saveSubscriptionChoice] = useSaveOnboardingMutation()
  const [saveError, setSaveError] = React.useState<Error | null>(null)
  const navigate = useNavigate()

  const basicContent = [
    'Build a beautiful data room for your fundraise',
    'Accept intro requests from strategic angel investors',
    'Find & follow identify verified strategic angel investors and VCs',
    'Fine tune your pitch with data-driven insights ',
    'Access fundraising content & education',
  ]
  const raiseContent = [
    'Everything in Cherub Basic, plus:  ',
    'Request intros to strategic angel investors',
    'See who’s viewed and clicked your profile links',
    'Filter the directory to find the best investors for you',    
    'Access a private community of peers and experts for feedback',
  ]

  useEffect(() => {
    checkStatus({ variables: { stepName: 'choose-plan' } })
  }, [checkStatus])

  useEffect(() => {
    if (loading || !called || error || data?.onboardingWorkflow?.data?.paymentStatus !== 'paid') {
      return
    }

    // If we are already paid, we can skip this step, but the backend needs to re-verify to go to the next step,
    // so we hit the mutation to it processes into the next step
    saveSubscriptionChoice({
      variables: {stepName: 'choose-plan', data: {}},
    }).then((response) => {
      const error = hasErrors(response)
      if (error) {
        console.error(error)
        return
      }

      navigate('/onboarding/agreement')
    })
  }, [loading, called, data, error, navigate, saveSubscriptionChoice])

  if (loading || !called || error || data?.onboardingWorkflow?.data?.paymentStatus === 'paid') {
    return <Box>
      {error && <Alert action="error">Error: {error.message}</Alert>}
      <CircularProgress />
    </Box>
  }

  return (
    <Stack>
    <Stack spacing={5} sx={{border: '1px', borderRadius: '20px', marginBottom: '20px'}}>
      {saveError && <Alert action="error">Error: {saveError.message}</Alert>}
      <Box typography="h4">Basic</Box>
      <Box typography="body2">Best for founders who aren’t actively fundraising</Box>
      
      *Free / One time verification fee $3
      <PrimaryButton size="large" onClick={() => {
        saveSubscriptionChoice({
          variables: {
            stepName: 'choose-plan',
            data: {plan: 'cherub_basic_v1'},
          },
        }).then((response) => {
          const error = hasErrors(response)
          if (error) {
            setSaveError(error)
            return
          }
          
          navigate('/onboarding/agreement')
        })
      }}>Get Basic</PrimaryButton>

      <Grid container spacing={3}>
        {basicContent.map((item, i) => (
          <Grid key={i} container item width="100%" spacing={2}>
            <Grid item flex="0 0 auto">
              <FilledDone />
            </Grid>
            <Grid item typography="body2" flex="1 0 0" color={colors.darkEvergreen[100]}>
              {item}
            </Grid>
          </Grid>
        ))}
      </Grid>     
    </Stack>
    <Stack spacing={5}>
      <Box typography="h4">Raise</Box>
      <Box typography="body2">Best for founders who are actively fundraising</Box>
      $270 Quarterly / $950 Annually (save 18%)
      <PrimaryButton size="large" onClick={() => {
        navigate('/onboarding/pick-frequency')        
      }}>Try for free</PrimaryButton>

      <Grid container spacing={3}>
        {raiseContent.map((item, i) => (
          <Grid key={i} container item width="100%" spacing={2}>
            <Grid item flex="0 0 auto">
              <FilledDone />
            </Grid>
            <Grid item typography="body2" flex="1 0 0" color={colors.darkEvergreen[100]}>
              {item}
            </Grid>
          </Grid>
        ))}
      </Grid>      
      <Box typography="body3" color={colors.darkEvergreen[75]}>
        Cancel anytime during trial period
      </Box>
    </Stack>
    </Stack>
  )
}

function SubscriptionFrequency() {
  const navigate = useNavigate()

  const [checkStatus, { loading, called }] = useCheckOnboardingWorkflowStatusMutation()
  const [saveSubscriptionChoice] = useSaveOnboardingMutation()

  const [error, setError] = React.useState<Error | null>(null)
  const [subscription, setSubscription] = React.useState<choices>('cherub_select_annual_v1')

  const frequencyContent = [
    '14-day free trial',
    'Request intros from verified strategic angel investors and VCs',
    'Build a beautiful data room for your fundraise',
    'See who’s viewed and clicked your profile links',    
    'Access a private community of peers and experts for feedback',
  ]

  useEffect(() => {
    checkStatus({ variables: { stepName: 'choose-plan' } })
      .then((response) => {
        if (hasErrors(response)) {
          return setError(hasErrors(response))
        }

        if (response.data?.onboardingWorkflow?.data?.paymentStatus === 'paid') {
          // if you've already paid, it won't let you go to step 4 as it bounces you back here immediately, same with collect payment pushing you to verify
          return navigate('/onboarding/agreement')
        }

        if (response.data?.onboardingWorkflow?.data?.plan) {
          setSubscription(response.data.onboardingWorkflow.data.plan as choices)
        }
      })
  }, [checkStatus, setError, navigate, setSubscription])
  
  if (loading || !called) {
    return <Box><CircularProgress /></Box>
  }

  return (
    <Stack spacing={5}>
      {error && <Alert action="error">Error: {error.message}</Alert>}
      <Box typography="h4">Ready to start your fundraising journey?</Box>
      <Divider />
      <Grid container spacing={3}>
        {frequencyContent.map((item, i) => (
          <Grid key={i} container item width="100%" spacing={2}>
            <Grid item flex="0 0 auto">
              <FilledDone />
            </Grid>
            <Grid item typography="body2" flex="1 0 0" color={colors.darkEvergreen[100]}>
              {item}
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Box>
        <Choice selected={subscription === 'cherub_select_quarterly_v1'}>
          <ChoiceText>
            <Box typography="body4" color={colors.darkEvergreen[100]} marginBottom={2}>Free for 14 days</Box>
            <Box typography="body1" color={colors.darkEvergreen[100]}>Then $270 Quarterly</Box>
          </ChoiceText>
          <ChoiceRadio
            type="radio"
            name="subscription"
            onChange={() => setSubscription('cherub_select_quarterly_v1')}
            checked={subscription === 'cherub_select_quarterly_v1'}
          />
        </Choice>
      </Box>
      <Box>        
        <Choice selected={subscription === 'cherub_select_annual_v1'}>
          <ChoiceText>
            <Box typography="body4" color={colors.darkEvergreen[100]} marginBottom={2}>Free for 14 days</Box>
            <Box typography="body1" color={colors.darkEvergreen[100]}>$950 Annually</Box>
          </ChoiceText>
          <ChoiceRadio
            type="radio"
            name="subscription"
            onChange={() => setSubscription('cherub_select_annual_v1')}
            checked={subscription === 'cherub_select_annual_v1'}
          />
        </Choice>
      </Box>
      
      <Box typography="body3" color={colors.darkEvergreen[75]}>
        Cancel anytime during trial period
      </Box>
      <Divider />
      <PrimaryButton size="large" onClick={() => {
        saveSubscriptionChoice({
          variables: {
            stepName: 'choose-plan',
            data: {
              plan: subscription,
            },
          },
        }).then((response) => {
          const errors = hasErrors(response)
          if (errors) {
            console.error(errors)
            return setError(errors)
          }

          navigate('/onboarding/agreement')
        })
      }}>
        Next
      </PrimaryButton>
    </Stack>
  )
}

function CollectPayment() {
  const {stripe} = useLoaderData() as { stripe: Stripe }
  const [checkStatus, { data, loading, called, error }] = useCheckOnboardingWorkflowStatusMutation()
  const [initiatePayment, { data: paymentData, loading: initializing, called: invoked, error: initError }] = useSaveOnboardingMutation()
  const navigate = useNavigate()

  useEffect(() => {
    checkStatus({ variables: { stepName: 'collect-payment' } })
  }, [checkStatus])

  useEffect(() => {
    if (loading || !called || !data?.onboardingWorkflow?.data) {
      return
    }

    initiatePayment({
      variables: {
        stepName: 'collect-payment',
        data: {},
      },
    }).then(({ data, errors }) => {
      if (errors) {
        // this is a non business logic error, we can't know how to proceed
        throw new Error(errors.join('; '))
      }

      if (!data?.onboardingWorkflow?.data) {
        throw new Error('Payment session failed to created')
      }

      // we may get back a payment error since it is pending,
      // but if we get back data then we can proceed to the checkout session
      if (!data.onboardingWorkflow.data.clientSecret) {
        if (data.onboardingWorkflow.data.paymentStatus === 'paid') {
          // if a person has already paid, it will advance their onboarding state, so we go to the next step
          return navigate('/onboarding/verify-identity')
        }

        // if we get no secret and have not paid, we need to show an error
        if (data.onboardingWorkflow.errors) {
          throw new Error(data.onboardingWorkflow.errors.join(', '))
        }

        throw new Error('Payment session failed to return token')
      }
    })
  }, [loading, called, data, navigate, initiatePayment])

  if (loading || !called || initializing || !invoked) {
    return <Box><CircularProgress /></Box>
  }

  if (error) {
    return (
      <Alert action="error">Error: {error.message}</Alert>
    )
  }

  if (initError || !paymentData?.onboardingWorkflow?.data) {
    return (
      <Alert action="error">{initError?.message || 'Could not initiate payment process, please contact support for assistance'}</Alert>
    )
  }

  // Sometimes people refresh, so we need to check if the payment was already successful
  // for now, try to not handle the case of a failed payment
  
  if (paymentData.onboardingWorkflow.data.clientSecret == null) {
    switch (paymentData.onboardingWorkflow.data.paymentStatus) {
      case 'paid':
        return (
          <>
            <Alert action="success">Payment succeeded -- redirecting</Alert>
            <Navigate to="/onboarding/payment-finished" />
          </>
        )
      default:
        return (
          <Alert action="error">Payment status is invalid, please contact support for assistance. Status is: {paymentData?.onboardingWorkflow?.data.paymentStatus}</Alert>
        )
    }
  }

  return (
    <EmbeddedCheckoutProvider stripe={stripe} options={{
      clientSecret: paymentData.onboardingWorkflow.data.clientSecret,
      // TODO: customization: https://docs.stripe.com/elements/appearance-api?platform=web
      onComplete: () => {
        navigate('/onboarding/payment-finished')
      },
    }}>
      <EmbeddedCheckout />
    </EmbeddedCheckoutProvider>
  )
}

function PaymentFinished() {
  const navigate = useNavigate()
  const location = useLocation()

  const [checkPaymentStatus, {data, loading, called}] = useCheckOnboardingWorkflowStatusMutation()
  const [savePaymentSession, {data: onboardingData, loading: onboardingLoading, called: onboardingCalled }] = useSaveOnboardingMutation()
  const [error, setError] = React.useState<Error | null>(null)

  const params = new URLSearchParams(location.search)
  const sessionId = params.get('session_id')

  useEffect(() => {
    if (sessionId) {
      savePaymentSession({
        variables: {
          stepName: 'collect-payment',
          data: {sessionId},
        },
      }).then((response) => {
        if (hasErrors(response)) {
          return setError(hasErrors(response))
        }
      })
    } else {
      checkPaymentStatus({
        variables: {
          stepName: 'collect-payment',
        },
      }).then((response) => {
        if (hasErrors(response)) {
          setError(hasErrors(response))
        }
      })
    }
  }, [checkPaymentStatus, savePaymentSession, sessionId])

  if (loading || onboardingLoading || (!called && !onboardingCalled)) {
   return <CircularProgress /> 
  }

  if (error) {
    return (
      <Alert action="error">Error checking payment status: {error.message}</Alert>
    )
  }

  const status = data?.onboardingWorkflow?.data?.paymentStatus ?? onboardingData?.onboardingWorkflow?.data?.paymentStatus
  if (status !== 'paid') {
    debugger
    return (
      <Alert action="error">Error: payment did not succeed</Alert>
    )
  }
  
  const selectedPlan = onboardingData?.onboardingWorkflow?.data?.plan 
  const billDate = (selectedPlan === 'cherub_select_annual_v1' || selectedPlan === 'cherub_select_quarterly_v1')
    ? DateTime.now().plus({ days: 14 }).toLocaleString(DateTime.DATE_MED)
    : null

  const title = (selectedPlan === 'cherub_select_annual_v1' || selectedPlan === 'cherub_select_quarterly_v1')
    ? "We've added your payment method!"
    : 'Your $3 verification fee was successfully charged'
  // return (
  //   <Stack spacing={4} alignContent="center">
  //     {onboardingData?.onboardingWorkflow?.data?.paymentStatus === 'paid'}
  //     <Box typography="h4">{title}</Box>
  //     {billDate && <Box typography="body1">You will be billed on {billDate}</Box>}
  //     <PrimaryButton size="large" onClick={() => navigate('/onboarding/verify-identity')}>Next</PrimaryButton>
  //   </Stack>
  // )
  return (
    <Stack spacing={4} alignContent="center">
      {onboardingData?.onboardingWorkflow?.data?.paymentStatus === 'paid'}
      <Box typography="h4">Your payment method was successfully captured.</Box>
      <Box typography="body1">If you're on a trial, you will be billed at the end of it.</Box>
      <PrimaryButton size="large" onClick={() => navigate('/onboarding/verify-identity')}>Next</PrimaryButton>
    </Stack>
  )
}

function AccreditedSelect() {
  const choices = {
    yes: 'Yes, I am accredited',
    no: 'No, I am not accredited',
  }

  const [terms, setTerms] = React.useState([])
  const [getAccredidationTerms, {loading, called, error}] = useCheckOnboardingWorkflowStatusMutation()
  const [saveAccredidationResponse] = useSaveOnboardingMutation()
  const [saveError, setSaveError] = React.useState<Error | null>(null)

  useEffect(() => {
    getAccredidationTerms({variables: { stepName: 'accreditation-survey' }}).then(({data}) => {
      setTerms(data?.onboardingWorkflow?.data?.accreditations)
    })
  }, [getAccredidationTerms, setTerms])

  const Checkbox = ({idx, item, terms, value, onChange}: any) => {
    const clickAction = () => {
      terms[idx].applies = !value
      onChange(Object.assign([], terms))
    }
    return (
      <Grid container item width="100%" spacing={2}>
        <Grid item flex="0 0 auto">
          {/* <Checkbox label={item} value={false} onChange={()=>{}}/> */}
          <input type="checkbox" checked={value} onChange={clickAction} />
        </Grid>
        <Grid item typography="body2" flex="1 0 0" onClick={clickAction} color={colors.darkEvergreen[100]}>
          {item.text}
        </Grid>
      </Grid>
    )
  }

  const [answer, setAnswer] = React.useState('')
  const [isAccredited, setIsAccredited] = React.useState(false)
  const navigate = useNavigate()

  const canSubmit = _.some(terms, ['applies', true]) || answer === 'no'

  if (loading || !called || error) {
    return <Box>
      {error && <Alert action="error">{error.message}</Alert>}
      <CircularProgress />
    </Box>
  }

  return (
    <Stack spacing={5}>
      {saveError && <Alert action="error">{saveError.message}</Alert>}
      <Box typography="h4">Are you an accredited angel investor?</Box>

      <Box typography="body2">
        You must be an accredited angel investor per the SEC's standards in order to join Cherub.{' '}
        <a
          href="https://www.sec.gov/resources-small-businesses/exempt-offerings/frequently-asked-questions-about-exempt-offerings?auHash=rh5WfJi9h3wRzP6X2anOmgYLdhPHNuo-3Vw0YNZyR_M#faq2"
          target="_blank"
          rel="noreferrer">
          Learn more
        </a>
      </Box>

      <Divider />
      <CherubRadioGroup
        choices={choices}
        defaultValue={answer}
        onChange={v => {
          setAnswer(v.target.value)
          setIsAccredited(v.target.value === 'yes')
        }}
      />

      {isAccredited && (
        <>
          <Typography variant="body2">Please confirm which of the following apply to you:</Typography>
          {terms.map((item: any, i) => (
            <Checkbox key={i} idx={i} item={item} value={item.applies} terms={terms} onChange={setTerms} />
          ))}
        </>
      )}
      <PrimaryButton
        disabled={!canSubmit}
        size="large"
        onClick={() => {
          if (!isAccredited) {
            return navigate('/onboarding/accredited-only')
          }

          saveAccredidationResponse({
            variables: {
              stepName: 'accreditation-survey',
              data: {
                accreditations: terms,
              },
            },
          }).then((response) => {
            if (hasErrors(response)) {
              return setSaveError(hasErrors(response))
            }

            navigate('/onboarding/agreement')
          })
        }}>
        Next
      </PrimaryButton>
    </Stack>
  )
}

function AccreditedOnly() {
  return (
    <Stack spacing={4} direction='column' justifyContent='center' alignItems="center">      
      <Typography variant='h4' align='center'>Sorry, Cherub is open to accredited angel investors only.</Typography>
      <Typography variant='body2' align='center' sx={{marginBottom: '200px !important', marginTop:'30px !important'}} >If your accreditation status changes, please apply again or sign up for our email list.</Typography>
      
      <PrimaryButton sx={{color: 'white', textDecoration: 'none', width: '100%'}} size="large" 
      
      >
        <a style={{'color': 'white','display': 'block','textDecoration': 'none'}} href='https://intercom.help/cherub/en/articles/10003031-why-do-i-need-to-be-an-accredited-investor' target='_blank' rel="noreferrer">Learn more</a>
      </PrimaryButton>
      <a href='https://www.investwithcherub.com' >Close</a>
    </Stack>
  )
}

function VerifyIdentity() {
  const {stripe} = useLoaderData() as { stripe: Stripe }
  const navigate = useNavigate()

  const [unknownError, setUnknownError] = useState<Error | null>(null)
  const [checkVerificationStatus, {data: status, loading, called, error}] = useCheckOnboardingWorkflowStatusMutation()
  const [initiateVerification, { loading: initializing, error: initError }] = useSaveOnboardingMutation()

  useEffect(() => {
    checkVerificationStatus({ variables: { stepName: 'verify-identity' } })
  }, [checkVerificationStatus])

  useEffect(() => {
    if (loading || !called || !status?.onboardingWorkflow?.data) {
      return
    }

    if (status.onboardingWorkflow.data.verificationStatus === 'verified') {
      // if a person has already verified, it will advance their onboarding state, so we go to the next step
      initiateVerification({
        variables: {
          stepName: 'verify-identity',
          data: {},
        },
      }).then((response) => {
        if (hasErrors(response)) {
          return setUnknownError(hasErrors(response))
        }

        navigate('/onboarding/verification-finished')
      })
    }
  }, [loading, called, status, navigate, initiateVerification])
  
  if (!stripe || loading || !called) {
    return <CircularProgress />
  }

  if (status?.onboardingWorkflow?.data.verificationStatus === 'verified' && initializing) {
    return (
      <Box>
        {unknownError && <Alert action="error">{unknownError.message}</Alert>}
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Stack spacing={5}>
      {error && <Alert action="error">{error.toString()}</Alert>}
      {initError && <Alert action="error">{initError.toString()}</Alert>}
      {unknownError && <Alert action="error">{unknownError.toString()}</Alert>}
      <Box typography="h4" color={colors.darkEvergreen[100]}>Verify your identity</Box>
      <Box typography="body1" color={colors.darkEvergreen[100]}>Get ready to take a photo of your ID and a selfie.</Box>
      <Box typography="body1" color={colors.darkEvergreen[100]}>We only allow verified people to view data rooms to ensure security of our platform. We never use your ID for anything outside of this verification.</Box>
      <PrimaryButton size="large" onClick={() => {
        initiateVerification({
          variables: {
            stepName: 'verify-identity',
            data: {},
          },
        })
          .then(async ({data, errors}) => {            
            if (errors) {
              // this is a non business logic error, we can't know how to proceed
              throw new Error(errors.join('; '))
            }
            if (!data?.onboardingWorkflow?.data) {
              throw new Error('No data returned')
            }
            
            // we may get back a validation error since the verification is pending,
            // but if we get back data then we can proceed to retry verification first
            if (!data.onboardingWorkflow.data.clientSecret) {
              if (data.onboardingWorkflow.data.status === 'verified') {
                navigate('/onboarding/verification-finished')
                return
              }

              // if we get no secret and have not verified, we need to show an error
              if (data.onboardingWorkflow.errors) {
                throw new Error(data.onboardingWorkflow.errors.join(', '))
              }

              throw new Error('Verification session ID missing')
            }

            const reply = await stripe.verifyIdentity(data.onboardingWorkflow.data.clientSecret)
            if (reply.error) {
              if (reply.error.code === 'session_cancelled') {
                throw new Error('Verification session was cancelled, please refresh and try again')
              }
              if (reply.error.message) {
                throw new Error(reply.error.message)
              }

              Sentry.captureException(reply.error)
              throw new Error('An unknown error occurred when trying to verify your identity, please contact support')
            }

            navigate('/onboarding/verification-finished')
          })
          .catch((error) => {
            setUnknownError(error)
          })
      }}>Accept and continue</PrimaryButton>
      <Divider />
      <Stack spacing={2}>
        <Box typography="body3" color={colors.darkEvergreen[100]}>Cherub partners with Stripe for secure identity verification</Box>
        <Box typography="body4" color={colors.darkEvergreen[75]}>Stripe will use biometric technology (on images of you and your IDs), as well as other data sources and our service providers (such as document issuer or authorized record holder), to confirm your identity, and for fraud and security purposes. You can subsequently opt-out by contacting Stripe.</Box>
      </Stack>
    </Stack>
  )
}

function VerificationFinished() {
  const {resumeAfterOnboarding} = useAuthRedirects()
  const navigate = useNavigate()
  const { data, loading, error } = useOnboardingMeQuery()

  // save rather than check, as we may have already verified and need to advance the onboarding state backend
  const [saveOnboardingProfile, {data: onboardingStatus, loading: loadingStatus, error: errorStatus }] = useSaveOnboardingMutation()
  
  useEffect(() => {
    saveOnboardingProfile({
      variables: {
        stepName: 'verify-identity',
        data: {},
      },
    })
  }, [saveOnboardingProfile])
  

  const errorScreen = (
    <Stack spacing={4} direction='column' justifyContent='center' alignItems="center">
    <Box  sx={{marginTop: '100px', marginBottom: '30px !important'}}>
      <CancelDark />
    </Box>
    <Typography variant='h4' align='center'>Uh oh, we’ve encountered an issue. Your verification is incomplete.</Typography>
    <Typography variant='h4' align='center' sx={{marginBottom: '200px !important', marginTop:'30px !important'}} >Get in touch with Cherub to finish signing up.</Typography>
    
    <PrimaryButton sx={{textDecoration: 'none', width: '100%'}} size="large" 
    href={'mailto:membership@investwithcherub.com?subject=ID Verification Issue'}
    >
      Contact us
    </PrimaryButton>
  </Stack>
  )

  if (error || errorStatus) {
    return errorScreen
  }

  if (loading || loadingStatus || !data || !onboardingStatus?.onboardingWorkflow?.data) {
    return <CircularProgress />
  }

  if (onboardingStatus?.onboardingWorkflow?.data?.status !== 'verified') {
    return errorScreen
  }

  return (
    <Stack spacing={4} direction='column' alignItems="center">
      <Box  sx={{marginTop: '100px', marginBottom: '30px !important'}}>
      <SuccessDone />
      </Box>
   
      
      <Box typography="h4">Your identity has</Box>
      <Box sx={{marginBottom: '200px !important', marginTop:'0 !important'}} typography="h4">been verified!</Box>
      
      <PrimaryButton sx={{width: '100%'}} size="large" onClick={() => {
        if (resumeAfterOnboarding() !== 'redirected') {
          navigate(`/profile/${data.me.orgId}/edit`)
        }
      }}>
        Get started
      </PrimaryButton>
    </Stack>
  )

}


function Industries() {
  const [checkStatus, { data, loading, called, error }] = useCheckOnboardingWorkflowStatusMutation()
  const [saveProfileTags, { error: saveError }] = useSaveOnboardingMutation()
  const [validationError, setValidationError] = React.useState<Error | null>(null)

  const navigate = useNavigate()

  useEffect(() => {
    checkStatus({ variables: { stepName: 'set-match-tags' } })
  }, [checkStatus])
     
  const setTags = (tags: string[]) => {    
    if (data?.onboardingWorkflow?.profileType === 'investor') {
      saveProfileTags({
        variables: {
          stepName: 'set-match-tags',
          data: {
            match_targets: tags,
          },
        },
      }).then((response) => {
        const error = hasErrors(response)
        if (error) {
          return setValidationError(error)
        }

        navigate('/onboarding/investment-appetite')
      })
      return
    }

    saveProfileTags({
      variables: {
        stepName: 'set-match-tags',
        data: {
          match_industries: tags,
        },
      },
    }).then((response) => {
      const error = hasErrors(response)
      if (error) {
        return setValidationError(error)
      }

      navigate('/onboarding/fundraising-goals')
    })
  }

  if (loading || !called || error) {
    return <Box>
      {error && <Alert action="error">Error: {error.message}</Alert>}
      <CircularProgress />
    </Box>
  }

  const tagComponentProps = data?.onboardingWorkflow?.profileType === 'investor' ? {
    title: 'Which categories best apply to the types of companies you’re interested in?',
    description: 'Select as many options as relevant to help us find your matches. You can always change this later.',
    maxItems: null,
  } : {
    title: 'Which industries best apply to your company?',
    description: 'Select up to 3 categories to help us find relevant matches. You can always change this later.',
    maxItems: 3,
  }

  return (
    <Stack spacing={5}>
      {/* <Box typography="h4">Which industries best apply to your company?</Box>
      <Box typography="body3" >Select up to 3 categories to help us find relevant matches. You can always change this later.</Box> */}
      {saveError && <Alert action="error">Error: {saveError.message}</Alert>}
      {validationError && <Alert action="error">{validationError.message}</Alert>}
      <TagSelector 
        title={tagComponentProps.title}
        description={tagComponentProps.description}
        categories={['industries']}
        filterTODO=''
        showSuggestTODO={false}
        maxItems={tagComponentProps.maxItems}
        tags = {data?.onboardingWorkflow?.data?.match_industries ?? []}
        setTags={setTags}
      />
    </Stack>
  )
}

function InvestmentAppetite() {  
  const investorChoices = {
    'Angel investing': 'Angel investing',
    'Advising / advisory shares': 'Advising / advisory shares',
    Both: 'Both',
    'Do not match or surface me to anyone': 'Do not match or surface me to anyone',
  }

  const navigate = useNavigate()
  const [checkStatus, { loading, called, error }] = useCheckOnboardingWorkflowStatusMutation()
  const [saveInvestmentAppetite, { error: saveError }] = useSaveOnboardingMutation()
  
  const [openTo, setOpenTo] = React.useState('')
  useEffect(() => {
    checkStatus({ variables: { stepName: 'set-match-preferences' } })
      .then(({data}) => {
        if (data?.onboardingWorkflow?.data?.open_to) {
          setOpenTo(data?.onboardingWorkflow?.data?.open_to)
        }
      })
  }, [checkStatus])

  const choices = investorChoices

  const canSubmit = Boolean(openTo)
  
  if (loading || !called || error) {
    return (
      <Box>
        {error && <Alert action="error">Error: {error.message}</Alert>}
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Stack spacing={5}>
    {saveError && <Alert action="error">Error: {saveError.message}</Alert>}
    <Box typography="h4">What’s your current angel investment appetite?</Box>   
    
    <Box typography="body2" >Help founders understand whether or not you’re the right partner for them.</Box>
    
    <Box typography="body3" >I am open to matching with:</Box>
    <CherubRadioGroup choices={choices} defaultValue={openTo} onChange={v => setOpenTo(v.target.value)} />
    <PrimaryButton disabled={!canSubmit} size="large" onClick={() => {      
      
      saveInvestmentAppetite({
          variables: {
            stepName: 'set-match-preferences',
            data: {            
              open_to: openTo,
            },
          },
        }).then(() =>{          
          navigate('/onboarding/accreditation')          
        })
        
      }}>Next</PrimaryButton>
  </Stack>
  )
}

function FundraisingGoals() {
  const choices = {
    'Match with angel investors': 'Match with angel investors',
    'Match with advisors': 'Match with advisors',
    Both: 'Both',
    'Do not match or surface me to anyone': 'Do not match or surface me to anyone',
  }

  const [checkStatus, { loading, called, error }] = useCheckOnboardingWorkflowStatusMutation()
  const [saveMatchPreferences, { error: saveError }] = useSaveOnboardingMutation()
  const [validationError, setValidationError] = React.useState<Error | null>(null)
 
  const [stage, setStage] = React.useState('' as any)
  const [openTo, setOpenTo] = React.useState('')

  const canSubmit = Boolean(stage && openTo)
  const navigate = useNavigate()
  
  useEffect(() => {
    checkStatus({ variables: { stepName: 'set-match-preferences' } })
      .then(({data}) => {
        if (data?.onboardingWorkflow?.data?.stage) {
          setStage(data?.onboardingWorkflow?.data?.stage)
        }
        if (data?.onboardingWorkflow?.data?.open_to) {
          setOpenTo(data?.onboardingWorkflow?.data?.open_to)
        }
      })
  }, [checkStatus])

  if (loading || !called) {
    return (
      <Box>
        <CircularProgress />
      </Box>
    )
  }

  if (error) {
    return (
      <Box>
        <Alert action="error">Error: {error.message}</Alert>
      </Box>
    )
  }

  return (
    <Stack spacing={5}>
      {saveError && <Alert action="error">Error: {saveError.message}</Alert>}
      {validationError && <Alert action="error">{validationError.message}</Alert>}
    <Box typography="h4">Let’s get into your fundraising goals.</Box>
    
    <Divider />
    <Box typography="body2" >What stage is your company in?</Box>
    <Select placeholder="Select…" value={stage} onChange={(_, v) => setStage(v)}>
          <Option value="Pre-Seed">Pre-Seed</Option>
          <Option value="Seed">Seed</Option>
          <Option value="Series A+">Series A+</Option>
        </Select>
    <Divider />
    <Box typography="body2" >I am open to matching with:</Box>
    <CherubRadioGroup choices={choices} defaultValue={openTo} onChange={v => setOpenTo(v.target.value)} />
    <PrimaryButton disabled={!canSubmit} size="large" onClick={() => {      
      saveMatchPreferences({
          variables: {
            stepName: 'set-match-preferences',
            data: {
              stage: stage,
              open_to: openTo,
            },
          },
        }).then((response) => {
          const error = hasErrors(response)
          if (error) {
            return setValidationError(error)
          }

          navigate('/onboarding/pick-subscription')        
        })
        
      }}>Next</PrimaryButton>
  </Stack>
  )
}


function Agreement() {
  const [getOnboardingTerms, {data, loading, called, error }] = useCheckOnboardingWorkflowStatusMutation()
  const [saveOnboardingProfile, { error: saveError }] = useSaveOnboardingMutation()

  const navigate = useNavigate()

  const [terms, setTerms] = React.useState([])

  const Checkbox = ({ idx, item, terms, onChange }: any) => {
    const clickAction = ()=>{                
      terms[idx].agreed = !item.agreed                
      onChange(Object.assign([],terms))                
    }
    return (
      <Grid  container item width="100%" spacing={2}>
        <Grid item flex="0 0 auto">
              {/* <Checkbox label={item} value={false} onChange={()=>{}}/> */}
              <input type="checkbox" checked={item.agreed} onChange={clickAction} />
        </Grid>
        <Grid item typography="body2" flex="1 0 0" onClick={clickAction} color={colors.darkEvergreen[100]}>
          {item.text}
        </Grid>        
      </Grid>
    )
  }
  
  const canSubmit = _.every(terms, ['agreed', true])

  useEffect(()=>{
    getOnboardingTerms({ variables: { stepName: 'agree-to-guidelines' } })
      .then((response) => {
        if (hasErrors(response)) {
          console.error(response.errors)
          return
        }

        setTerms(response.data?.onboardingWorkflow?.data?.agreement_terms)
      })
  }, [getOnboardingTerms])

  if (loading || !called || error || !data?.onboardingWorkflow?.data) {
    return <Box>
      {error && <Alert action="error">Failed to load terms: {error.message}</Alert>}
      <CircularProgress />
    </Box>
  }

  return (
    <Stack spacing={5}>
      {saveError && <Alert action="error">Error: {saveError.message}</Alert>}
      <Box typography="h4">By signing up for Cherub:</Box>
      {data.onboardingWorkflow.profileType === 'investor' &&
        <>
          <Divider />
          <Typography>Before you begin you must agree to our community guidelines.</Typography>
        </>
      }
      {terms.map((item: any, i) => (
          
            <Checkbox key={i} idx={i} item={item} terms={terms} onChange={setTerms}/> 

        ))}
      <PrimaryButton disabled={!canSubmit} size="large" onClick={() => {
        saveOnboardingProfile({
          variables: {
            stepName: 'agree-to-guidelines',
            data: {
              agreement_terms: terms,
            },
          },
        }).then(({errors}) =>{
          if (errors) {
            console.error(errors)
            return
          }

          if (data!.onboardingWorkflow!.profileType === 'investor') {
            navigate('/onboarding/verify-identity')
          } else {
            navigate('/onboarding/collect-payment')
          }
        })
        
      }}>Next</PrimaryButton>
    </Stack>
  )
}

function Onboarding() {
  const {resumeAfterOnboarding} = useAuthRedirects()
  const location = useLocation()
  const navigate = useNavigate()
  const outlet = useOutlet()

  const {data} = useOnboardingMeQuery()

  const [checkStatus, {data: status, loading: checking, called: checked, error: checkError}] = useCheckOnboardingWorkflowStatusMutation()
  const [rerouted, setRerouted] = useState(false)
  const decidingRoute = (checking || !checked || checkError || !rerouted)

  useEffect(() => {
    if (checking || checked) {
      return
    }

    checkStatus({ variables: { stepName: '' }}).then((response) => {
      const error = hasErrors(response)
      if (error) {
        console.error(error)
      }
    })
  }, [checkStatus, checking, checked])
  
  useEffect(() => {
    if (!decidingRoute || !status?.onboardingWorkflow) {
      return
    }

    if (status.onboardingWorkflow.profileType === 'founder') {
      switch(status.onboardingWorkflow.status.value) {
        case 'newly-created':
          navigate('/onboarding/tell-us-about-yourself')
          break
        case 'set-match-tags':
          navigate('/onboarding/select-industries')
          break
        case 'choose-plan':
          navigate('/onboarding/pick-subscription')
          break
        case 'agree-to-guidelines':
          navigate('/onboarding/agreement')
          break
        case 'collect-payment':
          const params = new URLSearchParams(location.search)
          const sessionId = params.get('session_id')

          if(sessionId) {
            navigate(`/onboarding/payment-finished?session_id=${sessionId}`)
          } else {
            navigate('/onboarding/collect-payment')
          }
          break
        case 'verify-identity':
          navigate('/onboarding/verify-identity')
          break
        case 'onboarding-complete':
          navigate(`/profile/${data?.me.orgId}/edit`)
          break
        case 'set-match-preferences':
          navigate('/onboarding/fundraising-goals')
          break
        default:
          navigate('/onboarding/tell-us-about-yourself')
          break
      }
    } else {
      switch(status.onboardingWorkflow.status.value) {
        case 'newly-created':
          navigate('/onboarding/tell-us-about-yourself')
          break
        case 'set-match-tags':
          navigate('/onboarding/select-industries')
          break
        case 'choose-plan':
          navigate('/onboarding/pick-subscription')
          break
        case 'agree-to-guidelines':
          navigate('/onboarding/agreement')
          break
        case 'accreditation-survey':
          navigate('/onboarding/accreditation')
          break
        case 'verify-identity':
          navigate('/onboarding/verify-identity')
          break
        case 'onboarding-complete':
          navigate(`/profile/${data?.me.orgId}/edit`)
          break
        case 'set-match-preferences':
          navigate('/onboarding/investment-appetite')
          break
        default:
          navigate('/onboarding/tell-us-about-yourself')
          break
      }
    }
    setRerouted(true)
  }, [status, navigate, outlet, location, data, rerouted, setRerouted, decidingRoute])
  
  // if there is an outlet, the more specific route is being rendered, so we don't need to wait for the checkStatus to be called
  if (decidingRoute) {
    return (
      <Box>
        <Header hideRight />
        <Box maxWidth="22rem" margin="0 auto">
          <CircularProgress />
        </Box>
        {checkError && <Alert action="error">Error: {checkError.message}</Alert>}
      </Box>
    )
  }

  const childSteps = _.filter(route.children, (r) => r.profileType === 'both' || r.profileType === data?.me?.profileType)
  let path = location.pathname
  let sameStep = false
  if (path.includes('pick-frequency')) {
    path = 'pick-subscription'
    sameStep = true
  }
  if (path.includes('payment-finished')) {
    path = 'collect-payment'
    sameStep = true
  }
  const index = _.findIndex(childSteps, route => path.includes(route.path))
  const step = index + 1
  const totalParts = childSteps.length
  const progress = 100 * (step / totalParts)

  return (
    <Box>
      <Header hideRight />
      <Box maxWidth="22rem" margin="0 auto">
        <Grid container marginBottom={6} justifyContent="space-between">
          <Grid item>
            <div role="button" style={{cursor: 'pointer'}} onClick={() => {
              if (index > 0) {
                if (sameStep) {
                  return navigate(`/onboarding/${childSteps[index].path}`)
                }
                navigate(`/onboarding/${childSteps[index - 1].path}`)
              }
            }}>
              <LeftArrow height="18" width="18" />
            </div>
          </Grid>
          <Grid item><Box typography="body3">{step}/{totalParts}</Box></Grid>
        </Grid>
        <Box sx={{width: '100%', margin: '2rem auto'}}>
          <BorderLinearProgress
            variant="determinate"
            value={progress}
          />
        </Box>
        {outlet}
      </Box>
    </Box>
  )
}
