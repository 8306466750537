import React from 'react'
import {Link} from 'react-router-dom'

function Logo(props: any): React.JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="132" height="24" viewBox="0 0 132 24" fill="none">
      <g clipPath="url(#clip0_1244_3060)">
        <path
          d="M0.685547 12.0571C0.685547 5.4057 5.45126 0.845703 11.7598 0.845703C18.377 0.845703 21.9084 5.06285 22.3198 10.4457L16.7313 10.6514C16.3884 8.25142 15.0855 6.02285 11.7255 6.02285C8.53698 6.02285 6.68555 8.38856 6.68555 12.0571C6.68555 15.7257 8.53698 18.0914 11.7255 18.0914C15.0855 18.0914 16.3884 15.8628 16.7313 13.4628L22.3198 13.7028C21.9084 19.0857 18.377 23.2686 11.7598 23.2686C5.45126 23.2686 0.685547 18.7086 0.685547 12.0571ZM30.4094 22.8571H24.5809V1.25713H30.4094V9.58856H39.0152V1.25713H44.8437V22.8571H39.0152V14.32H30.4094V22.8571ZM65.1245 18.0571V22.8571H48.1188V1.25713H64.816V6.05713H53.8445V9.58856H62.3131V14.32H53.8445V18.0571H65.1245ZM87.3164 22.8571H81.4193C80.9736 22.2743 80.6993 20.9371 80.5278 18.7086C80.3564 16.24 79.2936 15.6914 77.3393 15.6914H73.465V22.8571H67.7393V1.25713H78.6421C84.2307 1.25713 87.0079 3.89713 87.0079 8.11428C87.0079 11.2686 85.0536 13.3257 82.4136 13.6686C84.6421 14.1143 85.945 15.3828 86.185 17.8857C86.4936 21.1086 86.5964 22.0686 87.3164 22.8571ZM73.465 11.2H77.6136C80.0821 11.2 81.3164 10.5828 81.3164 8.59428C81.3164 6.8457 80.0821 5.91999 77.6136 5.91999H73.465V11.2ZM99.2383 23.2686C93.684 23.2686 89.4325 20.5943 89.4325 14.2857V1.25713H95.2268V13.3257C95.2268 16.6171 96.2554 18.3657 99.2383 18.3657C102.255 18.3657 103.25 16.6171 103.25 13.3257V1.25713H109.078V14.2857C109.078 20.5943 104.793 23.2686 99.2383 23.2686ZM131.438 16.9257C131.438 20.6628 128.489 22.8571 123.449 22.8571H112.204V1.25713H122.729C128.283 1.25713 130.786 3.58856 130.786 7.22285C130.786 9.62285 129.243 11.2686 126.946 11.6457C129.483 12.16 131.438 13.84 131.438 16.9257ZM122.489 14.1828H117.86V18.1943H122.523C124.546 18.1943 125.438 17.3714 125.438 16.2057C125.438 15.04 124.546 14.1828 122.489 14.1828ZM117.86 5.8857V9.82856H121.975C123.963 9.82856 124.752 8.93713 124.752 7.90856C124.752 6.77713 123.963 5.8857 121.975 5.8857H117.86Z"
          fill={props?.color || 'currentColor'} />
      </g>
      <defs>
        <clipPath id="clip0_1244_3060">
          <rect width="131.429" height="24" fill={props?.color || 'currentColor'} />
        </clipPath>
      </defs>
    </svg>
  )
}

export function CherubLogo(props: any): React.JSX.Element {
  const nolink = props?.nolink

  if (nolink) {
    return <Logo color={props.color} />
  } else {
    return (
      <Link to='/'>
        <Logo color={props.color} />
      </Link>
    )
  }
}