import {useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {useAuthRedirects} from './useAuthRedirects'

export const route = {
  path: '/auth-landing',
  element: <Outlet />,
  children: [
    {
      path: 'sign-in',
      element: <SignInLanding />,
    },
    {
      path: 'sign-up',
      element: <SignUpLanding />,
    },
    {
      path: 'logged-out',
      element: <LoggedOutLanding />,
    },
  ],
}

function SignInLanding() {
  const redirects = useAuthRedirects()
  useEffect(() => {
    redirects.signIn('/')
  })
  return (
    <div>
    </div>
  )
}

function SignUpLanding() {
  const redirects = useAuthRedirects()
  useEffect(() => {
    redirects.signUp('/')
  })
  return (
    <div>
    </div>
  )
}

function LoggedOutLanding() {
  const redirects = useAuthRedirects()
  useEffect(() => {
    // other logic
    redirects.signOut()
  })
  return (
    <div>
    </div>
  )
}
