import {Box} from '@mui/system'
import {DateTime} from 'luxon'
import colors from '../colors'
import {Eye} from '../ui/icons'
import {SectionDivider, SectionInstructions, SectionSubInstructions} from './Profile.Section'
import {Section, commonFields} from './common'
import content from './content'

// TODOs: need to display lenght limits on text input
// see: https://www.figma.com/file/qMoxJn4rVySreDEnf2R9Js/Cherub-Annotations?type=design&node-id=266-1268&mode=dev
const logo: Section = {
  title: 'Logo',
  percent: 10,
  description: (
    <Box sx={{typography: 'body3'}}>
      <p>This logo picture will appear as a profile headshot. Add a logo image to help people recognize you.</p>
      <p>Recommended size: 600px X 600px</p>
    </Box>
  ),
  fields: [
    {
      property: 'company.logo',
      label: 'Company Logo',
      placeholder: 'Upload',
      sizes: {xs: 12},
      type: 'image',
      extra: {
        previewType: 'avatar',
        hideText: true,
      },
    },
  ],
}

const basicInfo: Section = {
  title: 'Basic Info',
  percent: 20, // TODO: redisplay sum, add up only if all fields are filled
  fields: [
    commonFields.companyName,
    {
      label: 'Company Website',
      property: 'company.website',
      sizes: {xs: 12, md: 6},
      placeholder: 'https://www.example.com',
      type: 'text',
    },
    SectionDivider,
    {
      property: 'company.tagline',
      label: 'Tagline',
      placeholder: 'Cutting-edge Asian American culinary style...',
      // tooltip: content.TODO,
      sizes: {xs: 12},
      type: 'text',
      extra: {
        maxLength: 140,
      },
    },
    // TODO: final repeating to address
    {
      property: 'company.highlights',
      label: 'Highlights',
      description: 'Add up to 3 highlights that help investors understand why you’re worth a second look.',
      placeholder: 'Former VC for an investment startup...',
      sizes: {xs: 12},
      type: 'text',
      extra: {
        maxLength: 40,
        repeats: 3,
      },
    },
    {
      // TODO: all tags need to be considered separately for relational data
      property: 'match_industries',
      label: 'My Industries',
      description:
        'Select categories to help us find relevant investor matches. Only select tags that fully represent your company today.',
      categories: ['industries'],
      sizes: {xs: 12},
      type: 'tags',
      extra: {
        modalPrompt: 'What industries define your company best?',
        modalDescription:
          'Select up to 3 categories to help us find relevant matches. You can always change this later.',
        maxItems: 3,
      },
    },
    {
      property: 'match_characteristics',
      label: 'My Characteristics',
      description: 'Select all relevant characteristics that fully represent your company today.',
      categories: ['company-characteristics'],
      sizes: {xs: 12},
      type: 'tags',
      extra: {
        modalPrompt: 'Which characteristics best apply to your company?',
        maxItems: null,
      },
    },
    {
      property: 'match_targets',
      label: 'My ideal angel investor is…',
      description:
        'Select up to 5 categories that represent the expertise you are looking for.',
      categories: ['expertise-type-skill'], 
      sizes: {xs: 12},
      type: 'tags',
      extra: {
        modalPrompt: 'What type of Cherub members are you looking to connect with?',
        maxItems: 5,
      },
    },
    SectionDivider,
    {
      property: 'company.hero',
      label: 'Add your cover photo or video',
      description: 'File format: .JPG, .GIF, .PNG, MP4 – Max size 10MB\nRecommended asset size is 1000x1000px',
      sizes: {xs: 12},
      type: 'image',
      extra: {},
    },
  ],
}

const keyMetrics: Section = {
  title: 'Key Metrics',
  percent: 5,
  description: (
    <Box sx={{typography: 'body3'}}>
      Numbers that matter to investors when you are pre-seed illustrate demand. Add up to 6 key metrics.'
      <Box sx={{color: colors.gray[1]}}>
        <Eye />
        Only visible to investors
      </Box>
    </Box>
  ),
  fields: [
    // TODO: Metrcs is an array of things that doesn't conform to the previous one
    // See: https://www.figma.com/file/qMoxJn4rVySreDEnf2R9Js/Cherub-Annotations?type=design&node-id=151-2162&mode=dev
    {
      type: 'repeating',
      minimum: 1,
      repeats: 6,
      property: 'metrics',
      fields: [
        {
          property: 'value',
          label: 'Metric',
          sizes: {xs: 12},
          placeholder: '$15k',
          // tooltip: content.TODO,
          type: 'text',
        },
        {
          property: 'description',
          label: 'Description',
          sizes: {xs: 12},
          placeholder: 'Waitlist Signups',
          // tooltip: content.TODO,
          type: 'text',
          extra: {
            maxLength: 30,
          },
        },
      ],
    },
  ],
}

const keyProducts: Section = {
  title: 'Key Products',
  percent: 5,
  description: (
    <>
      <Box sx={{typography: 'body3'}}>{content.edit['key-products-description']}</Box>
    </>
  ),
  fields: [
    {
      type: 'repeating',
      minimum: 1,
      repeats: 3,
      property: 'products',
      fields: [
        {
          property: 'name',
          label: 'Product/Service Name',
          sizes: {xs: 12},
          placeholder: 'X Product',
          // tooltip: content.TODO,
          type: 'text',
        },
        {
          property: 'description',
          label: 'Description',
          sizes: {xs: 12},
          placeholder: 'Sold in 160+ Whole Foods',
          // tooltip: content.TODO,
          type: 'text',
          extra: {
            maxLength: 30,
          },
        },
        {
          property: 'link',
          label: 'Link',
          sizes: {xs: 12},
          placeholder: 'Enter URL',
          // tooltip: content.TODO,
          type: 'text',
        },
        {
          property: 'photo',
          label: 'Add your product photo',
          description: 'File format: .JPG, .GIF, .PNG – Max size 25MB',
          sizes: {xs: 12},
          type: 'image',
          extra: {},
        },
      ],
    },
  ],
}

const pitch: Section = {
  title: 'The Pitch',
  percent: 20,
  description: (
    <Box sx={{typography: 'body3'}}>
      Investors want to get to know you and learn why you're the best founder to realize this vision. Add a pitch video
      to highlight your passion and skills, in addition to a pitch deck that illustrates your path to success. If your
      product is still in development, give investors a way to check it out.
      <Box sx={{color: colors.gray[1]}}>
        <Eye />
        Only visible to investors
      </Box>
    </Box>
  ),
  fields: [
    () => <SectionInstructions text="Pitch in your own words" />,
    {
      property: 'pitch.video',
      label: 'Paste a video URL',
      description: 'Paste the video URL from YouTube.',
      type: 'text',
      sizes: {xs: 12},
      placeholder: 'http://www.example.com',
      extra: {
        showPreview: true,
      },
    },
    {
      property: 'pitch.video_title',
      label: 'Video title',
      placeholder: 'Video title',
      sizes: {xs: 12},
      type: 'text',
    },
    SectionDivider,
    () => <SectionInstructions text="Add your elevator pitch" />,
    {
      property: 'pitch.elevator',
      // TODO: this needs to switch based on the Stage of the company
      label:
        'A good pre-seed company elevator pitch should be concise, compelling, and clearly communicate the essence of your business idea in a brief amount of time.',
      type: 'text',
      sizes: {xs: 12},
      placeholder: 'Enter a description...',
      extra: {
        maxLength: 300,
        textArea: {
          rows: 10,
        },
      },
    },
    SectionDivider,
    () => <SectionInstructions text="Add a pitch deck" />,
    {
      property: 'pitch.title',
      label: 'Display Title',
      placeholder: 'Enter a short description...',
      sizes: {xs: 12},
      type: 'text',
    },
    {
      property: 'pitch.document_url',
      label: 'Doc URL',
      description: 'Paste the embed code url from Dropbox, YouTube, TikTok, business website.',
      placeholder: 'http://www.example.com',
      sizes: {xs: 12},
      type: 'text',
    },
    {
      property: 'pitch.hero',
      label: 'Upload a thumbnail',
      description: 'File format: .JPG, .GIF, .PNG – Max size 10MB \n Recommended asset size is 1824x1026px',
      sizes: {xs: 12},
      type: 'image',
      extra: {},
    },
    SectionDivider,
    () => <SectionInstructions text="Add additional materials" />,
    () => (
      <SectionSubInstructions text="Your data room should be where you share all the valuable materials that an angel investor needs to make a decision.  Include materials like customer testimonials and reviews, financial projections, product demo, etc." />
    ),
    {
      type: 'repeating',
      minimum: 1,
      repeats: 3,
      property: 'pitch_materials',
      fields: [
        {
          property: 'title',
          label: 'Display Title',
          sizes: {xs: 12},
          type: 'text',
        },
        {
          property: 'link',
          label: 'Link',
          sizes: {xs: 12},
          type: 'text',
        },
        {
          property: 'photo',
          label: 'Upload a thumbnail',
          description: 'File format: .JPG, .GIF, .PNG – Max size 10MB \n Recommended asset size is 1824x1026px',
          sizes: {xs: 12},
          type: 'image',
          extra: {},
        },
      ],
    },
    SectionDivider,
    () => <SectionInstructions text="Add a product sample" />,
    {
      property: 'product_sample.title',
      label: 'Promotional offer headline',
      placeholder: 'Want to try our product first?',
      // tooltip: content.TODO,
      sizes: {xs: 12},
      type: 'text',
      extra: {
        maxLength: 40,
      },
    },
    {
      property: 'product_sample.description',
      label: 'Description',
      placeholder: 'Enter a short description...',
      sizes: {xs: 12},
      type: 'text',
      extra: {
        maxLength: 100,
        textArea: {
          rows: 3,
        },
      },
    },
    {
      property: 'product_sample.link',
      label: 'CTA Link',
      placeholder: 'http://www.example.com',
      // tooltip: content.TODO,
      sizes: {xs: 12},
      type: 'text',
    },
    {
      property: 'product_sample.discount_code',
      label: 'Discount code',
      // tooltip: content.TODO,
      placeholder: `${DateTime.local().year}XYZ`,
      sizes: {xs: 12},
      type: 'text',
    },
  ],
}

const whosIn: Section = {
  title: 'Who’s In',
  description: (
    <Box sx={{typography: 'body3'}}>
      Let angel investors know they're in good company and who else is in on your cap table.
    </Box>
  ),
  percent: 5,
  // TODO: top 3 highlighted investor special selector
  fields: [
    () => <SectionInstructions text="All your angel investors/advisors" />,
    () => <SectionSubInstructions text="Add all your angel investors or advisors..." />,
    {
      type: 'repeating',
      minimum: 1,
      repeats: 35,
      property: 'active_investors',
      fields: [
        {
          property: 'name',
          label: 'Full name',
          sizes: {xs: 12},
          // TODO: needs to be a search of investors
          type: 'text',
        },
        {
          property: 'type',
          label: 'Angel investor or advisor?',
          sizes: {xs: 12},
          // TODO: needs to be a select w/ options
          type: 'text',
        },
        {
          property: 'title',
          label: 'Title',
          tooltip:
            'What unique qualities or attributes could make this individual a noteworthy addition to your list of investors, particularly in comparison to other potential investors?',
          sizes: {xs: 12},
          type: 'text',
        },
      ],
    },
  ],
}

const lookingFor: Section = {
  title: "What you're looking for",
  percent: 10,
  fields: [
    {
      property: 'matches.looking_for',
      label:
        "In your own words, describe the skills or qualities of the angel investors or advisors you'd like to bring to your cap table",
      sizes: {xs: 12},
      type: 'text',
      extra: {
        textArea: {
          rows: 6,
        },
      },
    },
  ],
}

const raiseDetails: Section = {
  title: 'Raise Details',
  percent: 10,
  description: (
    <>
      <Box sx={{typography: 'h5'}}>Raises</Box>
      {/* TODO: this and a few other fields need to reference current Raise */}
      <Box sx={{typography: 'body3', color: colors.gray[2]}}>
        In the pre-seed stage, "raise" means securing initial funding to validate your concept, develop an MVP, and
        build your team, typically from friends, family, or angel investors. Aim for enough to reach key milestones
        while being realistic about your resources.
      </Box>
      <Box sx={{typography: 'body3', color: colors.gray[1]}}>
        <Eye />
        Only visible to investors
      </Box>
    </>
  ),
  fields: [
    {
      property: 'raises',
      label: 'Manage raises',
      placeholder: 'Bowlcut Pre-Seed 2 $1M <Active> >',
      sizes: {xs: 12},
      type: 'raise',
      repeats: 5,
    },
    SectionDivider,
    () => <SectionInstructions text="Why you’re raising" />,
    () => <SectionSubInstructions text="Add up to 3 reasons why you're raising and how the investment will be spent." />,
    {
      type: 'repeating',
      property: 'raise_reasons',
      minimum: 1,
      repeats: 3,
      fields: [
        {
          property: 'title',
          label: 'Title',
          placeholder: 'A short description',
          sizes: {xs: 12},
          type: 'text',
        },
        {
          property: 'details',
          label: 'Details',
          placeholder: 'Add a few sentence to support your reason...',
          sizes: {xs: 12},
          type: 'text',
          extra: {
            maxLength: 100,
            textArea: {
              rows: 3,
            },
          },
        },
      ],
    },
  ],
}

const featuredIn: Section = {
  title: 'Featured In',
  description: (
    <Box sx={{typography: 'body3'}}>
      Have you established any social proof? Let investors know if anyone is talking about you or if you are associated
      with any notable accelorators or awards.
    </Box>
  ),
  percent: 5,
  fields: [
    {
      type: 'repeating',
      minimum: 1,
      repeats: 10,
      property: 'featured_articles',
      fields: [
        {
          property: 'publisher_name',
          label: 'Name',
          // tooltip: content.TODO,
          sizes: {xs: 12},
          type: 'text',
        },
        {
          property: 'title',
          label: 'Title',
          // tooltip: content.TODO,
          sizes: {xs: 12},
          type: 'text',
        },
        {
          // THOUGHT: small service job that queries all link hostnames and reports on them
          property: 'link',
          label: 'Link',
          sizes: {xs: 12},
          type: 'text',
        },
        // TODO: need to sort out article_image has_many
        {
          property: 'photo',
          label: 'Upload a thumbnail',
          description: 'File format: .JPG, .GIF, .PNG – Max size 10MB \n Recommended asset size is 1000x1000px',
          sizes: {xs: 12},
          type: 'image',
          extra: {},
          // TODO:
        },
      ],
    },
  ],
}

const team: Section = {
  title: 'The Team',
  description: (
    <Box sx={{typography: 'body3'}}>
      Why are you and your team the best people for the Job? Highlight yourself and key members of your team who
      illustrate the expertise to bring your plan to life.
    </Box>
  ),
  percent: 5,
  fields: [
    () => <SectionInstructions text="Main contact" />,
    {
      property: 'organization_contact.headshot',
      label: 'Headshot',
      description: 'File format: .JPG, .GIF, .PNG – Max size 10MB \n Recommended asset size is 1000x1000px',
      sizes: {xs: 12},
      type: 'image',
      extra: {
        previewType: 'avatar',
      },
    },
    commonFields.contactFirstName,
    commonFields.contactLastName,
    commonFields.contactJobRole,
    {
      property: 'organization_contact.experience',
      label: 'Experience Highlights',
      // tooltip: content.TODO,
      sizes: {xs: 12},
      type: 'text',
      extra: {
        textArea: {
          rows: 2,
        },
      },
    },
    {
      property: 'organization_contact.linkedin',
      label: 'LinkedIn',
      sizes: {xs: 12},
      type: 'text',
    },
    SectionDivider,
    () => <SectionInstructions text="Key team members" />,
    {
      type: 'repeating',
      minimum: 0,
      repeats: 3,
      property: 'organization_members',
      fields: [
        {
          property: 'headshot',
          label: 'Headshot',
          description: 'File format: .JPG, .GIF, .PNG – Max size 10MB \n Recommended asset size is 1000x1000px',
          sizes: {xs: 12},
          type: 'image',
          extra: {
            previewType: 'avatar',
          },
        },
        {
          property: 'first_name',
          // TODO: this may not be sufficient for this case,
          // may want to introduce text to follow dividers and turn that to an explicit type and remove instructions
          // see: https://www.figma.com/file/qMoxJn4rVySreDEnf2R9Js/Cherub-Annotations?type=design&node-id=179-4325&mode=dev
          label: 'First Name',
          sizes: {xs: 6},
          type: 'text',
        },
        {
          property: 'last_name',
          label: 'Last Name',
          sizes: {xs: 6},
          type: 'text',
        },
        {
          property: 'job_role',
          label: 'Your role in the company',
          // tooltip: content.TODO,
          sizes: {xs: 12},
          type: 'text',
          extra: {
            maxLength: 40,
          },
        },
        {
          property: 'experience',
          label: 'Experience Highlights',
          // tooltip: content.TODO,
          sizes: {xs: 12},
          type: 'text',
          extra: {
            maxLength: 40,
            textArea: {
              rows: 2,
            },
          },
        },
        {
          property: 'linkedin',
          label: 'LinkedIn',
          sizes: {xs: 12},
          type: 'text',
        },
      ],
    },
  ],
}

// const qAndA: Section = {
//   title: 'Q & A',
//   description: <Box sx={{typography: 'body3'}}>If investors still have questions, they can ask them here.</Box>,
//   percent: 5,
//   fields: [],
//   // TODO: see https://www.figma.com/file/qMoxJn4rVySreDEnf2R9Js/Cherub-Annotations?type=design&node-id=179-5129&mode=dev
//   // needs to be a link out
// }

const companyDetails: Section = {
  title: 'Company Details',
  description: (
    <Box sx={{typography: 'body3'}}>
      Where else should investors go to learn more about your company? Add anything that helps to paint a full picture
      of your progress so far.
    </Box>
  ),
  percent: 5,
  fields: [
    {
      property: 'company_details.legal_name',
      label: 'Legal Name',
      placeholder: 'Name, LLC',
      sizes: {xs: 12},
      type: 'text',
    },
    SectionDivider,
    {
      property: 'company_details.company_size',
      label: 'How many employees are in your company?',
      sizes: {xs: 12},
      type: 'select',
      // TODO: need this to be a tag actually
      // https://docs.google.com/spreadsheets/d/1UhpQXzPKOI7R2LW_rZ_9SEVPGquO6jyomTTwZ896_kI/edit#gid=20691204&range=A72
      options: [
        {value: 'Just me', label: 'Just me'},
        {value: 'Less than 10 ', label: 'Less than 10 '},
        {value: '10-25 employees', label: '10-25 employees'},
        {value: '25+ employees', label: '25+ employees'},
      ],
    },
    SectionDivider,
    // TODO: location select, maybe https://www.npmjs.com/package/react-location-picker
    {
      property: 'company_details.location',
      label: 'Where is your team headquartered?',
      placeholder: 'Type a location',
      sizes: {xs: 12},
      type: 'text',
    },
    SectionDivider,
    {
      property: 'company_details.founded',
      label: 'When was your company founded?',
      sizes: {xs: 12},
      type: 'date',
    },
    SectionDivider,
    () => <SectionInstructions text="Find us" />,
    {
      // TODO: validation that it's an LL link, same for all
      property: 'company_social_links.linkedin',
      label: 'LinkedIn',
      placeholder: 'Enter URL',
      sizes: {xs: 12},
      type: 'text',
    },
    {
      property: 'company_social_links.facebook',
      label: 'Facebook',
      placeholder: 'Enter URL',
      sizes: {xs: 12},
      type: 'text',
    },
    {
      property: 'company_social_links.instagram',
      label: 'Instagram',
      placeholder: 'Enter URL',
      sizes: {xs: 12},
      type: 'text',
    },
    {
      property: 'company_social_links.youtube',
      label: 'Youtube',
      placeholder: 'Enter URL',
      sizes: {xs: 12},
      type: 'text',
    },
    {
      property: 'company_social_links.twitter',
      label: 'Twitter',
      placeholder: 'Enter URL',
      sizes: {xs: 12},
      type: 'text',
    },
    {
      property: 'company_social_links.tiktok',
      label: 'TikTok',
      placeholder: 'Enter URL',
      sizes: {xs: 12},
      type: 'text',
    },
    () => <SectionSubInstructions text="Other" />,
    {
      type: 'repeating',
      minimum: 1,
      repeats: 3,
      property: 'company_social_links_others',
      fields: [
        {
          property: 'title',
          label: 'Display title',
          placeholder: 'Enter title',
          sizes: {xs: 12},
          type: 'text',
        },
        {
          property: 'url', // TODO: url -> link
          label: 'Link',
          placeholder: 'Enter URL',
          sizes: {xs: 12},
          type: 'text',
        },
      ],
    },
  ],
}

const sections = [
  logo,
  basicInfo,
  keyMetrics,
  keyProducts,
  pitch,
  whosIn,
  lookingFor,
  raiseDetails,
  featuredIn,
  team,
  // qAndA,
  companyDetails,
]

if (sections.reduce((acc, section) => acc + section.percent, 0) !== 100) {
  throw new Error('Sections do not add up to 100%')
}

export default sections
