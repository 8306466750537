import {Input as BaseInput, InputProps} from '@mui/base'
import {Typography} from '@mui/material'
import {styled} from '@mui/system'
import * as React from 'react'
import colors from '../colors'
import {InputProfileField} from './common'

const WrapperDivElement = styled('div')<{isTextArea: boolean}>(
  ({theme, isTextArea}) => `
  border-radius: 8px;
  border: 1px solid ${colors.background[4]};
  background: ${colors.background.white};

  align-items: end;
  align-self: stretch;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: ${isTextArea ? '10px' : '4px 10px'};
`,
)

const InputElement = styled('input')(
  ({theme}) => `
  border: 0;
  color: ${colors.darkEvergreen[100]};

  /* Headline/Mobile/h4 */
  font-family: "Warnock-Pro";
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: -0.24px;
  outline: none;
  width: 100%;
  
  ::placeholder {
    color: ${colors.darkEvergreen[40]};
  }
`,
)

const TextareaElement = styled('textarea', {
  shouldForwardProp: prop => !['ownerState', 'minRows', 'maxRows'].includes(prop.toString()),
})(
  ({theme}) => `
  border: 0;
  color: ${colors.darkEvergreen[100]};

  /* Headline/Mobile/h4 */
  font-family: "Warnock-Pro";
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 115%; /* 27.6px */
  letter-spacing: -0.24px;
  outline: none;
  width: 100%;

  resize: none;

  ::placeholder {
    color: ${colors.darkEvergreen[40]};
  }
`,
)

type ProfileInputProps = InputProfileField & {
  'aria-label': string // makes this required
  value: string
}

function LimitAdornment(props: {maxLength: number; value: string}): React.JSX.Element {
  return (
    // TODO: textarea custom adornment
    <Typography variant="body4" color={colors.gray[3]}>
      {props.value.length}/{props.maxLength}
    </Typography>
  )
}

const defaultLimit = 500

const Input = React.forwardRef(function CustomInput(
  props: InputProps & ProfileInputProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  // MUI has a typing quirk where multiline is typed more like a constant than a field, but this gets around that
  let extraProps = props.extra?.textArea as any
  if (extraProps) {
    extraProps.multiline = true
  }
  return (
    <BaseInput
      slots={{
        root: WrapperDivElement,
        input: InputElement,
        textarea: TextareaElement,
      }}
      slotProps={{
        root: {isTextArea: !!props.extra?.textArea},
        input: {maxLength: props.extra?.maxLength ?? defaultLimit},
        textarea: props.extra?.textArea,
      }}
      {...props}
      {...extraProps}
      id={props.property}
      ref={ref}
      label={props.description}
      endAdornment={props.extra?.maxLength && <LimitAdornment maxLength={props.extra?.maxLength} value={props.value} />}
    />
  )
})

export default Input
