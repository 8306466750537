import {createTheme} from '@mui/material'
import colors from './colors'

// Update typings, see https://mui.com/material-ui/customization/typography/#adding-amp-disabling-variants
type CSSProperties = React.CSSProperties &
  Partial<{
    '@media (max-width: 600px)': React.CSSProperties
  }>

declare module '@mui/material' {
  interface TypographyVariants {
    d1: CSSProperties
    d2: CSSProperties
    d3: CSSProperties
    d4: CSSProperties
    d5: CSSProperties
    h1: CSSProperties
    h3: CSSProperties
    h4: CSSProperties
    h5: CSSProperties
    mobileh5: CSSProperties
    body1medium: CSSProperties
    body1: CSSProperties
    body2medium: CSSProperties
    body2: CSSProperties
    body3medium: CSSProperties
    body3: CSSProperties
    body4medium: CSSProperties
    body4: CSSProperties
    title2medium: CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    d1?: CSSProperties
    d2?: CSSProperties
    d3?: CSSProperties
    d4?: CSSProperties
    d5?: CSSProperties
    h1?: CSSProperties
    h3?: CSSProperties
    h4?: CSSProperties
    h5?: CSSProperties
    mobileh5?: CSSProperties
    body1medium?: CSSProperties
    body1?: CSSProperties
    body2medium?: CSSProperties
    body2?: CSSProperties
    body3medium?: CSSProperties
    body3?: CSSProperties
    body4medium?: CSSProperties
    body4?: CSSProperties
    title2medium: CSSProperties
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    d1: true
    d2: true
    d3: true
    d4: true
    d5: true
    h1: true
    h3: true
    h4: true
    h5: true
    mobileh5: true
    body1medium: true
    body1: true
    body2medium: true
    body2: true
    body3medium: true
    body3: true
    body4medium: true
    body4: true
    title2medium: true
  }
}

// Evergreen: '#0F5148'
const theme = createTheme({
  // Desktop:
  // max width: 1440px
  // content max width: 1200px (120px gutters)

  // Mobile:
  // max width: 375px
  // content max width: 325px (25px gutters)

  spacing: (factor: number) => `${0.25 * factor}rem`,

  typography: {
    d1: {
      color: colors.background.black,
      fontFamily: 'Warnock-Pro',
      fontSize: '160px',
      fontWeight: 300,
      lineHeight: '100%',
      letterSpacing: '-4.8px',
      // TODO: this should be referencable via mui
      '@media (max-width: 600px)': {
        fontSize: '56px',
        letterSpacing: '-1.68px',
      },
    },
    d2: {
      color: colors.background.black,
      fontFamily: 'Warnock-Pro',
      fontSize: '76px',
      fontWeight: 300,
      lineHeight: '110%',
      letterSpacing: '-2.28px',
      '@media (max-width: 600px)': {
        fontSize: '36px',
        letterSpacing: '-1.08px',
      },
    },
    d3: {
      color: colors.background.black,
      fontFamily: 'Warnock-Pro', // TODO: Campaign?
      fontSize: '160px',
      fontWeight: 480,
      lineHeight: '90%',
      textTransform: 'uppercase',
      '@media (max-width: 600px)': {
        fontSize: '56px',
      },
    },
    d4: {
      color: colors.background.black,
      fontFamily: 'Warnock-Pro', // TODO: Campaign?
      fontSize: '90px',
      fontWeight: 480,
      lineHeight: '90%',
      textTransform: 'uppercase',
      '@media (max-width: 600px)': {
        fontSize: '30px',
      },
    },
    d5: {
      color: colors.background.black,
      fontFamily: 'Warnock-Pro', // TODO: Campaign?
      fontSize: '42px',
      fontWeight: 480,
      lineHeight: '90%',
      textTransform: 'uppercase',
      '@media (max-width: 600px)': {
        fontSize: '24px',
      },
    },
    h1: {
      color: colors.evergreen[130],
      fontFamily: 'Warnock-Pro',
      fontSize: '56px',
      fontWeight: 300,
      lineHeight: '110%',
      letterSpacing: '-1.86px',
      '@media (max-width: 600px)': {
        fontSize: '40px',
        letterSpacing: '-1.2px',
      },
    },
    h2: {
      fontFamily: 'Warnock-Pro',
      fontSize: '3rem',
      fontWeight: 300,
      letterSpacing: '-0.09rem',
      lineHeight: '110%',
      '@media (max-width: 600px)': {
        fontSize: '2.25rem',
        lineHeight: '110%',
        letterSpacing: '-1%',
      },
    },
    h3: {
      color: colors.evergreen[130],
      fontFamily: 'Warnock-Pro',
      fontSize: '40px',
      fontWeight: 300,
      lineHeight: '110%',
      letterSpacing: '-1.2px',
      '@media (max-width: 600px)': {
        fontSize: '28px',
        lineHeight: '115%',
        letterSpacing: '-0.28px',
      },
    },
    h4: {
      color: colors.evergreen[130],
      fontFamily: 'Warnock-Pro',
      fontSize: '36px',
      fontWeight: 300,
      lineHeight: '110%',
      letterSpacing: '-0.36px',
      '@media (max-width: 600px)': {
        fontSize: '24px',
        lineHeight: '115%',
        letterSpacing: '-0.24px',
      },
    },
    h5: {
      color: colors.evergreen[130],
      fontFamily: 'Warnock-Pro',
      fontSize: '28px',
      fontWeight: 300,
      lineHeight: '115%',
      letterSpacing: '-0.28px',
      '@media (max-width: 600px)': {
        fontSize: '20px',
        lineHeight: '115%',
        letterSpacing: '-0.2px',
      },
    },
    mobileh5: {
      fontFamily: 'Warnock-Pro',
      fontSize: '20px',
      fontWeight: 300,
      lineHeight: '115%',
      letterSpacing: '-1%',
      textDecoration: 'none',
    },
    h6: {
      color: colors.evergreen[130],
      fontFamily: 'Warnock-Pro',
      fontSize: '24px',
      fontWeight: 300,
      lineHeight: '115%',
      letterSpacing: '-0.24px',
      '@media (max-width: 600px)': {
        fontSize: '18px',
        lineHeight: '115%',
        letterSpacing: '-0.18px',
      },
    },
    body1medium: {
      color: colors.gray[1],
      fontFamily: 'Aktiv-Grotesk',
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '110%',
    },
    body1: {
      color: colors.gray[1],
      fontFamily: 'Aktiv-Grotesk',
      fontSize: '20px',
      fontWeight: 400,
      lineHeight: '130%',
    },
    body2medium: {
      color: colors.gray[1],
      fontFamily: 'Aktiv-Grotesk',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '110%',
    },
    body2: {
      color: colors.gray[1],
      fontFamily: 'Aktiv-Grotesk',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '130%',
    },
    body3medium: {
      color: colors.gray[1],
      fontFamily: 'Aktiv-Grotesk',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '110%',
    },
    body3: {
      color: colors.gray[1],
      fontFamily: 'Aktiv-Grotesk',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '130%',
    },
    body4medium: {
      color: colors.gray[1],
      fontFamily: 'Aktiv-Grotesk',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '130%',
    },
    body4: {
      color: colors.gray[1],
      fontFamily: 'Aktiv-Grotesk',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '130%',
    },
    title2medium: {
      fontFamily: 'Aktiv-Grotesk',
      fontSize: '1.5rem',
      fontWeight: 300,
      lineHeight: '130%',
    },
  },
})

export default theme
