import {Grid, Typography} from '@mui/material'
import {Box} from '@mui/system'
import * as React from 'react'
import colors from '../colors'
import {SecondaryButton, SecondaryLabelButton} from '../ui/Buttons'
import {Add, Delete, Edit} from '../ui/icons'
import AvatarImagePreview from './Profile.AvatarImagePreview'
import {useProfileContext} from './Profile.Contexts'
import ImagePreview from './Profile.ImagePreview'
import CherubTooltip from './Profile.Tooltip'
import {ImageProfileField} from './common'

type ProfileInputProps = ImageProfileField & {
  'aria-label': string // makes this required
  description?: string
  // an object is from the API, but a string is from the blob(b64) preview that is generated
  url: {key: string; ref: 'files'} | string | undefined
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

// We want to ensure the same htmlFor for change and upload so it controls the input in both cases
// and so that when clicking on the button, it triggers the file input natively
function OpenInputButton({
  property,
  label,
  children,
}: React.PropsWithChildren<{property: string; label: string}>): React.JSX.Element {
  return (
    <SecondaryLabelButton size="medium" htmlFor={property} aria-label={label}>
      {children}
    </SecondaryLabelButton>
  )
}

export default function ImageInput(
  // File inputs cannot be controlled, so force value to never be set
  props: Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value'> & ProfileInputProps,
) {
  const {
    form: {currentFiles},
  } = useProfileContext()

  let url = undefined
  if (typeof props.url === 'string') {
    url = props.url
  } else if (typeof props.url === 'object') {
    url = currentFiles[props.url?.key]
  }

  const [preview, setPreview] = React.useState(url)
  const [justDeleted, setJustDeleted] = React.useState(false)
  // Since file inputs are uncontrolled, we need to change the key to force a re-render to clear
  const [inputKey, setInputKey] = React.useState(new Date().toISOString())
  const ref = React.useRef<HTMLDivElement>(null)

  const label = props['aria-label']
  const inputName = props.property
  const clearName = `Clear ${inputName}`

  React.useEffect(() => {
    if (justDeleted && ref.current) {
      window.scrollBy(0, ref.current.getBoundingClientRect().top - 120)
      setJustDeleted(false)
    }
  }, [justDeleted])

  return (
    <Box>
      <Grid ref={ref} container spacing={3}>
        <Grid item xs={12} sx={{display: props.extra.hideText ? 'none' : 'block'}}>
          <Typography variant="body4medium" color={colors.darkEvergreen[60]}>
            {props['aria-label']}
            {props.tooltip && <CherubTooltip description={props.tooltip} />}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {props.description && <Box sx={{typography: 'body3medium'}}>{props.description}</Box>}
        </Grid>
        <Grid item xs={12}>
          {props.extra.previewType === 'avatar' ? (
            <AvatarImagePreview image={preview} />
          ) : (
            <ImagePreview image={preview} />
          )}
        </Grid>
        <Grid container item xs={12} justifyContent="flex-start" spacing={2}>
          {preview ? (
            <>
              <Grid item xs={6} sm="auto">
                <OpenInputButton property={inputName} label={label}>
                  <Edit /> Change
                </OpenInputButton>
              </Grid>
              <Grid item xs={6} sm="auto">
                <SecondaryButton
                  size="medium"
                  aria-label={clearName}
                  onClick={() => {
                    setPreview(undefined)
                    setInputKey(new Date().toISOString())
                    // TODO: this could be better? could cause issues in PreviewableImageInput's callbacks
                    props.onChange({
                      target: {},
                    } as React.ChangeEvent<HTMLInputElement>)
                    setJustDeleted(true)
                  }}>
                  <Delete /> Delete
                </SecondaryButton>
              </Grid>
            </>
          ) : (
            <Grid item xs={12} sm="auto">
              <OpenInputButton property={inputName} label={label}>
                <Add /> Upload
              </OpenInputButton>
            </Grid>
          )}
        </Grid>
        <input
          {...props}
          id={inputName}
          name={inputName}
          key={inputKey}
          accept="image/png,image/jpeg,image/jpg,image/gif,image/svg+xml"
          type="file"
          style={{display: 'none'}}
          defaultValue={undefined}
          onChange={event => {
            const file = event.target.files?.[0]
            if (file) {
              props.onChange(event)
              setPreview(URL.createObjectURL(file))
            }
          }}
        />
      </Grid>
    </Box>
  )
}
