import {InMemoryCache} from '@apollo/client'
import {Alert} from '@mui/material'
import {PropsWithChildren, useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {CallbackPrerouteDocument, MeQuery} from '../api/types'
import {useAuthRedirects} from './useAuthRedirects'
import {useAuthentication} from './useAuthentication'

const cache = new InMemoryCache()

// View wrapper that requires an authenticated user, and optionally forces them to the login page
export function RequiresAuthentication(props: PropsWithChildren<{forceLogin?: boolean}>) {
  const {state, _client} = useAuthentication()
  const navigate = useNavigate()
  const location = useLocation()

  const {signIn, signUp} = useAuthRedirects()
  const [error, setError] = useState<string | null>(null)
  const [checkedStatus, setCheckedStatus] = useState(false)

  const forceLogin = props.forceLogin === true || props.forceLogin === undefined

  // TODO: figure out how to have this occur in Callback.tsx or right after aut h
  useEffect(() => {
    if (state === 'loading') {
      return
    }

    if (state === 'error') {
      setError('Error loading account authentication')
      return
    }

    if (state === 'unauthenticated') {
      if (forceLogin) {
        if (location.pathname.startsWith('/onboarding')) {
          signUp()
        } else {
          signIn()
        }
      } else {
        setCheckedStatus(true)
      }
      return
    }

    if (!_client) {
      setError('Client not initialized')
      return
    }

    if (window.location.hash.startsWith('#/onboarding')) {
      // since Onboarding can change the results of the query, we need to reset the cache
      cache.reset()
      setCheckedStatus(true)
      return
    }

    if (checkedStatus) {
      return
    }
    const query = CallbackPrerouteDocument
    const cached = cache.readQuery({query}) as MeQuery

    const request = cached ? Promise.resolve({data: cached, error: null}) : _client.query<MeQuery>({query})
    request
      .then(({data, error}) => {
        if (error) {
          throw error
        }

        cache.writeQuery({query, data})

        // TODO: use stripe JS lib for these enums
        setCheckedStatus(true)
        if (data.me.onboardingStatus !== 'onboarding-complete') {
          navigate('/onboarding')
          return
        }
      })
      .catch(error => setError(error.message))
  }, [state, _client, forceLogin, location, navigate, signUp, signIn, checkedStatus])

  if (state !== 'authenticated' || !checkedStatus) {
    return <>{error && <Alert>{error}</Alert>}</>
  }

  return <>{props.children}</>
}
