import {InputProps} from '@mui/base'
import {Typography} from '@mui/material'
import {Box, styled} from '@mui/system'
import _ from 'lodash'
import * as React from 'react'
import colors from '../colors'
import {SecondaryButton} from '../ui/Buttons'
import {Add} from '../ui/icons'
import {useProfileContext} from './Profile.Contexts'
import Input from './Profile.Input'
import CherubTooltip from './Profile.Tooltip'
import {InputProfileField} from './common'

const DivElement = styled('div')(
  ({theme}) => `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
`,
)

export function PreviewFor({name, value, style}: {name: string; value: string, style?: React.CSSProperties }): React.JSX.Element {
  // TODO: for some reason TypeScrip thinks this doesn't exist on save & compile?
  let url
  try {
    url = new URL(value)
  } catch (err: any) {
    return <></>
  }
  
  if (['www.youtube.com', 'youtube.com'].includes(url.hostname)) {
    const videoId = url.searchParams.get('v')
    return (
      <iframe
        title={`Preview for ${name}`}
        src={`https://www.youtube.com/embed/${videoId}`}
        frameBorder="0"
        allowFullScreen
        style={{maxWidth: '100%', borderRadius: '16px', border: 0, ...style}}
      />
    )
  }

  return <></>
}
type ProfileInputProps = InputProfileField & {
  'aria-label': string // makes this required
  value: string
}

const TextField = React.forwardRef(function CustomTextField(
  props: InputProps & ProfileInputProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  // Highlights repeating is not an ideal pattern, so we'll handle it one off and think on it later
  const {
    form: {profile},
    dispatch,
  } = useProfileContext()
  // TODO: maybe should only re-render the Preview on blur
  const isRepeating = props.extra?.repeats != null
  const [count, setCount] = React.useState(_.get(profile, props.property)?.length || 1)

  return (
    <DivElement>
      <label htmlFor={props.property}>
        <Typography variant="body4medium" color={colors.darkEvergreen[60]}>
          {props['aria-label']}
        </Typography>
        {props.tooltip && <CherubTooltip description={props.tooltip} />}
      </label>
      {props.description && <Box sx={{typography: 'body3medium'}}>{props.description}</Box>}
      {isRepeating ? (
        _.range(count).map(i => {
          const repeated = {...props, property: `${props.property}[${i}].value`}
          repeated.value = _.get(profile, repeated.property) ?? ''
          repeated.onChange = event => {
            dispatch({type: 'set_value', field: repeated, value: event.target.value})
          }
          return <Input {...repeated} key={i} ref={ref} />
        })
      ) : (
        <Input {...props} ref={ref} />
      )}
      {props.extra?.showPreview && (
        <>
          <Box sx={{typograph: 'body3'}}>Preview: </Box>
          <Box>{props.value && <PreviewFor name={props.label} value={props.value} />}</Box>
        </>
      )}
      {isRepeating && count < (props.extra?.repeats ?? 0) && (
        <SecondaryButton size="medium-large" type="button" onClick={() => setCount(count + 1)}>
          <Add /> Add
        </SecondaryButton>
      )}
    </DivElement>
  )
})

export default TextField
