import {styled} from '@mui/system'

const ImageViewport = styled('div')(
  ({theme}) => `
  display: flex;
  justify-content: center;
  align-items: center;
`,
)

export default function ImagePreview({image}: {image?: string}): React.JSX.Element {
  if (!image) {
    return <></>
  }
  return (
    <ImageViewport>
      <img src={image} alt="Current" style={{maxWidth: '100%', maxHeight: '100%'}} />
    </ImageViewport>
  )
}
