import {Typography} from '@mui/material'
import {Box, styled} from '@mui/system'
import {DatePicker, DatePickerProps} from '@mui/x-date-pickers'
import {DateTime} from 'luxon'
import * as React from 'react'
import colors from '../colors'
import CherubTooltip from './Profile.Tooltip'
import {DateProfileField} from './common'

const styles = {
  borderRadius: '8px',
  border: `1px solid ${colors.background[4]}`,
  background: colors.background.white,
  boxSizing: 'border-box',
  width: '100%',

  '& .MuiInputBase-input': {
    color: colors.darkEvergreen[100],
    fontFamily: 'Warnock-Pro',
    fontSize: '24px',
    fontWeight: 300,
    lineHeight: '115%',
    letterSpacing: '-0.24px',
    padding: '10px',
    outline: 'none',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderWidth: '0px !important',
    borderColor: 'transparent !important',
    outline: 'none !important',
  },
}

const DivElement = styled('div')(
  ({theme}) => `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
`,
)

type ProfileInputProps = DateProfileField & {
  'aria-label': string // makes this required
  value: string | DateTime | null
}

const DateField = React.forwardRef(function CustomDateField(
  props: DatePickerProps<DateTime> & ProfileInputProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  // TODO: maybe should only re-render the Preview on blur
  let value = props.value ?? null
  if (typeof props.value === 'string') {
    value = DateTime.fromISO(props.value)
  }
  return (
    <DivElement>
      <label htmlFor={props.property}>
        <Typography variant="body4medium" color={colors.darkEvergreen[60]}>
          {props['aria-label']}
        </Typography>
        {props.tooltip && <CherubTooltip description={props.tooltip} />}
      </label>
      {props.description && <Box sx={{typography: 'body3medium'}}>{props.description}</Box>}
      <DatePicker
        sx={styles}
        ref={ref}
        {...props}
        onChange={(date, context) => {
          props?.onChange?.(date, context)
        }}
        value={value}
        format="MM/dd/yyyy"
        label=""
      />
    </DivElement>
  )
})

export default DateField
