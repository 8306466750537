import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  ISO8601DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
};

/** Autogenerated input type of AddStarterDeals */
export type AddStarterDealsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of profile to add deals to */
  userId: Scalars['ID']['input'];
};

/** Autogenerated return type of AddStarterDeals. */
export type AddStarterDealsPayload = {
  __typename?: 'AddStarterDealsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type Carousel = {
  __typename?: 'Carousel';
  deal?: Maybe<Deal>;
  match?: Maybe<Match>;
  type: Scalars['String']['output'];
};

/** Autogenerated input type of ChangeReviewStatus */
export type ChangeReviewStatusInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Owner ID -- omit for the current user's organization */
  owner?: InputMaybe<Scalars['ID']['input']>;
  /** Review status */
  status: Status;
};

/** Autogenerated return type of ChangeReviewStatus. */
export type ChangeReviewStatusPayload = {
  __typename?: 'ChangeReviewStatusPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  profileReviewStatus?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of CreateDeal */
export type CreateDealInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the profile to receing the deal */
  forOrgId: Scalars['ID']['input'];
  /** ID of the profile to suggest */
  suggestedOrgId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateDeal. */
export type CreateDealPayload = {
  __typename?: 'CreateDealPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type Deal = {
  __typename?: 'Deal';
  createdAt: Scalars['ISO8601DateTime']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  handSelected?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  lastViewedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  recommendedFor: Scalars['ID']['output'];
  requestIntro?: Maybe<Scalars['Boolean']['output']>;
  suggestedProfile: Scalars['ID']['output'];
  systemSelected?: Maybe<Scalars['Boolean']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  userRequested?: Maybe<Scalars['Boolean']['output']>;
};

export type DealFlowSet = {
  __typename?: 'DealFlowSet';
  profileType?: Maybe<ProfileKind>;
  profiles?: Maybe<Scalars['JSON']['output']>;
};

export type DealHistoryPageMetadata = {
  __typename?: 'DealHistoryPageMetadata';
  all?: Maybe<PageMetadata>;
  introed?: Maybe<PageMetadata>;
  requested?: Maybe<PageMetadata>;
  sent?: Maybe<PageMetadata>;
};

export type DealMutations = {
  __typename?: 'DealMutations';
  addStarterDeals?: Maybe<AddStarterDealsPayload>;
  createDeal?: Maybe<CreateDealPayload>;
  seedDeals?: Maybe<SeedDealsPayload>;
  updateDealFlowSet?: Maybe<UpdateDealFlowSetPayload>;
  updateIntro?: Maybe<UpdateDealIntroPayload>;
  userMatchIntro?: Maybe<UserMatchIntroPayload>;
  userRequestIntro?: Maybe<UserRequestIntroPayload>;
};


export type DealMutationsAddStarterDealsArgs = {
  input: AddStarterDealsInput;
};


export type DealMutationsCreateDealArgs = {
  input: CreateDealInput;
};


export type DealMutationsSeedDealsArgs = {
  input: SeedDealsInput;
};


export type DealMutationsUpdateDealFlowSetArgs = {
  input: UpdateDealFlowSetInput;
};


export type DealMutationsUpdateIntroArgs = {
  input: UpdateDealIntroInput;
};


export type DealMutationsUserMatchIntroArgs = {
  input: UserMatchIntroInput;
};


export type DealMutationsUserRequestIntroArgs = {
  input: UserRequestIntroInput;
};

export type DealProfile = {
  __typename?: 'DealProfile';
  companyName?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  creatorUsername: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  investorName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  linkedinUrl?: Maybe<Scalars['String']['output']>;
  owner: Scalars['JSON']['output'];
  profileReviewStatus: ProfileReviewStatus;
  profileType: ProfileKind;
  truncated: Scalars['Boolean']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type DirectoryPage = {
  __typename?: 'DirectoryPage';
  metadata: PageMetadata;
  profiles: Array<Profile>;
};

/** Autogenerated input type of FinishUpload */
export type FinishUploadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Name of the file to upload */
  name: ProfileUploadOptions;
  /** Owner ID -- omit for the current user's organization */
  owner?: InputMaybe<Scalars['ID']['input']>;
  /** Signed ID of the file to upload */
  signedId: Scalars['String']['input'];
  /** Type of upload */
  type: UploadKind;
};

/** Autogenerated return type of FinishUpload. */
export type FinishUploadPayload = {
  __typename?: 'FinishUploadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  id?: Maybe<Scalars['ID']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type FounderProfileData = {
  active_investors?: InputMaybe<Array<Scalars['JSON']['input']>>;
  company?: InputMaybe<Scalars['JSON']['input']>;
  company_details?: InputMaybe<Scalars['JSON']['input']>;
  company_social_links?: InputMaybe<Scalars['JSON']['input']>;
  company_social_links_others?: InputMaybe<Array<Scalars['JSON']['input']>>;
  featured_articles?: InputMaybe<Array<Scalars['JSON']['input']>>;
  match_characteristics?: InputMaybe<Array<Scalars['String']['input']>>;
  match_industries?: InputMaybe<Array<Scalars['String']['input']>>;
  match_targets?: InputMaybe<Array<Scalars['String']['input']>>;
  matches?: InputMaybe<Scalars['JSON']['input']>;
  metrics?: InputMaybe<Scalars['JSON']['input']>;
  open_to?: InputMaybe<Scalars['String']['input']>;
  organization_contact?: InputMaybe<Scalars['JSON']['input']>;
  organization_members?: InputMaybe<Array<Scalars['JSON']['input']>>;
  pitch?: InputMaybe<Scalars['JSON']['input']>;
  pitch_materials?: InputMaybe<Array<Scalars['JSON']['input']>>;
  product_sample?: InputMaybe<Scalars['JSON']['input']>;
  products?: InputMaybe<Array<Scalars['JSON']['input']>>;
  raise_reasons?: InputMaybe<Array<Scalars['JSON']['input']>>;
  raises?: InputMaybe<Scalars['JSON']['input']>;
  stage?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

export type InvestorProfileData = {
  about?: InputMaybe<Scalars['JSON']['input']>;
  about_materials?: InputMaybe<Array<Scalars['JSON']['input']>>;
  featured_articles?: InputMaybe<Array<Scalars['JSON']['input']>>;
  investing_experience?: InputMaybe<Array<Scalars['JSON']['input']>>;
  investing_history?: InputMaybe<Array<Scalars['JSON']['input']>>;
  investor?: InputMaybe<Scalars['JSON']['input']>;
  investor_social_links?: InputMaybe<Scalars['JSON']['input']>;
  investor_social_links_others?: InputMaybe<Array<Scalars['JSON']['input']>>;
  match_characteristics?: InputMaybe<Array<Scalars['String']['input']>>;
  match_industries?: InputMaybe<Array<Scalars['String']['input']>>;
  match_targets?: InputMaybe<Array<Scalars['String']['input']>>;
  matches?: InputMaybe<Scalars['JSON']['input']>;
  open_to?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of MarkViewed */
export type MarkViewedInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the deal or match to view */
  id: Scalars['ID']['input'];
  /** Type of object to mark as view */
  type?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of MarkViewed. */
export type MarkViewedPayload = {
  __typename?: 'MarkViewedPayload';
  alredyViewed?: Maybe<Scalars['Boolean']['output']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  viewedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

export type Match = {
  __typename?: 'Match';
  createdAt: Scalars['ISO8601DateTime']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  initiatingProfile: MinimalProfile;
  introMessage: Scalars['String']['output'];
  notes: Scalars['String']['output'];
  originalDeal?: Maybe<Deal>;
  recommendedFor: Scalars['ID']['output'];
  requestIntro: Scalars['Boolean']['output'];
  state: Scalars['String']['output'];
  suggestedProfile: Scalars['ID']['output'];
  targetProfile: MinimalProfile;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type MatchInfo = {
  __typename?: 'MatchInfo';
  match?: Maybe<Match>;
  matched?: Maybe<Scalars['Boolean']['output']>;
  matchingTags?: Maybe<Array<Scalars['String']['output']>>;
  requestIntro: Scalars['Boolean']['output'];
  requestsRemaining?: Maybe<Scalars['Int']['output']>;
};

export type Me = {
  __typename?: 'Me';
  emailVerified: Scalars['Boolean']['output'];
  matchRequests: Scalars['Int']['output'];
  onboardingStatus?: Maybe<Scalars['String']['output']>;
  orgId: Scalars['ID']['output'];
  plan?: Maybe<Scalars['String']['output']>;
  profileType: ProfileKind;
  roles: Array<Scalars['String']['output']>;
  subscriptionStatus?: Maybe<Scalars['String']['output']>;
  userId: Scalars['ID']['output'];
  username: Scalars['String']['output'];
  verificationStatus?: Maybe<Scalars['String']['output']>;
};

export type MetricObject = MinimalProfile | Organization;

export type MetricView = {
  __typename?: 'MetricView';
  createdAt: Scalars['ISO8601DateTime']['output'];
  extra?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  source: MetricObject;
  target: MetricObject;
  type: Scalars['String']['output'];
};

/** Autogenerated input type of Metrics */
export type MetricsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Extra data to store with the metric */
  extra?: InputMaybe<Scalars['JSON']['input']>;
  /** The target of the metric */
  targetId: Scalars['String']['input'];
  /** The type of metric to store */
  type: Scalars['String']['input'];
};

/** Autogenerated return type of Metrics. */
export type MetricsPayload = {
  __typename?: 'MetricsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

export type MinimalProfile = {
  __typename?: 'MinimalProfile';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  owner: Organization;
  profileType: ProfileKind;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  viewData?: Maybe<Scalars['JSON']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  changeReviewStatus?: Maybe<ChangeReviewStatusPayload>;
  deals?: Maybe<DealMutations>;
  finishUpload?: Maybe<FinishUploadPayload>;
  markViewed?: Maybe<MarkViewedPayload>;
  metrics?: Maybe<MetricsPayload>;
  onboardingWorkflow?: Maybe<OnboardingWorkflowPayload>;
  saveTag?: Maybe<SaveTagPayload>;
  startUpload?: Maybe<StartUploadPayload>;
  stripe?: Maybe<StripeMutations>;
  updateProfile?: Maybe<UpdateProfilePayload>;
};


export type MutationChangeReviewStatusArgs = {
  input: ChangeReviewStatusInput;
};


export type MutationFinishUploadArgs = {
  input: FinishUploadInput;
};


export type MutationMarkViewedArgs = {
  input: MarkViewedInput;
};


export type MutationMetricsArgs = {
  input: MetricsInput;
};


export type MutationOnboardingWorkflowArgs = {
  input: OnboardingWorkflowInput;
};


export type MutationSaveTagArgs = {
  input: SaveTagInput;
};


export type MutationStartUploadArgs = {
  input: StartUploadInput;
};


export type MutationUpdateProfileArgs = {
  input: UpdateProfileInput;
};

export type OnboardingStatus = {
  __typename?: 'OnboardingStatus';
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

/** Autogenerated input type of OnboardingWorkflow */
export type OnboardingWorkflowInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Data to save for the current step */
  data?: InputMaybe<Scalars['JSON']['input']>;
  /** Client specified step to jump to -- cannot skip past the current step */
  stepName?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OnboardingWorkflow. */
export type OnboardingWorkflowPayload = {
  __typename?: 'OnboardingWorkflowPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Scalars['JSON']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  profileType: ProfileKind;
  status: OnboardingStatus;
};

export type Organization = {
  __typename?: 'Organization';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type PageMetadata = {
  __typename?: 'PageMetadata';
  nextPage?: Maybe<Scalars['Int']['output']>;
  previousPage?: Maybe<Scalars['Int']['output']>;
  totalItems: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type Profile = {
  __typename?: 'Profile';
  createdAt: Scalars['ISO8601DateTime']['output'];
  creator: User;
  data: Scalars['JSON']['output'];
  files: Scalars['JSON']['output'];
  id: Scalars['ID']['output'];
  owner: Organization;
  profileReviewStatus: ProfileReviewStatus;
  profileType: ProfileKind;
  truncated: Scalars['Boolean']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Profile kind enum */
export enum ProfileKind {
  Founder = 'founder',
  Investor = 'investor'
}

export type ProfileReviewStatus = {
  __typename?: 'ProfileReviewStatus';
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

/** Profile upload filename options */
export enum ProfileUploadOptions {
  AboutMaterialsPhotos = 'about_materials__photos',
  CompanyHero = 'company_hero',
  CompanyLogo = 'company_logo',
  FeaturedArticlesPhotos = 'featured_articles__photos',
  InvestingHistoryPhotos = 'investing_history__photos',
  InvestorHeadshot = 'investor_headshot',
  OrganizationContactHeadshot = 'organization_contact_headshot',
  OrganizationMembersHeadshots = 'organization_members__headshots',
  PitchHero = 'pitch_hero',
  PitchMaterialsPhotos = 'pitch_materials__photos',
  ProductsPhotos = 'products__photos'
}

export type Query = {
  __typename?: 'Query';
  /** Loads a deal */
  deal?: Maybe<Deal>;
  /** Load list of deals and intro requests for the current user */
  dealCarousel?: Maybe<Array<Carousel>>;
  /** Loads a deal flow set */
  dealFlowSet?: Maybe<DealFlowSet>;
  /** Loads your list of deals */
  dealHistory?: Maybe<Array<Carousel>>;
  /** Fetches the page metadata for deal history filters */
  dealHistoryPageMetadata?: Maybe<DealHistoryPageMetadata>;
  /** Loads a list of profiles */
  dealProfiles?: Maybe<Array<DealProfile>>;
  /** Loads a list of all users deals */
  deals?: Maybe<Array<Deal>>;
  /** Loads a directory of users */
  directory?: Maybe<DirectoryPage>;
  /** Loads a match */
  match?: Maybe<MatchInfo>;
  /** Loads a list of matches */
  matches?: Maybe<Array<Match>>;
  /** Loads the current user metadata */
  me: Me;
  /** Loads metrics */
  metrics: Array<MetricView>;
  /** Loads a profile */
  profile: Profile;
  /** Load all profiles */
  profiles: Array<Profile>;
  /** Loads a tag */
  tag: Tag;
  /** Loads a list of tags */
  tags: Array<Tag>;
};


export type QueryDealArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDealFlowSetArgs = {
  profileType: Scalars['String']['input'];
};


export type QueryDealHistoryArgs = {
  cursor?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  filter: Scalars['String']['input'];
};


export type QueryDealProfilesArgs = {
  createdAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  profileId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryDealsArgs = {
  all?: InputMaybe<Scalars['Boolean']['input']>;
  profileId?: InputMaybe<Scalars['ID']['input']>;
  requestIntro?: InputMaybe<Scalars['Boolean']['input']>;
  systemSelected?: InputMaybe<Scalars['Boolean']['input']>;
  userRequested?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryDirectoryArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  profileType: ProfileKind;
};


export type QueryMatchArgs = {
  wanted: Scalars['ID']['input'];
};


export type QueryMatchesArgs = {
  states?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryMetricsArgs = {
  filter: Scalars['String']['input'];
  objectId?: InputMaybe<Scalars['ID']['input']>;
  resumeAfter?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryProfileArgs = {
  owner?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryTagArgs = {
  slug: Scalars['String']['input'];
};


export type QueryTagsArgs = {
  parent?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of SaveTag */
export type SaveTagInput = {
  /**
   * Is this tag active(useable by admins or the matching \
   *     algorithm or users) in the system
   */
  active: Scalars['Boolean']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** On site display of tag */
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** Full name of tag */
  name: Scalars['String']['input'];
  /** Slug of parent */
  parent?: InputMaybe<Scalars['String']['input']>;
  /**
   * Type of profile(Investor or \
   *     Founder) this tag is restricted to
   */
  profileTypeRestriction?: InputMaybe<ProfileKind>;
  /** Slug of existing or new tag */
  slug: Scalars['String']['input'];
  /** Is this tag selectable or viewable by end Users */
  userVisible: Scalars['Boolean']['input'];
};

/** Autogenerated return type of SaveTag. */
export type SaveTagPayload = {
  __typename?: 'SaveTagPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  tag?: Maybe<Tag>;
};

/** Autogenerated input type of SeedDeals */
export type SeedDealsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of SeedDeals. */
export type SeedDealsPayload = {
  __typename?: 'SeedDealsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of StartUpload */
export type StartUploadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Metadata for the upload */
  metadata: UploadMetadataAttributes;
  /** Name of the file to upload */
  name: ProfileUploadOptions;
  /** Owner ID -- omit for the current user's organization */
  owner?: InputMaybe<Scalars['ID']['input']>;
  /** Type of upload */
  type: UploadKind;
};

/** Autogenerated return type of StartUpload. */
export type StartUploadPayload = {
  __typename?: 'StartUploadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  headers?: Maybe<Scalars['JSON']['output']>;
  signedId?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export enum Status {
  Submitted = 'submitted',
  Unsubmitted = 'unsubmitted',
  Vetted = 'vetted'
}

/** Autogenerated input type of StripeCheckout */
export type StripeCheckoutInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The plan to subscribe to */
  plan: Scalars['String']['input'];
};

/** Autogenerated return type of StripeCheckout. */
export type StripeCheckoutPayload = {
  __typename?: 'StripeCheckoutPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  clientSecret?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  paymentStatus: Scalars['String']['output'];
};

/** Autogenerated input type of StripeCheckoutStatus */
export type StripeCheckoutStatusInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The session to check the status of */
  sessionId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of StripeCheckoutStatus. */
export type StripeCheckoutStatusPayload = {
  __typename?: 'StripeCheckoutStatusPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  clientSecret?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  paymentStatus: Scalars['String']['output'];
};

export type StripeMutations = {
  __typename?: 'StripeMutations';
  checkout?: Maybe<StripeCheckoutPayload>;
  checkoutStatus?: Maybe<StripeCheckoutStatusPayload>;
  portal?: Maybe<StripePortalPayload>;
  verification?: Maybe<StripeVerificationPayload>;
  verificationStatus?: Maybe<StripeVerificationStatusPayload>;
};


export type StripeMutationsCheckoutArgs = {
  input: StripeCheckoutInput;
};


export type StripeMutationsCheckoutStatusArgs = {
  input: StripeCheckoutStatusInput;
};


export type StripeMutationsPortalArgs = {
  input: StripePortalInput;
};


export type StripeMutationsVerificationArgs = {
  input: StripeVerificationInput;
};


export type StripeMutationsVerificationStatusArgs = {
  input: StripeVerificationStatusInput;
};

/** Autogenerated input type of StripePortal */
export type StripePortalInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of StripePortal. */
export type StripePortalPayload = {
  __typename?: 'StripePortalPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  sessionUrl?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of StripeVerification */
export type StripeVerificationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of StripeVerification. */
export type StripeVerificationPayload = {
  __typename?: 'StripeVerificationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  clientSecret?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  status: Scalars['String']['output'];
};

/** Autogenerated input type of StripeVerificationStatus */
export type StripeVerificationStatusInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of StripeVerificationStatus. */
export type StripeVerificationStatusPayload = {
  __typename?: 'StripeVerificationStatusPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  clientSecret?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  status: Scalars['String']['output'];
};

export type Tag = {
  __typename?: 'Tag';
  active: Scalars['Boolean']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  parent?: Maybe<Tag>;
  slug: Scalars['String']['output'];
  tagCategory: TagCategory;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type TagCategory = {
  __typename?: 'TagCategory';
  description: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of UpdateDealFlowSet */
export type UpdateDealFlowSetInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Kind of profile to update */
  profileKind: Scalars['String']['input'];
  /** Profile data for update */
  profiles: Scalars['JSON']['input'];
};

/** Autogenerated return type of UpdateDealFlowSet. */
export type UpdateDealFlowSetPayload = {
  __typename?: 'UpdateDealFlowSetPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dealFlowSet?: Maybe<DealFlowSet>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of UpdateDealIntro */
export type UpdateDealIntroInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the deal */
  id: Scalars['ID']['input'];
  /** True to request intro False to deny, nil to bring back to deal flow */
  requestIntro?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of UpdateDealIntro. */
export type UpdateDealIntroPayload = {
  __typename?: 'UpdateDealIntroPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deal?: Maybe<Deal>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of UpdateProfile */
export type UpdateProfileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Profile data for update -- only submit one */
  data?: InputMaybe<Scalars['JSON']['input']>;
  /** Founder data for update -- only submit one */
  founder?: InputMaybe<FounderProfileData>;
  /** Investor data for update -- only submit one */
  investor?: InputMaybe<InvestorProfileData>;
  /** Merge data with existing data */
  merge?: InputMaybe<Scalars['Boolean']['input']>;
  /** Owner ID -- omit for the current user's organization */
  owner?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of UpdateProfile. */
export type UpdateProfilePayload = {
  __typename?: 'UpdateProfilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  profile?: Maybe<Profile>;
};

/** Upload kind enum */
export enum UploadKind {
  Profile = 'profile'
}

export type UploadMetadataAttributes = {
  /** Checksum of file */
  checksum: Scalars['String']['input'];
  /** Type of file image to upload, eg image/jpeg */
  contentType: Scalars['String']['input'];
  /** Size of file in bytes */
  size: Scalars['Int']['input'];
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated input type of UserMatchIntro */
export type UserMatchIntroInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Notes to include with the request */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** ID of the profile to request intro */
  requestedId: Scalars['ID']['input'];
};

/** Autogenerated return type of UserMatchIntro. */
export type UserMatchIntroPayload = {
  __typename?: 'UserMatchIntroPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UserRequestIntro */
export type UserRequestIntroInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Notes to include with the request */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** ID of the profile to request intro */
  requestedId: Scalars['ID']['input'];
};

/** Autogenerated return type of UserRequestIntro. */
export type UserRequestIntroPayload = {
  __typename?: 'UserRequestIntroPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type ProfileEditFieldsFragment = { __typename?: 'Profile', id: string, data: any, profileType: ProfileKind, updatedAt: any, files: any, truncated: boolean, creator: { __typename?: 'User', id: string }, owner: { __typename?: 'Organization', id: string, name: string } };

export type CheckOnboardingWorkflowStatusMutationVariables = Exact<{
  stepName: Scalars['String']['input'];
}>;


export type CheckOnboardingWorkflowStatusMutation = { __typename?: 'Mutation', onboardingWorkflow?: { __typename?: 'OnboardingWorkflowPayload', data?: any | null, profileType: ProfileKind, errors?: Array<string> | null, status: { __typename?: 'OnboardingStatus', value: string, displayName: string } } | null };

export type SaveOnboardingMutationVariables = Exact<{
  stepName: Scalars['String']['input'];
  data: Scalars['JSON']['input'];
}>;


export type SaveOnboardingMutation = { __typename?: 'Mutation', onboardingWorkflow?: { __typename?: 'OnboardingWorkflowPayload', data?: any | null, profileType: ProfileKind, errors?: Array<string> | null, status: { __typename?: 'OnboardingStatus', value: string, displayName: string } } | null };

export type SaveProfileMutationVariables = Exact<{
  data?: InputMaybe<Scalars['JSON']['input']>;
  founder?: InputMaybe<FounderProfileData>;
  investor?: InputMaybe<InvestorProfileData>;
  merge?: InputMaybe<Scalars['Boolean']['input']>;
  orgId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type SaveProfileMutation = { __typename?: 'Mutation', updateProfile?: { __typename?: 'UpdateProfilePayload', errors?: Array<string> | null, profile?: { __typename?: 'Profile', id: string, data: any, profileType: ProfileKind, updatedAt: any, files: any, truncated: boolean, creator: { __typename?: 'User', id: string }, owner: { __typename?: 'Organization', id: string, name: string } } | null } | null };

export type CallbackPrerouteQueryVariables = Exact<{ [key: string]: never; }>;


export type CallbackPrerouteQuery = { __typename?: 'Query', me: { __typename?: 'Me', emailVerified: boolean, roles: Array<string>, onboardingStatus?: string | null, username: string, matchRequests: number } };

export type OnboardingAllQueryVariables = Exact<{ [key: string]: never; }>;


export type OnboardingAllQuery = { __typename?: 'Query', me: { __typename?: 'Me', username: string, orgId: string, subscriptionStatus?: string | null, verificationStatus?: string | null }, profile: { __typename?: 'Profile', id: string, data: any, profileType: ProfileKind } };

export type OnboardingProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type OnboardingProfileQuery = { __typename?: 'Query', profile: { __typename?: 'Profile', id: string, data: any, profileType: ProfileKind } };

export type OnboardingMeQueryVariables = Exact<{ [key: string]: never; }>;


export type OnboardingMeQuery = { __typename?: 'Query', me: { __typename?: 'Me', username: string, orgId: string, subscriptionStatus?: string | null, verificationStatus?: string | null, profileType: ProfileKind } };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'Me', username: string, userId: string, orgId: string, roles: Array<string>, emailVerified: boolean, profileType: ProfileKind, matchRequests: number, subscriptionStatus?: string | null, verificationStatus?: string | null, onboardingStatus?: string | null } };

export type GetEditProfileQueryVariables = Exact<{
  orgId: Scalars['ID']['input'];
}>;


export type GetEditProfileQuery = { __typename?: 'Query', profile: { __typename?: 'Profile', id: string, data: any, profileType: ProfileKind, updatedAt: any, files: any, truncated: boolean, creator: { __typename?: 'User', id: string }, owner: { __typename?: 'Organization', id: string, name: string } } };

export const ProfileEditFieldsFragmentDoc = gql`
    fragment ProfileEditFields on Profile {
  id
  data
  profileType
  creator {
    id
  }
  owner {
    id
    name
  }
  updatedAt
  files
  truncated
}
    `;
export const CheckOnboardingWorkflowStatusDocument = gql`
    mutation CheckOnboardingWorkflowStatus($stepName: String!) {
  onboardingWorkflow(input: {stepName: $stepName, data: null}) {
    data
    profileType
    status {
      value
      displayName
    }
    errors
  }
}
    `;
export type CheckOnboardingWorkflowStatusMutationFn = Apollo.MutationFunction<CheckOnboardingWorkflowStatusMutation, CheckOnboardingWorkflowStatusMutationVariables>;

/**
 * __useCheckOnboardingWorkflowStatusMutation__
 *
 * To run a mutation, you first call `useCheckOnboardingWorkflowStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckOnboardingWorkflowStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkOnboardingWorkflowStatusMutation, { data, loading, error }] = useCheckOnboardingWorkflowStatusMutation({
 *   variables: {
 *      stepName: // value for 'stepName'
 *   },
 * });
 */
export function useCheckOnboardingWorkflowStatusMutation(baseOptions?: Apollo.MutationHookOptions<CheckOnboardingWorkflowStatusMutation, CheckOnboardingWorkflowStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckOnboardingWorkflowStatusMutation, CheckOnboardingWorkflowStatusMutationVariables>(CheckOnboardingWorkflowStatusDocument, options);
      }
export type CheckOnboardingWorkflowStatusMutationHookResult = ReturnType<typeof useCheckOnboardingWorkflowStatusMutation>;
export type CheckOnboardingWorkflowStatusMutationResult = Apollo.MutationResult<CheckOnboardingWorkflowStatusMutation>;
export type CheckOnboardingWorkflowStatusMutationOptions = Apollo.BaseMutationOptions<CheckOnboardingWorkflowStatusMutation, CheckOnboardingWorkflowStatusMutationVariables>;
export const SaveOnboardingDocument = gql`
    mutation SaveOnboarding($stepName: String!, $data: JSON!) {
  onboardingWorkflow(input: {stepName: $stepName, data: $data}) {
    data
    profileType
    status {
      value
      displayName
    }
    errors
  }
}
    `;
export type SaveOnboardingMutationFn = Apollo.MutationFunction<SaveOnboardingMutation, SaveOnboardingMutationVariables>;

/**
 * __useSaveOnboardingMutation__
 *
 * To run a mutation, you first call `useSaveOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveOnboardingMutation, { data, loading, error }] = useSaveOnboardingMutation({
 *   variables: {
 *      stepName: // value for 'stepName'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSaveOnboardingMutation(baseOptions?: Apollo.MutationHookOptions<SaveOnboardingMutation, SaveOnboardingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveOnboardingMutation, SaveOnboardingMutationVariables>(SaveOnboardingDocument, options);
      }
export type SaveOnboardingMutationHookResult = ReturnType<typeof useSaveOnboardingMutation>;
export type SaveOnboardingMutationResult = Apollo.MutationResult<SaveOnboardingMutation>;
export type SaveOnboardingMutationOptions = Apollo.BaseMutationOptions<SaveOnboardingMutation, SaveOnboardingMutationVariables>;
export const SaveProfileDocument = gql`
    mutation SaveProfile($data: JSON, $founder: FounderProfileData, $investor: InvestorProfileData, $merge: Boolean = true, $orgId: ID) {
  updateProfile(
    input: {data: $data, founder: $founder, investor: $investor, merge: $merge, owner: $orgId}
  ) {
    profile {
      ...ProfileEditFields
    }
    errors
  }
}
    ${ProfileEditFieldsFragmentDoc}`;
export type SaveProfileMutationFn = Apollo.MutationFunction<SaveProfileMutation, SaveProfileMutationVariables>;

/**
 * __useSaveProfileMutation__
 *
 * To run a mutation, you first call `useSaveProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveProfileMutation, { data, loading, error }] = useSaveProfileMutation({
 *   variables: {
 *      data: // value for 'data'
 *      founder: // value for 'founder'
 *      investor: // value for 'investor'
 *      merge: // value for 'merge'
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useSaveProfileMutation(baseOptions?: Apollo.MutationHookOptions<SaveProfileMutation, SaveProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveProfileMutation, SaveProfileMutationVariables>(SaveProfileDocument, options);
      }
export type SaveProfileMutationHookResult = ReturnType<typeof useSaveProfileMutation>;
export type SaveProfileMutationResult = Apollo.MutationResult<SaveProfileMutation>;
export type SaveProfileMutationOptions = Apollo.BaseMutationOptions<SaveProfileMutation, SaveProfileMutationVariables>;
export const CallbackPrerouteDocument = gql`
    query CallbackPreroute {
  me {
    emailVerified
    roles
    onboardingStatus
    username
    matchRequests
  }
}
    `;

/**
 * __useCallbackPrerouteQuery__
 *
 * To run a query within a React component, call `useCallbackPrerouteQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallbackPrerouteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallbackPrerouteQuery({
 *   variables: {
 *   },
 * });
 */
export function useCallbackPrerouteQuery(baseOptions?: Apollo.QueryHookOptions<CallbackPrerouteQuery, CallbackPrerouteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CallbackPrerouteQuery, CallbackPrerouteQueryVariables>(CallbackPrerouteDocument, options);
      }
export function useCallbackPrerouteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CallbackPrerouteQuery, CallbackPrerouteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CallbackPrerouteQuery, CallbackPrerouteQueryVariables>(CallbackPrerouteDocument, options);
        }
export function useCallbackPrerouteSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CallbackPrerouteQuery, CallbackPrerouteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CallbackPrerouteQuery, CallbackPrerouteQueryVariables>(CallbackPrerouteDocument, options);
        }
export type CallbackPrerouteQueryHookResult = ReturnType<typeof useCallbackPrerouteQuery>;
export type CallbackPrerouteLazyQueryHookResult = ReturnType<typeof useCallbackPrerouteLazyQuery>;
export type CallbackPrerouteSuspenseQueryHookResult = ReturnType<typeof useCallbackPrerouteSuspenseQuery>;
export type CallbackPrerouteQueryResult = Apollo.QueryResult<CallbackPrerouteQuery, CallbackPrerouteQueryVariables>;
export const OnboardingAllDocument = gql`
    query OnboardingAll {
  me {
    username
    orgId
    subscriptionStatus
    verificationStatus
  }
  profile {
    id
    data
    profileType
  }
}
    `;

/**
 * __useOnboardingAllQuery__
 *
 * To run a query within a React component, call `useOnboardingAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnboardingAllQuery(baseOptions?: Apollo.QueryHookOptions<OnboardingAllQuery, OnboardingAllQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OnboardingAllQuery, OnboardingAllQueryVariables>(OnboardingAllDocument, options);
      }
export function useOnboardingAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OnboardingAllQuery, OnboardingAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OnboardingAllQuery, OnboardingAllQueryVariables>(OnboardingAllDocument, options);
        }
export function useOnboardingAllSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OnboardingAllQuery, OnboardingAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OnboardingAllQuery, OnboardingAllQueryVariables>(OnboardingAllDocument, options);
        }
export type OnboardingAllQueryHookResult = ReturnType<typeof useOnboardingAllQuery>;
export type OnboardingAllLazyQueryHookResult = ReturnType<typeof useOnboardingAllLazyQuery>;
export type OnboardingAllSuspenseQueryHookResult = ReturnType<typeof useOnboardingAllSuspenseQuery>;
export type OnboardingAllQueryResult = Apollo.QueryResult<OnboardingAllQuery, OnboardingAllQueryVariables>;
export const OnboardingProfileDocument = gql`
    query OnboardingProfile {
  profile {
    id
    data
    profileType
  }
}
    `;

/**
 * __useOnboardingProfileQuery__
 *
 * To run a query within a React component, call `useOnboardingProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnboardingProfileQuery(baseOptions?: Apollo.QueryHookOptions<OnboardingProfileQuery, OnboardingProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OnboardingProfileQuery, OnboardingProfileQueryVariables>(OnboardingProfileDocument, options);
      }
export function useOnboardingProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OnboardingProfileQuery, OnboardingProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OnboardingProfileQuery, OnboardingProfileQueryVariables>(OnboardingProfileDocument, options);
        }
export function useOnboardingProfileSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OnboardingProfileQuery, OnboardingProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OnboardingProfileQuery, OnboardingProfileQueryVariables>(OnboardingProfileDocument, options);
        }
export type OnboardingProfileQueryHookResult = ReturnType<typeof useOnboardingProfileQuery>;
export type OnboardingProfileLazyQueryHookResult = ReturnType<typeof useOnboardingProfileLazyQuery>;
export type OnboardingProfileSuspenseQueryHookResult = ReturnType<typeof useOnboardingProfileSuspenseQuery>;
export type OnboardingProfileQueryResult = Apollo.QueryResult<OnboardingProfileQuery, OnboardingProfileQueryVariables>;
export const OnboardingMeDocument = gql`
    query OnboardingMe {
  me {
    username
    orgId
    subscriptionStatus
    verificationStatus
    profileType
  }
}
    `;

/**
 * __useOnboardingMeQuery__
 *
 * To run a query within a React component, call `useOnboardingMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnboardingMeQuery(baseOptions?: Apollo.QueryHookOptions<OnboardingMeQuery, OnboardingMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OnboardingMeQuery, OnboardingMeQueryVariables>(OnboardingMeDocument, options);
      }
export function useOnboardingMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OnboardingMeQuery, OnboardingMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OnboardingMeQuery, OnboardingMeQueryVariables>(OnboardingMeDocument, options);
        }
export function useOnboardingMeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OnboardingMeQuery, OnboardingMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OnboardingMeQuery, OnboardingMeQueryVariables>(OnboardingMeDocument, options);
        }
export type OnboardingMeQueryHookResult = ReturnType<typeof useOnboardingMeQuery>;
export type OnboardingMeLazyQueryHookResult = ReturnType<typeof useOnboardingMeLazyQuery>;
export type OnboardingMeSuspenseQueryHookResult = ReturnType<typeof useOnboardingMeSuspenseQuery>;
export type OnboardingMeQueryResult = Apollo.QueryResult<OnboardingMeQuery, OnboardingMeQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    username
    userId
    orgId
    roles
    emailVerified
    profileType
    matchRequests
    subscriptionStatus
    verificationStatus
    onboardingStatus
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export function useMeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const GetEditProfileDocument = gql`
    query GetEditProfile($orgId: ID!) {
  profile(owner: $orgId) {
    ...ProfileEditFields
  }
}
    ${ProfileEditFieldsFragmentDoc}`;

/**
 * __useGetEditProfileQuery__
 *
 * To run a query within a React component, call `useGetEditProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditProfileQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useGetEditProfileQuery(baseOptions: Apollo.QueryHookOptions<GetEditProfileQuery, GetEditProfileQueryVariables> & ({ variables: GetEditProfileQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEditProfileQuery, GetEditProfileQueryVariables>(GetEditProfileDocument, options);
      }
export function useGetEditProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEditProfileQuery, GetEditProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEditProfileQuery, GetEditProfileQueryVariables>(GetEditProfileDocument, options);
        }
export function useGetEditProfileSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetEditProfileQuery, GetEditProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEditProfileQuery, GetEditProfileQueryVariables>(GetEditProfileDocument, options);
        }
export type GetEditProfileQueryHookResult = ReturnType<typeof useGetEditProfileQuery>;
export type GetEditProfileLazyQueryHookResult = ReturnType<typeof useGetEditProfileLazyQuery>;
export type GetEditProfileSuspenseQueryHookResult = ReturnType<typeof useGetEditProfileSuspenseQuery>;
export type GetEditProfileQueryResult = Apollo.QueryResult<GetEditProfileQuery, GetEditProfileQueryVariables>;