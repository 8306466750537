import {RouteObject} from 'react-router-dom'

const RouterUtil = {
  routes: Array<RouteObject>(),
  addRoute(route: RouteObject) {
    this.routes.push(route)
  },
}

export default RouterUtil
