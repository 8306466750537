// See: https://www.figma.com/file/qMoxJn4rVySreDEnf2R9Js/Cherub-Annotations?type=design&node-id=399%3A5439&mode=dev
import styled from '@emotion/styled'
import {Stack} from '@mui/system'
import React from 'react'
import colors from '../colors'
import {useProfileContext} from './Profile.Contexts'

const HelpLink = styled('a')(
  ({theme}) => `
  color: ${colors.evergreen[100]};
  font-family: "Aktiv-Grotesk";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 26px */
  text-decoration-line: underline;
  cursor: pointer;
  z-index: 1;
  display: block;
`,
)

export default function GetStartedLink(): React.JSX.Element {
  const {
    form: {metadata: {profileType}},
  } = useProfileContext()

  const getHelpURL = profileType === 'investor' 
    ? 'https://intercom.help/cherub/en/articles/9044714-getting-started-with-your-angel-investor-profile'
    : 'https://intercom.help/cherub/en/articles/9035310-cherub-profile-guide-seed-stage-companies'

  return (
    <Stack direction="row" alignItems="flex-end" spacing={2}>
      <HelpLink href={getHelpURL} target="_blank">
        Want inspo? Start here.
      </HelpLink>
    </Stack>
  )
}
